var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Afișează mai multe",
  "client.header.login.button": "Autentificare",
  "client.header.logout.button": "Deconectare",
  "client.user-id-modal.input-placeholder": "ID utilizator",
  "client.user-id-modal.input-incorrect-id": "ID incorect de utilizator.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Încercați să copiați și să inserați ID-ul dvs. din joc.",
  "client.user-id-modal.continue-button": "Continuați",
  "client.user-id-modal.go-back": "Înapoi",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Afișează mai multe",
  "client.privacy-settings.open-button": "Setări de confidențialitate",
  "client.usa-privacy-settings.open-button": "Nu îmi vindeți datele",
  "client.user-id-modal.continue-with": "Continuă cu {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Introduceți ID-ul dvs. de utilizator",
  "document.pages.auth.subtitle": "sau introdu ID-ul",
  "document.pages.auth.placeholder": "Exemplu: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Nu vă puteți găsi ID-ul personal de utilizator?",
  "document.pages.auth.instruction.steps.description": "Descrieți cum utilizatorul își poate găsi ID-ul personal. Puteți adăuga mai mulți pași și imagini.",
  "document.pages.seo.title": "A apărut noul joc!",
  "document.pages.seo.description": "Jocul tău conduce jucătorii către o mare aventură plină de detractori, monștri, căutători de aur și mare pericol.",
  "document.pages.seo.ogTitle": "A apărut noul joc!",
  "document.pages.seo.ogDescription": "Jocul tău conduce jucătorii către o mare aventură plină de detractori, monștri, căutători de aur și mare pericol.",
  "default-data.templates.google-play.contact-us": "Contactează-ne {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "ÎNCARCĂ PORTOFELUL DIN JOC",
  "blocks.topup.values.description": "Introdu ID-ul utilizatorului",
  "blocks.topup.values.userIdPlaceholder": "Exemplu: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "NU VĂ PUTEȚI GĂSI ID-UL PERSONAL DE UTILIZATOR?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Numele pasului",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Descrieți cum utilizatorul își poate găsi ID-ul personal. Puteți adăuga mai mulți pași și imagini.",
  "default-data.templates.topup.app-support": "Asistență aplicație",
  "default-data.templates.topup.age-raiting": "Evaluarea vârstei",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TITLUL JOCULUI TĂU</h1>",
  "blocks.retailers.values.title": "<h2>Alegeți-vă ediția</h2>",
  "blocks.hero.values.description": "Jocul tău conduce jucătorii către o mare aventură plină de detractori, monștri, căutători de aur și mare pericol.",
  "blocks.hero.values.affiliate": "Venitul generat de această achiziție va fi împărțit cu afiliatul tău",
  "blocks.hero.components.platforms.label": "Disponibil pentru",
  "blocks.hero.components.subscribe.label": "Introdu e-mailul tău",
  "blocks.hero.components.subscribe.values.text" : "Abonează-te la newsletter pentru a afla cele mai recente știri și noutăți.",
  "blocks.hero.components.scroll.label": "Defilare",
  "blocks.footer.values.name": "Introdu textul tău",
  "blocks.footer.values.affiliate": "Înscrie-te programului afiliat",
  "blocks.hero.components.buy.label": "Cumpără acum",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.hero.components.customButton.link.label": "Buton de configurare",
  "blocks.header.components.customButton.link.label": "Buton de configurare",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.packs.components.pack.customButton.link.label": "Buton de configurare",
  "blocks.packs.components.pack.standart.components.buy.label": "Cumpără acum",
  "blocks.packs.components.pack.premium.components.buy.label": "Cumpără acum",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Cumpără acum",
  "blocks.packs.components.pack.platinum.components.buy.label": "Cumpără acum",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.packs.components.pack.standart.value.ribbon": "Cele mai populare",
  "blocks.packs.components.pack.premium.value.ribbon": "Cele mai populare",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Cele mai populare",
  "blocks.packs.components.pack.platinum.value.ribbon": "Cele mai populare",
  "blocks.description.values.title": "<h2>DESCRIEREA JOCULUI</h2>",
  "blocks.description.components.text.label": "Jocul tău conduce jucătorii către o mare aventură plină de detractori, monștri, căutători de aur și mare pericol. S-au petrecut evenimente dramatice care ți-au dat viața peste cap și acum trebuie să lupți ca să supraviețuiești într-o lume nouă și ostilă. Descoperă, explorează și cucerește în timp ce încerci să supraviețuiești în diferitele dimensiuni ale realității!",
  "blocks.faq.values.title": "<h2>FAQ</h2>",
  "blocks.faq.components.question.question": "Cum pot să joc Jocul Tău?",
  "blocks.faq.components.question.answer": "Pentru a juca Jocul Tău, trebuie să-l cumperi și să specifici e-mailul tău. Vei primi cheia de activare, introdu-o și bucură-te de joc.",
  "blocks.news.values.title": "<h2>Știri</h2>",
  "blocks.news.values.description": "Răsfoiți articolele noastre recente pentru a vedea noutățile.",
  "blocks.store.values.title": "<h2>Magazin</h2>",
  "blocks.store.buy_button.text": "CUMPĂRĂ ACUM CU {amount}",
  "blocks.embed.values.title": "<h2>Widgeturi de rețele sociale</h2>",
  "blocks.embed.components.twitter.title": "<h3>FEED TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>FEED FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Selectați ediția dumneavoastră",
  "blocks.packs.values.description": "Fiecare ediție include avantaje și beneficii exclusive pe care le veți obține după achiziție.",
  "blocks.packs.components.pack.standart.value.subtext": "Disponibil acum",
  "blocks.packs.components.pack.premium.value.subtext": "Disponibil acum",
  "blocks.packs.components.pack.ultimate.value.subtext": "Disponibil acum",
  "blocks.packs.components.pack.platinum.value.subtext": "Disponibil acum",
  "blocks.packs.components.pack.standart.value.title": "<h3>Ediția Standard</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Ediția Premium</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Ediția Platinum</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ediția Ultimate</h3>",
  "blocks.packs.components.pack.standart.value.description": "Cheie de joc, insignă exclusivă, coloană sonoră, hartă și pachet suplimentar cu armură",
  "blocks.packs.components.pack.premium.value.description": "Cheie de joc, insignă exclusivă, coloană sonoră, hartă, pachet suplimentar cu armură și pachet suplimentar cu armament",
  "blocks.packs.components.pack.platinum.value.description": "Cheie de joc, insignă exclusivă, coloană sonoră, hartă, pachet suplimentar cu armură, pachet suplimentar cu armament, un pachet aleatoriu cu skinuri",
  "blocks.packs.components.pack.ultimate.value.description": "Cheie de joc, insignă exclusivă, coloană sonoră, hartă, pachet suplimentar cu armură, două pachete suplimentare cu armament și trei pachete aleatorii cu skinuri",
  "blocks.subscriptions.values.card.title": "Activează premium și obține beneficii",
  "blocks.subscriptions.values.title": "Activează abonamentul",
  "blocks.subscriptions.values.heading": "Abonamente",
  "blocks.subscriptions.values.bullets.one.title": "Conținut digital",
  "blocks.subscriptions.values.bullets.one.description": "Imagini, imagini de fundal, conținut din culise, coloane sonore din joc",
  "blocks.subscriptions.values.bullets.two.title": "Beneficii în joc",
  "blocks.subscriptions.values.bullets.two.description": "Skinuri de caractere, efecte vizuale, carduri cu firul povestirii",
  "blocks.subscriptions.values.bullets.three.title": "3 cufere cu premii în joc",
  "blocks.subscriptions.values.bullets.three.description": "5 permise suplimentare la Sala Haosului, 10 renașteri ale caracterului",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Următoarea reînnoire",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Reînnoiește acum",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Gestionează planul",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Selectează-ți planul:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configurează abonamentul",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} zi(zile) gratuit",
  "editor.subscriptions-packs.button.then.price.per.day": "Apoi {amount} pe zi",
  "editor.subscriptions-packs.button.then.price.per.week": "Apoi {amount} pe săptămână",
  "editor.subscriptions-packs.button.then.price.per.month": "Apoi {amount} pe lună",
  "editor.subscriptions-packs.button.then.price.per.year": "Apoi {amount} pe an",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Apoi {amount} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Apoi {amount} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Apoi {amount} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Apoi {amount} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Apoi {amount} pe viață",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "ca primă plată, apoi {amount} pe zi",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "ca primă plată, apoi {amount} pe săptămână",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "ca primă plată, apoi {amount} pe lună",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "ca primă plată, apoi {amount} pe an",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "ca primă plată, apoi {amount} pe viață",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "ca primă plată, apoi {amount} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "ca primă plată, apoi {amount} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "ca primă plată, apoi {amount} le fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "ca primă plată, apoi {amount} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.price.per.day": "{amount} pe zi",
  "editor.subscriptions-packs.button.price.per.week": "{amount} pe săptămână",
  "editor.subscriptions-packs.button.price.per.month": "{amount} pe lună",
  "editor.subscriptions-packs.button.price.per.year": "{amount} pe an",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} pe viață",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe zi pentru {periodsCounts} perioadă(perioade), apoi {amount} pe zi",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe săptămână pentru {periodsCounts} perioadă(perioade), apoi {amount} pe săptămână",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe lună pentru {periodsCounts} perioadă(perioade), apoi {amount} pe lună",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe an pentru {periodsCounts} perioadă(perioade), apoi {amount} pe an",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} zile pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} săptămâni pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} luni pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare{periodValue} luni",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} le fiecare {periodValue} ani pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Ulterior {amount} ca primă plată, apoi {amountPromo} pe zi",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Ulterior {amount} ca primă plată, apoi {amountPromo} pe săptămână",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Ulterior {amount} ca primă plată, apoi {amountPromo} pe lună",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Ulterior {amount} ca primă plată, apoi {amountPromo} pe an",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Ulterior {amount} ca primă plată, apoi {amountPromo} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Ulterior {amount} ca primă plată, apoi {amountPromo} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Ulterior {amount} ca primă plată, apoi {amountPromo} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Ulterior {amount} ca primă plată, apoi {amountPromo} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Ulterior {amount} ca primă plată, apoi {amountPromo} pe viață",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "pentru {periodsCounts} perioadă(perioadă), apoi {amount} pe zi",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "pentru {periodsCounts} perioadă(perioadă), apoi {amount} pe săptămână",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "pentru {periodsCounts} perioadă(perioadă), apoi {amount} pe lună",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "pentru {periodsCounts} perioadă(perioadă), apoi {amount} pe an",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "pentru {periodsCounts} perioadă(perioade), apoi {amount} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "pentru {periodsCounts} perioadă(perioadă), apoi {amount} pe viață",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezonul se încheie la data {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezonul începe la data de {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Ulterior {amount}, sezonul se încheie la data de {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Ulterior {amount}, sezonul începe la data de {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe zi",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe săptămână",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe lună",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe an",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Apoi <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Apoi <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Apoi <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Apoi <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe viață",
  "editor.subscriptions-packs.button.then.price.promo.price": "Ulterior <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe zi",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe săptămână",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe lună",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe an",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} zile",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} săptămâni",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} luni",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} la fiecare {periodValue} ani",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pe viață",
  "editor.subscriptions-packs.button.then.price": "Ulterior {amount}",
  "editor.subscriptions-packs.button.season.ends": "Sezonul se încheie la data de {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Sezonul începe la data de {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / zi",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / săptămână",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / lună",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / an",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} zile",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} săptămâni",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} luni",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} ani",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / pe viață",
  "blocks.requirements.values.title": "<h2>Cerințe de sistem</h2>",
  "blocks.requirements.components.platform_req.value.title": "Platformă",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Grafică",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Stocare",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Procesor",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Placă de sunet",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Memorie",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minim",
  "default-data.values.requirements.title.recommended": "Recomandat",
  "default-data.values.requirements.item.directx.minimum": "Versiunea 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Compatibil cu DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Compatibil cu DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Versiunea 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Salut, prietene!",
  "default-data.customization.receipt.description": "Îți mulțumim pentru achiziția ta! Apreciem faptul că joci jocul nostru.",
  "default-data.customization.receipt.footer": "© Toate drepturile rezervate.",
  "blocks.store.components.storeSection.title": "Articole",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Te-ai abonat cu succes!",
  "notification.hero.components.subscribe.subscription.success": "Tocmai ți-am trimis un e-mail de confirmare. Te rugăm să urmezi linkul din e-mail pentru a-ți activa abonamentul.",
  "notification.hero.components.subscribe.error": "Ceva nu a funcționat corect. Te rugăm să încerci mai târziu sau să contactezi echipa noastră de experți la <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Ești deja abonat.",
  /* notifications values END */
  "period.years_short": "ani",
  "period.months_short": "luni",
  "period.weeks_short": "săpt.",
  "period.days_short": "zile",
  "subscription.button.text": "Abonează-te la </br>{fullCurrency} per {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Abonamentul dumneavoastră",
  "client.subscription.button.configuration": "Administrați abonamentul",
  "client.subscription.button.lifetime_text": "Abonați-vă pe viață</br>la {fullCurrency}",
  "client.thank_you_modal.title": "Mulțumim pentru că v-ați abonat!",
  "client.thank_you_modal.description": "Abonamentul dumneavoastră se reînnoiește automat. Plata va fi percepută regulat în conformitate cu condițiile abonamentului. Îl puteți anula oricând.",
  "client.thank_you_modal.input.description": "Dacă doriți să fiți vizibil în lista abonaților noștri, introduceți numele dumneavoastră mai jos.",
  "client.thank_you_modal.button.default.text": "Mulțumesc, respinge",
  "client.thank_you_modal.input.placeholder": "Numele dumneavoastră",
  "client.thank_you_modal.button.show_list": "Arată toți abonații",
  "client.subscribers_list_modal.title": "Lista completă a abonaților",
  "client.subscribers_list_modal.description": "Multe mulțumiri tuturor utilizatorilor care s-au alăturat și s-au abonat la joc!",
  "client.subscribers_list_modal.show_more_button": "Afișează mai multe",
  "client.subscribers_list_modal.anonymous": "și utilizatorilor anonimi",
  "client.footer.button.list_of_subscribers": "Lista abonaților",
  "client.footer.xsollalogo.powered": "Furnizat de", // копирайт в футере
  "client.footer.xsollalogo.sb": "Constructor de site Xsolla",
  "client.empty_subscribers_modal.title": "Abonații vor fi enumerați aici",
  "client.empty_subscribers_modal.description": "Fiți primul care se abonează la jocul nostru.",
  "client.empty_subscribers_modal.button": "Alegeți abonamentul acum",
  "client.news.blurred.description": "Devino un {subscription_name} abonat pentru a debloca acest conținut.",
  "client.news.blurred.button.join": "Alătură-te acum",
  "client.cart_modal.title": "Coș de cumpărături",
  "client.cart_modal.item.pre-order": "(comandă prealabilă)",
  "client.cart_modal.clear_all": "șterge tot",
  "client.cart_modal.item_purchased_description": "Ai achiziționat deja acest articol",
  "client.cart_modal.subtotal_title": "Total",
  "client.cart_modal.subtotal_subtitle": "Se pot aplica taxe și costuri suplimentare",
  "client.cart_modal.continue_button": "continuă cumpărăturile",
  "client.cart_modal.checkout_button": "mergi la casă",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Conectați-vă</button> sau <button class=\'xl_auth\' data-xl-route=\'signup\'>înregistrați-vă</button> pentru a salva articole în coș pentru mai târziu sau pentru a vedea articolele salvate anterior",
  "client.cart_modal.empty_cart_description": "Coșul tău este gol. Mergi la magazin și găsește ceva minunat de cumpărat.",
  "client.cart_modal.empty_cart_open_store_button": "deschide magazin",
  "client.cart_modal.email_label": "Trimite cheia de activare la:",
  "client.cart_modal.email_description": "Veți primi o cheie de activare pe care trebuie să o activați pentru a începe să jucați.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Aplicați",
  "client.cart_modal.promo_codes.remove": "Eliminați",
  "client.cart_modal.promo_codes.placeholder": "Cod promoțional",
  "client.cart_modal.promo_codes.error_incorrect": "Cod promoțional greșit.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Limita de valorificare a codului a fost atinsă.",
  "client.cart_modal.promo_codes.error_expired": "Acest cod promoțional a expirat.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Codul promoțional nu a fost aplicat. Încercați din nou mai târziu.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Codul promoțional nu este valabil pentru articolele din coșul dumneavoastră.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Selectați o platformă de joc pentru a continua.",
  "client.cart_modal.promo_codes.select_drm": "Selectați platforma",
  "client.cart_modal.promo_codes.tooltip_drm": "Pentru a schimba platforma, eliminați codul promoțional și apoi aplicați-l din nou",
  "client.bundle_modal.group_items": "Articole în pachet",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronz</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Conținut digital</h3><p>✓ Artă</p><p>✓ Imagini de fundal</p><p><span style=\'color:rgb(127, 96, 0);\'>× Coloane sonor în joc </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Conținut din spatele culiselor</span></p><p><br></p><h3>3 Loot box-uri</h3><h3>în joc</h3><p>✓ Înfățișări ale personajului</p><p><span style=\'color:rgb(127, 96, 0);\'>× Efecte vizuale </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Cărți de acțiune</span></p><p><br></p><h3>Câștiguri în joc</h3><p>✓ x1 Trecere suplimentară spre Hall of Chaos</p><p>✓ x1 renaștere de personaj</p><p><br></p><h3>Discord</h3><p>✓ Acces la canalul jucătorilor</p><p><span style=\'color:rgb(127, 96, 0);\'>× Acces la canalul dezvoltatorilor</span></p><p><br></p><h3>Forum</h3><p>✓ Nume de utilizator exclusiv color</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji exclusiv</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Imagine animată a profilului </span></p><p>✓ x2 puncte suplimentare pentru a evalua postarea (zilnic)</p><p><br></p><h3>Merch</h3><p>✓ Insigne și autocolante (la fiecare 6 luni)</p><p>✓ Poster de înaltă calitate (la fiecare 6 luni)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Jucării de pluș de marcă (în fiecare an)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Figurine de înaltă calitate (în fiecare an)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Evenimente offline</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Acces gratuit la evenimentele noastre privind jocurile</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Vizitați sediul companiei (in fiecare an)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ oferă acces la Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Argint</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Conteúdos digitais</h3><p>✓ Arte</p><p>✓ Papéis de parede</p><p><span style=\"color:rgb(127, 96, 0);\">× Trilha sonora do jogo</span></p><p>✓ Conteúdos por trás das cenas</p><p><br></p><h3>6 Caixas de recompensa</h3><h3>no jogo</h3><p>✓ Visuais de personagens</p><p>✓ Efeitos visuais</p><p><span style=\"color:rgb(127, 96, 0);\">× Cartas de história</span></p><p><br></p><h3>Vantagens nos jogos</h3><p>✓ x2 Passe extra ao Hall do Caos</p><p>✓ x2 renascimento de personagem</p><h3><br></h3><h3>Discord</h3><p>✓ Acesso ao canal de jogadores do jogo</p><p><span style=\"color:rgb(127, 96, 0);\">× Acesso ao canal dos desenvolvedores</span></p><p><br></p><h3>Fórum</h3><p>✓ Cor de apelido exclusiva</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji exclusivo(s)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Imagem de perfil animada</span></p><p>✓ 5x pontos extras para classificar a postagem (diariamente)</p><p><br></p><h3>Merchandising</h3><p>✓ Broches &amp; adesivos (a cada 6 meses)</p><p>✓ Pôster de alta qualidade (a cada 6 meses)</p><p><span style=\"color:rgb(127, 96, 0);\">× Brinquedos de pelúcia de marca (a cada ano)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Estatuetas de alta qualidade (a cada ano)</span></p><p><br></p><h3>Eventos off-line</h3><p>✓ Acesso gratuito às nossas convenções de jogos</p><p><span style=\"color:rgb(127, 96, 0);\">× Visite o estúdio da empresa (a cada ano)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ oferă acces la Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Aur</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Conținut digital</h3><p>✓ Artă</p><p>✓ Imagini de fundal</p><p>✓ Coloane sonor în joc</p><p>✓ Conținut din spatele culiselor</p><p><br></p><h3>9 Loot box-uri</h3><h3>în joc</h3><p>✓ Înfățișări ale personajului</p><p>✓ Efecte vizuale</p><p>✓ Cărți de acțiune</p><p><br></p><h3>Câștiguri în joc</h3><p>✓ x5 Trecere suplimentară spre Hall of Chaos</p><p>✓ x10 renaștere de personaj</p><p><br></p><h3>Discord</h3><p>✓ Acces la canalul jucătorilor</p><p>✓ Acces la canalul dezvoltatorilor</p><p><br></p><h3>Forum</h3><p>✓ Nume de utilizator exclusiv color</p><p>✓ Emoji exclusiv</p><p>✓ Imagine animată a profilului</p><p>✓ x12 puncte suplimentare pentru a evalua postarea (zilnic)</p><p><br></p><h3>Merch</h3><p>✓ Insigne și autocolante (la fiecare 6 luni)</p><p>✓ Poster de înaltă calitate (la fiecare 6 luni)</p><p>✓ Jucării de pluș de marcă (în fiecare an)</p><p>✓ Figurine de înaltă calitate (în fiecare an)</p><p><br></p><h3>Evenimente offline</h3><p>✓ Acces gratuit la evenimentele noastre privind jocurile</p><p>✓ Vizitați sediul companiei (in fiecare an)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ oferă acces la Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Conținut digital</h3><p>Artă</p><p>Imagini de fundal</p><p><span>Coloane sonor în joc </span></p><p><span>Conținut din spatele culiselor</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Loot box-uri</h3><h3>în joc</h3><p>Înfățișări ale personajului</p><p><span>Efecte vizuale </span></p><p><span>Cărți de acțiune</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Câștiguri în joc</h3><p>x1 Trecere suplimentară spre Hall of Chaos</p><p>x1 renaștere de personaj</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Acces la canalul jucătorilor</p><p><span>× Acces la canalul dezvoltatorilor</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Nume de utilizator exclusiv color</p><p><span>× Emoji exclusiv </span></p><p><span>× Imagine animată a profilului </span></p><p>x2 puncte suplimentare pentru a evalua postarea (zilnic)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merch</h3><p> Insigne și autocolante (la fiecare 6 luni)</p><p> Poster de înaltă calitate (la fiecare 6 luni)</p><p><span> Jucării de pluș de marcă (în fiecare an)</span></p><p><span> Figurine de înaltă calitate (în fiecare an)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Evenimente offline</h3><p>✓ Acces gratuit la evenimentele noastre privind jocurile</p><p>✓ Vizitați sediul companiei (in fiecare an)</p>",
  "client.buy_button.text": "Cumpără acum",
  "client.buy_button.unavailable": "Indisponibil",
  "client.buy_button.pre-order": "Comandă prealabilă",
  "client.buy_button.checkout": "Mergi la casă",
  "client.store.cart.add_button": "Adăugați în coș",
  "client.store.cart.header_button": "Coș de cumpărături",
  "client.store.no_items": "Deocamdată magazinul este gol. Vino mai târziu!",
  "client.store.error.gt10_gk": "Pentru a te proteja împotriva fraudei, poți achiziționa doar 10 chei simultan.",
  "client.store.error.no_keys": "Jocul este temporar fără stoc și va fi reumplut în curând. Nu ezitați să ne contactați pentru actualizări.",
  "client.store.item.purchased": "Achizionat",
  "client.store.item.price_from": "de la {price}",
  "client.store.item.show_more": "Afișează detalii",
  "client.store.successful_purchase": "Succes. Mulțumim pentru achiziție!",
  "client.store.not_enough_vc": "Nu este suficientă monedă virtuală.</br>Te rugăm să încarci pentru a finaliza achiziția.",
  "client.store.non_consumable_item": "Ai cumpărat deja acest articol și nu mai este disponibil.",
  "client.store.user_id_input_before_submit_hint": "Articolele vor fi creditate în acest cont.",
  "client.store.user_id_input_after_submit_hint": "Cumperi pentru:",
  "client.common_error": "Ceva nu a funcționat corect. Te rugăm să încerci mai târziu sau să contactezi echipa noastră de experți la <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Soldul tău",
  "client.header.logout": "Deconectare",
  "client.retailers.edition.title": "Selectați-vă copia",
  "client.retailers.country.title": "Selectați-vă țara",
  "client.retailers.platform.title": "Selectați platforma",
  "client.retailers.retailer.title": "Selectați vânzătorul",
  "client.retailers.edition.digital": "Digital",
  "client.retailers.edition.physical": "Fizic",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Cumpărați de la dezvoltator",
  "client.retailers.expand_button.collapse": "Restrânge",
  "client.retailers.buy_button.buy": "Cumpără acum",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Cele mai populare",
  "blocks.retailers.components.retailers.standart.value.subtext": "Disponibil acum",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Ediția Standard</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Cheie de joc, insignă exclusivă, coloană sonoră, hartă și pachet suplimentar cu armură",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TITLUL JOCULUI TĂU</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Jocul tău conduce jucătorii către o mare aventură plină de detractori, monștri, căutători de aur și mare pericol.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Disponibil pentru",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Introdu e-mailul tău",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Abonează-te la newsletter pentru a afla cele mai recente știri și noutăți.",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Buton de configurare",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Cumpără acum",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Buton de configurare",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Buton de configurare",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Buton de configurare",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Indisponibil",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Buton de configurare",
  "client.topup.input.errorMessage": "Ceva nu a funcționat bine. Asigurați-vă că ID-ul dumneavoastră de utilizator este corect sau încercați din nou mai târziu.",
  "client.topup.input.successMessage": "V-ați conectat cu ID-ul {userID}. Acum puteți face cumpărături pe site.",
  "client.topup.input.username": "Utilizator: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Nivel: {level}",
  "client.topup.input.user_not_found": "Nu am putut găsi un utilizator cu ID-ul pe care l-ați specificat.<br/>Încercați din nou.",
  "client.topup.input.serverError": "A survenit o eroare.<br>Dacă vedeți acest mesaj mai mult decât o dată, {contact_us}.",
  "client.topup.input.serverError.contact_us": "contactați-ne",
  "client.topup.authorized_title": "Cumperi pentru:",
  "blocks.store.values.custom_title": "<h2>{gameTitle} Magazin online</h2>"
};

module.exports = localizations;
