"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSelfOrParentDocumentLogin = exports.selectDocumentWebhook = exports.selectDocumentUserIdLoginId = exports.selectDocumentParent = exports.selectDocumentLogin = exports.selectDocumentIsRequiredWebhook = void 0;

/** @prettier  */
const selectDocumentLogin = document => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.loginId;
};

exports.selectDocumentLogin = selectDocumentLogin;

const selectDocumentWebhook = document => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.webhookUrl;
};

exports.selectDocumentWebhook = selectDocumentWebhook;

const selectDocumentIsRequiredWebhook = document => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.requiredWebhook;
};

exports.selectDocumentIsRequiredWebhook = selectDocumentIsRequiredWebhook;

const selectDocumentUserIdLoginId = document => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.userIdLoginId;
};

exports.selectDocumentUserIdLoginId = selectDocumentUserIdLoginId;

const selectDocumentParent = document => document?.site?.parent;

exports.selectDocumentParent = selectDocumentParent;

const selectSelfOrParentDocumentLogin = document => {
  const parentDocument = selectDocumentParent(document);
  return selectDocumentLogin(parentDocument || document);
};

exports.selectSelfOrParentDocumentLogin = selectSelfOrParentDocumentLogin;