/** @prettier */
// @flow
import React from 'react';
import ReactDOM from 'react-dom';

import type { Classed } from '#common/utils/store';
import type { GameKeysType } from '../../../utils/types';
import type { ScriptsData, Translations } from '../../types';
import type { CartItemTemplateProps } from './types';

import { isCustomAuth } from '../../../experiments/customAuth/utils';
import { getCurrencyDisplay } from '../../../utils/currency-formatter';
import { once } from '../../../utils/function-helper';
import { getAnalyticsTag, normalizePrice } from '../../../utils/store-helper';
import { validateEmail } from '../../../utils/validations';
import {
  handleCheckoutCart,
  initCheckoutCartEventHandler,
} from '../../actions/checkoutCart';
import { analyticsManager } from '../../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../afterBlocks/analytics/analyticsCategories';
import {
  isAfterRegistration,
  isAfterRedirectRegistration,
  clearAfterRedirectRegistration,
} from '../../beforeBlocks/urlSearchParams';
import events from '../../events';
import { getTokenFromCookie } from '../../helpers/common';
import stimulus from '../../stimulus/stimulus';
import { getStore } from '../../store';
import CartItemList from '../../templates/CartItemList';
import { initOneCustomSelector } from '../../ui-components/editor/customSelector';
import * as modalWindow from '../../ui-components/editor/modalWindow';
import {
  ATTRIBUTE_TO_ROUTE,
  clearLastLoginSource,
  isLoginLastSource,
  initLoginButton,
  LOGIN_SOURCES,
  openLogin,
} from '../user-account-services';
import { BundleCartItem } from './cart/BundleCartItem';
import { Cart } from './cart/Cart';
import { GameKeyCartItem } from './cart/GameKeyCartItem';
import { UniversalCart } from './cart/UniversalCart';
import { VICartItem } from './cart/VICartItem';
import { toggleCartEmail } from './cartEmail';
import { setTranslations } from './cartModal/setTranslations';
import { StoreAPIDataService } from './services/StoreAPIDataService';

import { LandingType } from '#common/model/landing';

const COINS_PACK_URL =
  'https://cdn3.xsolla.com/img/misc/images/4cb7b6690fe6d55b7c9bb966af466eb4.png';

const OPTIONS_LENGTH = 10;
const options = [...Array(OPTIONS_LENGTH)].map((_, index) => index + 1);
export const CART_PURCHASE_ID = 'cart_purchase';

const showDefaultStateCart = (modal) => {
  const defaultCart = modal.querySelector('[data-cart-modal-default-cart]');
  const emptyCart = modal.querySelector('[data-cart-modal-empty-cart]');
  const loader = modal.querySelector('[data-cart-modal-loader]');
  if (defaultCart && emptyCart && loader) {
    defaultCart.hidden = false;
    emptyCart.hidden = true;
    loader.hidden = true;
  }
};

const showEmptyStateCart = (modal) => {
  const defaultCart = modal.querySelector('[data-cart-modal-default-cart]');
  const emptyCart = modal.querySelector('[data-cart-modal-empty-cart]');
  const loader = modal.querySelector('[data-cart-modal-loader]');
  if (defaultCart && emptyCart && loader) {
    defaultCart.hidden = true;
    emptyCart.hidden = false;
    loader.hidden = true;
  }
};

const showLoadStateCart = (modal) => {
  const defaultCart = modal.querySelector('[data-cart-modal-default-cart]');
  const emptyCart = modal.querySelector('[data-cart-modal-empty-cart]');
  const loader = modal.querySelector('[data-cart-modal-loader]');
  if (defaultCart && emptyCart && loader) {
    defaultCart.hidden = true;
    emptyCart.hidden = true;
    loader.hidden = false;
  }
};

const setCartUpdatingState = (modal, hidingState = true) => {
  const updatingLoaderCart = modal.querySelector(
    '[data-cart-modal-updating-loader]'
  );
  if (updatingLoaderCart) {
    updatingLoaderCart.hidden = hidingState;
  }
};

const setLoginHint = (modal, hidingState) => {
  const loginHint = modal.querySelector('[data-cart-modal-footer-auth]');
  if (loginHint) {
    loginHint.hidden = hidingState;
  }
};

const controlsInitialize = ({
  data,
  modal,
  cart,
}: {
  data: ScriptsData,
  modal: Object,
  cart: UniversalCart,
}) => {
  const {
    isPreviewFrameMode,
    cartId,
    projectId,
    locale,
    analyticsCounterId,
    renderMode,
    payStationReturnUrl,
  } = data;
  modalWindow.initialize(modal);

  const clearAllButton = modal.querySelector('[data-cart-modal-clear-all]');
  if (clearAllButton) {
    clearAllButton.addEventListener('click', async () => {
      showLoadStateCart(modal);
      await cart.clearAll();
      setTimeout(showEmptyStateCart, 1000, modal);
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.CLEAR,
        page: `landing_cart-${AnalyticsActions.CLEAR}`,
      });
    });
  }

  const closeButton = modal.querySelector('.ui-site-modal-window__close');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.CLOSE_CART,
        page: `landing_cart-${AnalyticsActions.CLOSE}`,
      });
    });
  }

  const continueButton = modal.querySelector(
    '.ui-site-cart-modal__continue-button'
  );
  if (continueButton) {
    continueButton.addEventListener('click', () => {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.CONTINUE,
        page: `landing_cart-${AnalyticsActions.CONTINUE}`,
      });
    });
  }

  const openStoreButton = modal.querySelector(
    '.ui-site-cart-modal__empty-cart-open-store-button'
  );
  [continueButton, openStoreButton].forEach((button) => {
    if (button) {
      button.addEventListener('click', () => {
        modalWindow.closeModalWindow(modal);
      });
    }
  });

  const goToCheckout = modal.querySelector(
    '.ui-site-cart-modal__checkout-button'
  );
  if (goToCheckout) {
    goToCheckout.setAttribute('data-buy-button', true);
    goToCheckout.setAttribute(
      'data-xa-extra',
      `{"id":"${CART_PURCHASE_ID}","block":"store", "title": "shopping cart"}`
    );
    goToCheckout.addEventListener('click', async () => {
      const { zIndex } = window.getComputedStyle(modal);
      const userToken = getTokenFromCookie(isPreviewFrameMode);
      const xUserData = {
        email: userToken
          ? undefined
          : modal.querySelector('[data-cart-modal-email-input]').value,
        xsolla_product_tag: getAnalyticsTag(analyticsCounterId),
      };
      if (!userToken && cart.hasInventoryItems()) {
        openLogin({
          source: LOGIN_SOURCES.CART,
          isCustomAuth: isCustomAuth(),
        });
        analyticsManager.sendEvent({
          category: AnalyticsCategories.CART,
          event: AnalyticsActions.OPEN_LOGIN,
          page: `landing_${AnalyticsActions.OPEN_LOGIN}`,
        });
      } else {
        handleCheckoutCart({
          projectId,
          cartId,
          locale,
          zIndex: parseInt(zIndex, 10) + 1,
          xUser: xUserData,
          isAuth: !!userToken,
          renderMode,
          payStationReturnUrl,
          analyticsCounterId,
        });
        analyticsManager.sendEvent({
          category: AnalyticsCategories.CART,
          event: AnalyticsActions.CHECKOUT,
          page: `landing_cart-${AnalyticsActions.CHECKOUT}`,
        });
      }
    });
  }

  // hide sign-in hint if user is authorized
  const userToken = getTokenFromCookie(isPreviewFrameMode);
  setLoginHint(modal, !!userToken);

  const authButtons = modal.querySelectorAll(
    '[data-cart-modal-footer-auth] > button'
  );
  if (authButtons) {
    authButtons.forEach((button) => {
      const route = ATTRIBUTE_TO_ROUTE[button.dataset.xlRoute];
      button.className = 'ui-site-cart-modal__footer-auth-button';
      initLoginButton({ button, source: LOGIN_SOURCES.CART, route });
    });
  }

  if (data.landingType === LandingType.TOPUP) {
    setLoginHint(modal, true);
  }

  const userEmail = modal.querySelector('[data-cart-modal-email-input]');
  if (userEmail) {
    userEmail.addEventListener('focus', () => {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.INPUT_EMAIL,
        page: `landing_cart-${AnalyticsActions.INPUT_EMAIL}`,
      });
    });
    userEmail.addEventListener('input', (event) => {
      goToCheckout.disabled = !validateEmail(event.target.value);
    });
  }
};

export const renderSubtotal = (modal: HTMLElement, cart: Cart) => {
  const subtotal = cart.amount();
  if (!subtotal) {
    return;
  }
  const subtotalHTML = `${getCurrencyDisplay(
    subtotal.currency,
    subtotal.amount
  )}`;

  const withoutDiscount = `${getCurrencyDisplay(
    subtotal.currency,
    subtotal.amount_without_discount
  )}`;

  const subtotalPrice = modal.querySelector('[data-cart-modal-subtotal-price]');
  if (subtotalPrice) {
    subtotalPrice.innerHTML = subtotalHTML;
  }
  const withoutDiscountPrice = modal.querySelector(
    '[data-cart-modal-price-without-discount]'
  );
  if (withoutDiscountPrice) {
    withoutDiscountPrice.innerHTML =
      subtotal.amount_without_discount !== subtotal.amount
        ? withoutDiscount
        : '';
  }
};

type ErrorItem = {
  sku: $PropertyType<GameKeyCartItem, 'sku'>,
  quantity: $PropertyType<GameKeyCartItem, 'quantity'>,
  errorCode: string,
  errorMessage: string,
};

type SuccessItem = Classed<GameKeyCartItem | VICartItem>;

type Item = SuccessItem | ErrorItem;

export const collectTemplateItemsProps = (
  items: Item[],
  storeAPIDataService: StoreAPIDataService,
  translations: Translations
) =>
  items.reduce((accumulator: CartItemTemplateProps[], _item: Item) => {
    const isWarning = Object.prototype.hasOwnProperty.call(
      _item,
      'errorMessage'
    );
    const item = isWarning ? storeAPIDataService.findBySku(_item.sku) : _item;
    const {
      sku,
      imageUrl,
      name,
      // $FlowFixMe
      isTemporaryItem = false,
    } = ((item: any): SuccessItem);
    let amountWithoutDiscount;
    let amount;
    let currency;

    if (((item: any): SuccessItem).price) {
      ({ amountWithoutDiscount, amount, currency } =
        ((item: any): SuccessItem).price);
    }

    const drms = item instanceof GameKeyCartItem && item.getAvailableUnits();
    const oneDrmName =
      drms &&
      ((drms.length === 1 && drms[0].drm_name) ||
        (item instanceof GameKeyCartItem && item.isBonus && item.drmName));
    const isPreOrder = drms && drms[0] && drms[0].is_pre_order;

    const itemProps: CartItemTemplateProps = {
      sku,
      imageUrl: imageUrl || COINS_PACK_URL,
      imageGameClass:
        drms || item instanceof BundleCartItem
          ? 'cart-order-item__image--for-game'
          : '',
      name,
      drms,
      oneDrmName,
      preOrderText:
        isPreOrder && translations['client.cart_modal.item.pre-order'],
      selectedDRM: item instanceof GameKeyCartItem ? item.selectedDRM : '',
      isPurchased: isWarning,
      purchasedText:
        translations['client.cart_modal.item_purchased_description'],
      isShowSelector: isWarning
        ? false
        : ((item: any): SuccessItem).checkIfCanChangeQuantity(),
      options,
      amountWithoutDiscount: isWarning
        ? ''
        : getCurrencyDisplay(currency, amountWithoutDiscount),
      isDiscount: !isWarning && amountWithoutDiscount !== amount,
      amount: isWarning ? '' : getCurrencyDisplay(currency, amount),
      bundleTags: item instanceof BundleCartItem ? item.tags : null,
      bonusText: translations['client.cart_modal.item.bonus'],
      isTemporaryItem,
      // $FlowFixMe
      isBonus: item.isBonus,
      // $FlowFixMe
      quantity: item.quantity,
      selectPlatformText:
        translations['client.cart_modal.promo_codes.select_drm'],
    };

    accumulator.push(itemProps);
    return accumulator;
  }, []);

function renderCartItems(
  modal,
  cart,
  storeAPIDataService,
  translations,
  isPreviewFrameMode
) {
  const configureCartItems = () => {
    const modalCartItems = [
      ...modal.querySelectorAll('[data-cart-modal-item-sku]'),
    ];
    const currentCartItemsDrms = modalCartItems.map(
      (item) => item.dataset.cartModalItemDrm
    );
    modalCartItems.forEach((item) => {
      const itemSKU = item.dataset.cartModalItemSku;
      const itemDRM = item.dataset.cartModalItemDrm;
      const cartItem = cart.getItemBySKU(itemSKU, itemDRM);
      if (!cartItem) {
        return;
      }

      const drmSelector: HTMLSelectElement = (item.querySelector(
        '[data-cart-modal-item-drm-selector]'
      ): any);

      if (drmSelector && cartItem instanceof GameKeyCartItem) {
        drmSelector.querySelectorAll('option').forEach((option) => {
          // $FlowFixMe
          const optionDrm = option.value;
          const isDisabled: boolean =
            !!itemDRM &&
            optionDrm !== itemDRM &&
            currentCartItemsDrms.includes(optionDrm);
          // $FlowFixMe
          option.disabled = isDisabled;
        });
        drmSelector.value = cartItem.selectedDRM;
        drmSelector.addEventListener('change', async (event: Event) => {
          // $FlowFixMe
          const isTemporaryItem = event.target.dataset.cartModalItemIsTemporary;

          !isTemporaryItem && setCartUpdatingState(modal, false);
          const prevDrm = cartItem.selectedDRM;
          // $FlowFixMe
          cartItem.selectedDRM = event.target.value;
          analyticsManager.sendEvent({
            category: AnalyticsCategories.CART,
            event: AnalyticsActions.SELECT_DRM,
            label: cartItem.selectedDRM,
            page: 'landing_cart_select-drm-promocode',
          });
          // $FlowFixMe
          const mainDataItem: GameKeysType | typeof undefined =
            storeAPIDataService.findBySku(cartItem.sku);
          if (mainDataItem && mainDataItem.unit_items) {
            const selectedSKU = mainDataItem.unit_items.find(
              (drm) => drm.sku === cartItem.selectedDRM
            );
            if (selectedSKU) {
              const { price } = selectedSKU;

              if (price) {
                cartItem.price = normalizePrice(price);
              }
            }
          }

          if (isTemporaryItem) {
            const cartModalPromoController = stimulus.controllers.find(
              ({ identifier }) => identifier === 'cart-modal-promo'
            );
            cartModalPromoController.checkTemporaryItems();
            // Do not update the cart if change DRM in Temporary Item
            return;
          }

          await cart.deleteBySku(prevDrm);
          await cart.save();
          renderCartItems(
            modal,
            cart,
            storeAPIDataService,
            translations,
            isPreviewFrameMode
          );
          renderSubtotal(modal, cart);
          setCartUpdatingState(modal, true);
        });
        initOneCustomSelector(drmSelector);
      }

      const countSelector = item.querySelector(
        '[data-cart-modal-item-count-selector]'
      );
      if (countSelector) {
        // $FlowFixMe
        countSelector.value = cartItem.quantity;
        countSelector.addEventListener('change', async (event: Event) => {
          setCartUpdatingState(modal, false);
          // $FlowFixMe
          if (event.target.value) {
            // TODO возможно нужно нотифицировать
            const newQuantity = parseInt(event.target.value, 10);
            cart.setQuantityBySku(newQuantity, itemSKU);
            await cart.save();
            renderSubtotal(modal, cart);
            setCartUpdatingState(modal, true);
          }
        });
        initOneCustomSelector(countSelector);
      }

      const removeButton = item.querySelector('[data-cart-modal-item-remove]');
      if (removeButton) {
        let isDeleting = false;
        removeButton.addEventListener('click', async () => {
          if (isDeleting) {
            return;
          }
          setCartUpdatingState(modal, false);
          isDeleting = true;
          await cart.delete(cartItem);
          analyticsManager.sendEvent({
            category: AnalyticsCategories.CART,
            event: AnalyticsActions.DELETE,
            label: itemSKU,
            page: `landing_cart-${AnalyticsActions.DELETE}`,
          });
          await cart.load();
          toggleCartEmail(modal, cart);

          if (!cart.items.length && !cart.warnings().length) {
            showEmptyStateCart(modal);
            isDeleting = false;
            setCartUpdatingState(modal, true);
            return;
          }
          renderCartItems(
            modal,
            cart,
            storeAPIDataService,
            translations,
            isPreviewFrameMode
          );
          renderSubtotal(modal, cart);
          isDeleting = false;
          setCartUpdatingState(modal, true);
        });
      }
    });
  };

  const userToken = getTokenFromCookie(isPreviewFrameMode);

  const existingItems =
    userToken && cart.warnings().length
      ? [...cart.items, ...cart.warnings()]
      : cart.items;

  const cartItemsProps = collectTemplateItemsProps(
    // $FlowFixMe
    existingItems,
    storeAPIDataService,
    translations
  );

  const parentElement = modal.querySelector('[data-cart-modal-order-list]');
  if (parentElement) {
    ReactDOM.unmountComponentAtNode(parentElement);
    ReactDOM.render(
      <CartItemList cartItemsProps={cartItemsProps} />,
      parentElement
    );
  }

  configureCartItems();
}

const tryAutoopen = (modal, cart) => {
  if (
    isLoginLastSource(LOGIN_SOURCES.CART) &&
    (isAfterRegistration() || isAfterRedirectRegistration()) &&
    (cart.quantity > 0 || cart.warnings().length > 0)
  ) {
    toggleCartEmail(modal, cart);
    modalWindow.openModalWindow(modal, 'Cart');
    clearLastLoginSource();
    clearAfterRedirectRegistration();
  }
};

const waitStoreDataLoad = (callback: Function) => {
  let blocks: string[] = [];
  window.addEventListener(events.store.init, (event) => {
    const { blockId } = event.detail;
    blocks.push(blockId);
  });
  window.addEventListener(events.store.dataLoaded, (event) => {
    const { blockId } = event.detail;
    blocks = blocks.filter((id) => id !== blockId);
    if (!blocks.length) {
      callback();
    }
  });
};

const updateCartModal = (
  modal,
  cart,
  storeAPIDataService,
  translations,
  isPreviewFrameMode
) => {
  const cartIsEmpty = !cart.items.length && !cart.warnings().length;
  if (cartIsEmpty) {
    showEmptyStateCart(modal);
  } else {
    renderCartItems(
      modal,
      cart,
      storeAPIDataService,
      translations,
      isPreviewFrameMode
    );
    renderSubtotal(modal, cart);
    showDefaultStateCart(modal);
  }
};

export const openCartModal =
  ({
    analyticsCategory,
    cart,
    storeAPIDataService,
    translations,
    isPreviewFrameMode,
  }: {
    analyticsCategory?: string,
    cart: UniversalCart,
    storeAPIDataService: StoreAPIDataService,
    translations: {
      [string]: string,
    },
    isPreviewFrameMode: boolean,
  }) =>
  () => {
    const modal = document.querySelector('.ui-site-cart-modal');
    if (!modal) {
      return;
    }
    updateCartModal(
      modal,
      cart,
      storeAPIDataService,
      translations,
      isPreviewFrameMode
    );
    tryAutoopen(modal, cart);
    analyticsManager.sendEvent({
      category: analyticsCategory,
      event: AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW,
      page: `landing_${AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW}`,
    });
  };

const initCartModal = ({
  data,
  translations,
}: {
  data: ScriptsData,
  translations: {
    [string]: string,
  },
}) => {
  const { isPreviewFrameMode } = data;

  const store = getStore();
  const { landingServices } = store.getState();
  const { cart, storeAPIDataService } = landingServices;

  const modal = document.querySelector('.ui-site-cart-modal');
  if (!modal) {
    return;
  }

  initCheckoutCartEventHandler();

  if (modal.firstElementChild) {
    modal.firstElementChild.classList.add('ui-site-cart-modal__content');
  }

  setTranslations(modal);
  controlsInitialize({
    data,
    modal,
    cart,
  });
  waitStoreDataLoad(
    openCartModal({
      cart,
      storeAPIDataService,
      translations,
      isPreviewFrameMode,
    })
  );
  cart.addListener(() => {
    updateCartModal(
      modal,
      cart,
      storeAPIDataService,
      translations,
      isPreviewFrameMode
    );
  });
};

export const initCartModalOnce = once(initCartModal);
