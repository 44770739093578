var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Mostrar más",
  "client.header.login.button": "Iniciar sesión",
  "client.header.logout.button": "Cerrar sesión",
  "client.user-id-modal.input-placeholder": "ID de usuario",
  "client.user-id-modal.input-incorrect-id": "El ID de usuario no es correcto.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Intenta copiar y pegar el ID del juego.",
  "client.user-id-modal.continue-button": "Continuar",
  "client.user-id-modal.go-back": "Volver",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Mostrar más",
  "client.privacy-settings.open-button": "Ajustes de privacidad",
  "client.usa-privacy-settings.open-button": "No venda mis datos",
  "client.user-id-modal.continue-with": "Continuar con {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Introduce tu ID de usuario",
  "document.pages.auth.subtitle": "o con ID",
  "document.pages.auth.placeholder": "Ejemplo: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "¿No encuentras tu ID de usuario?",
  "document.pages.auth.instruction.steps.description": "Describe cómo el usuario puede encontrar su ID personal. Puedes añadir varios pasos o imágenes.",
  "document.pages.seo.title": "¡El Nuevo Juego ya está disponible!",
  "document.pages.seo.description": "Tu Juego embarca a los jugadores en una gran aventura repleta de detractores, monstruos, buscadores de oro y graves amenazas.",
  "document.pages.seo.ogTitle": "¡El Nuevo Juego ya está disponible!",
  "document.pages.seo.ogDescription": "Tu Juego embarca a los jugadores en una gran aventura repleta de detractores, monstruos, buscadores de oro y graves amenazas.",
  "default-data.templates.google-play.contact-us": "Contacta con nosotros {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "RECARGA EL MONEDERO EN EL JUEGO",
  "blocks.topup.values.description": "Introduce el ID de usuario",
  "blocks.topup.values.userIdPlaceholder": "Ejemplo: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "¿No encuentras tu ID de usuario?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Nombre del paso",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Describe cómo el usuario puede encontrar su ID personal. Puedes añadir varios pasos o imágenes.",
  "default-data.templates.topup.app-support": "Asistencia aplicación",
  "default-data.templates.topup.age-raiting": "Clasificación edad",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TÍTULO DE TU JUEGO</h1>",
  "blocks.retailers.values.title": "<h2>Elige tu edición</h2>",
  "blocks.hero.values.description": "Tu Juego embarca a los jugadores en una gran aventura repleta de detractores, monstruos, buscadores de oro y graves amenazas.",
  "blocks.hero.values.affiliate": "Los beneficios derivados de esta compra se compartirán con tu afiliado",
  "blocks.hero.components.platforms.label": "Disponible para",
  "blocks.hero.components.subscribe.label": "Escribe tu correo electrónico",
  "blocks.hero.components.subscribe.values.text" : "Suscríbete a nuestro boletín para recibir las últimas noticias y actualizaciones",
  "blocks.hero.components.scroll.label": "Desplázate",
  "blocks.footer.values.name": "Escribe tu texto",
  "blocks.footer.values.affiliate": "Únete al programa de afiliados",
  "blocks.hero.components.buy.label": "Cómpralo ya",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.hero.components.customButton.link.label": "Configura el botón",
  "blocks.header.components.customButton.link.label": "Configura el botón",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.packs.components.pack.customButton.link.label": "Configura el botón",
  "blocks.packs.components.pack.standart.components.buy.label": "Cómpralo ya",
  "blocks.packs.components.pack.premium.components.buy.label": "Cómpralo ya",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Cómpralo ya",
  "blocks.packs.components.pack.platinum.components.buy.label": "Cómpralo ya",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.packs.components.pack.standart.value.ribbon": "Más popular",
  "blocks.packs.components.pack.premium.value.ribbon": "Más popular",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Más popular",
  "blocks.packs.components.pack.platinum.value.ribbon": "Más popular",
  "blocks.description.values.title": "<h2>DESCRIPCIÓN DEL JUEGO</h2>",
  "blocks.description.components.text.label": "Tu Juego lleva a los jugadores a una gran aventura repleta de detractores, monstruos, buscadores de oro y graves amenazas. Ocurrieron trágicos sucesos que trastornaron tu vida, y ahora has de luchar por sobrevivir en un nuevo mundo hostil. ¡Descubre, explora y conquista por tu supervivencia en las diferentes dimensiones de la realidad!",
  "blocks.faq.values.title": "<h2>FAQ (Preguntas frecuentes)</h2>",
  "blocks.faq.components.question.question": "¿Cómo puedo jugar a Your Game?",
  "blocks.faq.components.question.answer": "Para jugar a Your Game, necesitas comprarlo y proporcionarnos tu correo electrónico. Recibirás la clave de activación: introdúcela y disfruta del juego.",
  "blocks.news.values.title": "<h2>Noticias</h2>",
  "blocks.news.values.description": "Consulta nuestros últimos artículos para conocer las novedades.",
  "blocks.store.values.title": "<h2>Tienda</h2>",
  "blocks.store.buy_button.text": "COMPRAR AHORA POR {amount}",
  "blocks.embed.values.title": "<h2>Widgets de redes sociales</h2>",
  "blocks.embed.components.twitter.title": "<h3>FUENTE (FEED) DE TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>FUENTE (FEED) DE FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Selecciona tu edición",
  "blocks.packs.values.description": "Cada edición incluye compensaciones y beneficios especiales que obtendrás después de la compra.",
  "blocks.packs.components.pack.standart.value.subtext": "¡Ya disponible!",
  "blocks.packs.components.pack.premium.value.subtext": "¡Ya disponible!",
  "blocks.packs.components.pack.ultimate.value.subtext": "¡Ya disponible!",
  "blocks.packs.components.pack.platinum.value.subtext": "¡Ya disponible!",
  "blocks.packs.components.pack.standart.value.title": "<h3>Edición Estándar</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Edición Prémium</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Edición Platino</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Edición Definitiva</h3>",
  "blocks.packs.components.pack.standart.value.description": "Clave del juego, insignia exclusiva, banda sonora, mapa y pack de armadura adicional",
  "blocks.packs.components.pack.premium.value.description": "Clave del juego, insignia exclusiva, banda sonora, mapa, pack de armadura adicional y pack de arma adicional",
  "blocks.packs.components.pack.platinum.value.description": "Clave del juego, insignia exclusiva, banda sonora, mapa, libro de ilustraciones digital, pack de armadura adicional, pack de arma adicional y un pack máscara aleatoria",
  "blocks.packs.components.pack.ultimate.value.description": "Clave del juego, insignia exclusiva, banda sonora, mapa, libro de ilustraciones digital, libro de canciones digital, pack de armadura adicional, dos packs de arma adicional y tres packs de máscara aleatoria",
  "blocks.subscriptions.values.card.title": "Hazte prémium y obtén beneficios",
  "blocks.subscriptions.values.title": "Activar suscripción",
  "blocks.subscriptions.values.heading": "Suscripciones",
  "blocks.subscriptions.values.bullets.one.title": "Contenido digital",
  "blocks.subscriptions.values.bullets.one.description": "Imágenes, fondos de pantalla, contenido exclusivo, bandas sonoras de juegos",
  "blocks.subscriptions.values.bullets.two.title": "Ventajas en los juegos",
  "blocks.subscriptions.values.bullets.two.description": "Máscaras de personajes, efectos visuales, tarjetas narrativas",
  "blocks.subscriptions.values.bullets.three.title": "3 cajas de recompensa en los juegos",
  "blocks.subscriptions.values.bullets.three.description": "5 pases extra a la Sala del caos, 10 renacimientos de personaje",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Próxima renovación",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Renovar ahora",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Gestionar plan",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Selecciona tu plan:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configurar suscripción",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} día(s) gratis",
  "editor.subscriptions-packs.button.then.price.per.day": "Después {amount} por día",
  "editor.subscriptions-packs.button.then.price.per.week": "Después {amount} por semana",
  "editor.subscriptions-packs.button.then.price.per.month": "Después {amount} por mes",
  "editor.subscriptions-packs.button.then.price.per.year": "Después {amount} por año",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Después {amount} por {periodValue} días",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Después {amount} por {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Después {amount} por {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Después {amount} por {periodValue} años",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Después {amount} de por vida",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "como primer pago, después {amount} por día",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "como primer pago, después {amount} por semana",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "como primer pago, después {amount} por mes",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "como primer pago, después {amount} por año",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "como primer pago, después {amount} de por vida",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "como primer pago, después {amount} por {periodValue} días",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "como primer pago, después {amount} por {periodValue} semanas",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "como primer pago, después {amount} por {periodValue} meses",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "como primer pago, después {amount} por {periodValue} años",
  "editor.subscriptions-packs.button.price.per.day": "{amount} por día",
  "editor.subscriptions-packs.button.price.per.week": "{amount} por semana",
  "editor.subscriptions-packs.button.price.per.month": "{amount} por mes",
  "editor.subscriptions-packs.button.price.per.year": "{amount} por año",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} por {periodValue} días",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} por {periodValue} semanas",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} por {periodValue} meses",
  "editor.subscriptions-packs.button.price.per.year.multiple": "Después {amount} por {periodValue} años",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} de por vida",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por día durante {periodsCounts} periodo(s), y luego {amount} por día",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana durante {periodsCounts} periodo(s), y luego {amount} por semana",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mes durante {periodsCounts} periodo(s), y luego {amount} por mes",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por año durante {periodsCounts} periodo(s), y luego {amount} por año",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} días durante {periodsCounts} periodo(s), después {amount} por {periodValue} días",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} semanas durante {periodsCounts} periodo(s), después {amount} por {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} meses durante {periodsCounts} periodo(s), después {amount} por {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} años durante {periodsCounts} periodo(s), después {amount} por{periodValue} años",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Después {amount} como primer pago, y luego {amountPromo} por día",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Después {amount} como primer pago, y luego {amountPromo} por semana",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Después {amount} como primer pago, y luego {amountPromo} por mes",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Después {amount} como primer pago, y luego {amountPromo} por año",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Después {amount} como primer pago, y luego {amountPromo} por {periodValue} días",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Después {amount} como primer pago, y luego {amountPromo} por {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Después {amount} como primer pago, y luego {amountPromo} por {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Después {amount} como primer pago, y luego {amountPromo} por {periodValue} años",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Después {amount} como primer pago, y luego {amountPromo} de por vida",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "durante {periodsCounts} periodo(s), después {amount} por día",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "durante {periodsCounts} periodo(s), después {amount} por semana",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "durante {periodsCounts} periodo(s), después {amount} por mes",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "durante {periodsCounts} periodo(s), después {amount} por año",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "durante {periodsCounts} periodo(s), después {amount} por {periodValue} días",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "durante {periodsCounts} periodo(s), después {amount} por {periodValue} semanas",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "durante {periodsCounts} periodo(s), después {amount} por {periodValue} meses",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "durante {periodsCounts} periodo(s), después {amount} por {periodValue} años",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "durante {periodsCounts} periodo(s), después {amount} de por vida",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, la temporada termina el {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, la temporada empieza el {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Después {amount}, la temporada termina el {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Después {amount}, la temporada empieza el {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por día",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mes",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por año",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} días",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} años",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} de por vida",
  "editor.subscriptions-packs.button.then.price.promo.price": "Después <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por día",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mes",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por año",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} días",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} semanas",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} meses",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por {periodValue} años",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} de por vida",
  "editor.subscriptions-packs.button.then.price": "Después {amount}",
  "editor.subscriptions-packs.button.season.ends": "La temporada termina el {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "La temporada empieza el {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / día",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / semana",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / mes",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / año",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} días",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} semanas",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} meses",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} años",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / de por vida",
  "blocks.requirements.values.title": "<h2>Requisitos de sistema</h2>",
  "blocks.requirements.components.platform_req.value.title": "Plataforma",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Gráficos",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "SO",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Capacidad de almacenamiento",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Procesador",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Tarjeta de sonido",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Memoria",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "Mac OS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Mínimo",
  "default-data.values.requirements.title.recommended": "Recomendado",
  "default-data.values.requirements.item.directx.minimum": "Versión 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2,5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB de RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Compatible con DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Compatible con DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Versión 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB de RAM",
  "default-data.customization.receipt.title": "¡Hola!",
  "default-data.customization.receipt.description": "¡Gracias por tu compra! Disfruta de nuestro juego.",
  "default-data.customization.receipt.footer": "© Todos los derechos reservados.",
  "blocks.store.components.storeSection.title": "Artículos",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "¡Te has suscrito con éxito!",
  "notification.hero.components.subscribe.subscription.success": "Acabamos de enviarte un correo electrónico de confirmación. Pulsa en el enlace del correo para activar tu suscripción.",
  "notification.hero.components.subscribe.error": "Se ha producido un error. Inténtalo de nuevo más tarde o contacta con nuestro equipo de integración: <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Ya estás suscrito.",
  /* notifications values END */
  "period.years_short": "años",
  "period.months_short": "meses",
  "period.weeks_short": "semanas",
  "period.days_short": "días",
  "subscription.button.text": "Suscríbete por </br>{fullCurrency} para {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Tu suscripción",
  "client.subscription.button.configuration": "Gestionar suscripción",
  "client.subscription.button.lifetime_text": "Suscríbete de por vida</br> por {fullCurrency}",
  "client.thank_you_modal.title": "¡Gracias por suscribirte!",
  "client.thank_you_modal.description": "Tu suscripción se renueva automáticamente. El pago se cobrará periódicamente según las condiciones de tu suscripción. Podrás cancelarlo en cualquier momento.",
  "client.thank_you_modal.input.description": "Si deseas aparecer en nuestra lista de suscriptores, ingresa tu nombre a continuación.",
  "client.thank_you_modal.button.default.text": "Descartar, gracias",
  "client.thank_you_modal.input.placeholder": "Tu nombre",
  "client.thank_you_modal.button.show_list": "Mostrar todos los suscriptores",
  "client.subscribers_list_modal.title": "Lista completa de suscriptores",
  "client.subscribers_list_modal.description": "¡Muchas gracias a todos los usuarios que se unieron y se suscribieron al juego!",
  "client.subscribers_list_modal.show_more_button": "Mostrar más",
  "client.subscribers_list_modal.anonymous": "y usuarios anónimos",
  "client.footer.button.list_of_subscribers": "Lista de suscriptores",
  "client.footer.xsollalogo.powered": "Desarrollado por el", // копирайт в футере
  "client.footer.xsollalogo.sb": "Creador de sitios web de Xsolla",
  "client.empty_subscribers_modal.title": "Los suscriptores aparecerán en esta lista",
  "client.empty_subscribers_modal.description": "Sé el primero en suscribirte a nuestro juego.",
  "client.empty_subscribers_modal.button": "Selecciona la suscripcion ya",
  "client.news.blurred.description": "Conviértete en un suscriptor {subscription_name} para desbloquear este contenido.",
  "client.news.blurred.button.join": "Únete ahora",
  "client.cart_modal.title": "Carro",
  "client.cart_modal.item.pre-order": "(precompra)",
  "client.cart_modal.clear_all": "Borrar todo",
  "client.cart_modal.item_purchased_description": "Ya has comprado este artículo",
  "client.cart_modal.subtotal_title": "Total",
  "client.cart_modal.subtotal_subtitle": "Pueden aplicarse impuestos y tarifas adicionales",
  "client.cart_modal.continue_button": "Continuar comprando",
  "client.cart_modal.checkout_button": "Ir a la pasarela de pago",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Inicia sesión</button> o <button class=\'xl_auth\' data-xl-route=\'signup\'>regístrate</button> para guardar los artículos en la cesta para más tarde o ver los artículos guardados",
  "client.cart_modal.empty_cart_description": "Tu carro está vacío. Visita la tienda y encuentra algo estupendo que comprar.",
  "client.cart_modal.empty_cart_open_store_button": "Abrir tienda",
  "client.cart_modal.email_label": "Enviar clave de activación del juego a:",
  "client.cart_modal.email_description": "Recibirás una clave que tendrás que activar para empezar a jugar.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonificación",
  "client.cart_modal.promo_codes.apply": "Aplicar",
  "client.cart_modal.promo_codes.remove": "Borrar",
  "client.cart_modal.promo_codes.placeholder": "Código promocional",
  "client.cart_modal.promo_codes.error_incorrect": "Código promocional incorrecto.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Se ha alcanzado el límite para canjear el código.",
  "client.cart_modal.promo_codes.error_expired": "Este código promocional ha caducado.",
  "client.cart_modal.promo_codes.error_could_not_apply": "No se ha podido aplicar el código promocional. Vuelve a intentarlo más tarde.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "El código promocional no es válido para los artículos en tu cesta.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Selecciona una plataforma de juego para continuar.",
  "client.cart_modal.promo_codes.select_drm": "Selecciona la plataforma",
  "client.cart_modal.promo_codes.tooltip_drm": "Para cambiar la plataforma, borra el código promocional y vuelve a aplicarlo",
  "client.bundle_modal.group_items": "Artículos en el paquete",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronce</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Contenido digital</h3><p>✓ Diseños</p><p>✓ Fondos de pantalla</p><p><span style=\'color:rgb(127, 96, 0);\'>× Bandas sonoras en el juego </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Contenido detrás del escenario</span></p><p><br></p><h3>3 Cajas de recompensa</h3><h3>en el juego</h3><p>✓ Máscara de personaje</p><p><span style=\'color:rgb(127, 96, 0);\'>× Efectos visuales </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Tarjetas de argumento</span></p><p><br></p><h3>Ventajas en el juego</h3><p>✓ x1 Pase extra a la Sala del Caos</p><p>✓ x1 renacimiento de personaje</p><p><br></p><h3>Discord</h3><p>✓ Acceso al canal de los jugadores</p><p><span style=\'color:rgb(127, 96, 0);\'>× Acceso al canal de los desarrolladores</span></p><p><br></p><h3>Foro</h3><p>✓ Color exclusivo de nombre de usuario</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji exclusivo</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Imagen de perfil animada </span></p><p>✓ x2 puntos extra por clasificarse en el puesto (diariamente)</p><p><br></p><h3>Productos promocionales</h3><p>✓ Insignias y pegatinas (cada 6 meses)</p><p>✓ HPóster de alta calidad (cada 6 meses)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Peluches con la marca  (cada año)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Figuritas de alta calidad (cada año)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Eventos sin conexión</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Acceso gratuito a nuestras convenciones de videojuegos</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Visita a las oficinas de la empresa (cada año)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ da accesso a Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Plata</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Contenido digital</h3><p>✓ Diseños</p><p>✓ Fondos de pantalla</p><p><span style=\"color:rgb(127, 96, 0);\">× Bandas sonoras en el juego</span></p><p>✓ Contenido detrás del escenario</p><p><br></p><h3>6 Cajas de recompensa</h3><h3>en el juego</h3><p>✓ Máscara de personaje</p><p>✓ Efectos visuales</p><p><span style=\"color:rgb(127, 96, 0);\">× Tarjetas de argumento</span></p><p><br></p><h3>Ventajas en el juego</h3><p>✓ x2 Pase extra a la Sala del Caos</p><p>✓ x2 renacimiento de personaje</p><h3><br></h3><h3>Discord</h3><p>✓ Acceso al canal de los jugadores</p><p><span style=\"color:rgb(127, 96, 0);\">× Acceso al canal de los desarrolladores</span></p><p><br></p><h3>Foro</h3><p>✓ Color exclusivo de nombre de usuario</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji exclusivo</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Imagen de perfil animada</span></p><p>✓ 5x puntos extra por clasificarse en el puesto (diariamente)</p><p><br></p><h3>Productos promocionales</h3><p>✓ Insignias y pegatinas (cada 6 meses)</p><p>✓ HPóster de alta calidad (cada 6 meses)</p><p><span style=\"color:rgb(127, 96, 0);\">× Peluches con la marca (cada año)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Figuritas de alta calidad (cada año)</span></p><p><br></p><h3>Eventos sin conexión</h3><p>✓ Acceso gratuito a nuestras convenciones de videojuegos</p><p><span style=\"color:rgb(127, 96, 0);\">× Visita a las oficinas de la empresa (cada año)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ da accesso a Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Oro</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Contenido digital</h3><p>✓ Diseños</p><p>✓ Fondos de pantalla</p><p>✓ Bandas sonoras en el juego</p><p>✓ Contenido detrás del escenario</p><p><br></p><h3>9 Cajas de recompensa</h3><h3>en el juego</h3><p>✓ Máscara de personaje</p><p>✓ Efectos visuales</p><p>✓ Tarjetas de argumento</p><p><br></p><h3>Ventajas en el juego</h3><p>✓ x5 Pase extra a la Sala del Caos</p><p>✓ x10 renacimiento de personaje</p><p><br></p><h3>Discord</h3><p>✓ Acceso al canal de los jugadores</p><p>✓ Acceso al canal de los desarrolladores</p><p><br></p><h3>Foro</h3><p>✓ Color exclusivo de nombre de usuario</p><p>✓ Emoji exclusivo</p><p>✓ Imagen de perfil animada</p><p>✓ x12 puntos extra por clasificarse en el puesto (diariamente)</p><p><br></p><h3>Productos promocionales</h3><p>✓ Insignias y pegatinas (cada 6 meses)</p><p>✓ HPóster de alta calidad (cada 6 meses)</p><p>✓ Peluches con la marca  (cada año)</p><p>✓ Figuritas de alta calidad (cada año)</p><p><br></p><h3>Eventos sin conexión</h3><p>✓ Acceso gratuito a nuestras convenciones de videojuegos</p><p>✓ Visita a las oficinas de la empresa (cada año)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ da accesso a Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Contenido digital</h3><p>Diseños</p><p>Fondos de pantalla</p><p><span>Bandas sonoras en el juego </span></p><p><span>Contenido detrás del escenario</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Cajas de recompensa</h3><h3>en el juego</h3><p>Máscara de personaje</p><p><span>Efectos visuales </span></p><p><span>Tarjetas de argumento</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Ventajas en el juego</h3><p>x1 Pase extra a la Sala del Caos</p><p>x1 renacimiento de personaje</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Acceso al canal de los jugadores</p><p><span>× Acceso al canal de los desarrolladores</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Foro</h3><p>Color exclusivo de nombre de usuario</p><p><span>× Emoji exclusivo</span></p><p><span>× Imagen de perfil animada </span></p><p>x2 puntos extra por clasificarse en el puesto (diariamente)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Productos promocionales</h3><p>Insignias y pegatinas (cada 6 meses)</p><p>Póster de alta calidad (cada 6 meses)</p><p><span>Peluches con la marca  (cada año)</span></p><p><span>Figuritas de alta calidad (cada año)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Eventos sin conexión</h3><p>✓ Acceso gratuito a nuestras convenciones de videojuegos</p><p>✓ Visita a las oficinas de la empresa (cada año)</p>",
  "client.buy_button.text": "Cómpralo ya",
  "client.buy_button.unavailable": "No disponible",
  "client.buy_button.pre-order": "Precompra",
  "client.buy_button.checkout": "Ir a la pasarela de pago",
  "client.store.cart.add_button": "Añadir a la cesta",
  "client.store.cart.header_button": "Carro",
  "client.store.no_items": "No hay artículos en la tienda de momento. ¡Vuelve más tarde!",
  "client.store.error.gt10_gk": "Para protegerte de fraudes, solo puedes comprar 10 claves cada vez.",
  "client.store.error.no_keys": "El juego está agotado temporalmente, pero pronto estará  disponible. Contacta con nosotros para saber cuándo.",
  "client.store.item.purchased": "Comprado",
  "client.store.item.price_from": "desde {price}",
  "client.store.item.show_more": "Mostrar detalles",
  "client.store.successful_purchase": "Compra realizada, ¡gracias!",
  "client.store.not_enough_vc": "No tienes suficientes monedas virtuales.</br>Por favor, recarga para finalizar la compra.",
  "client.store.non_consumable_item": "Ya has comprado este artículo antes y ya no está disponible.",
  "client.store.user_id_input_before_submit_hint": "El precio de artículos se cargarán a esta cuenta.",
  "client.store.user_id_input_after_submit_hint": "Estás comprando estos artículos para el ID (identificador): ",
  "client.common_error": "Se ha producido un error. Inténtalo de nuevo más tarde o contacta con nuestro equipo de integración: <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Tu saldo",
  "client.header.logout": "Cerrar sesión",
  "client.retailers.edition.title": "Selecciona tu copia",
  "client.retailers.country.title": "Selecciona tu país",
  "client.retailers.platform.title": "Selecciona la plataforma",
  "client.retailers.retailer.title": "Selecciona el vendedor",
  "client.retailers.edition.digital": "Digital",
  "client.retailers.edition.physical": "Física",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Comprar al desarrollador",
  "client.retailers.expand_button.collapse": "Contraer",
  "client.retailers.buy_button.buy": "Cómpralo ya",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Más popular",
  "blocks.retailers.components.retailers.standart.value.subtext": "¡Ya disponible!",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Edición Estándar</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Clave del juego, insignia exclusiva, banda sonora, mapa y pack de armadura adicional",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TÍTULO DE TU JUEGO</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Tu Juego embarca a los jugadores en una gran aventura repleta de detractores, monstruos, buscadores de oro y graves amenazas.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Disponible para",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Escribe tu correo electrónico",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Suscríbete a nuestro boletín para recibir las últimas noticias y actualizaciones",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Configura el botón",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Cómpralo ya",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Configura el botón",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Configura el botón",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Configura el botón",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "No disponible",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Configura el botón",
  "client.topup.input.errorMessage": "Se ha producido un error. Asegúrate de que tu ID de usuario es correcto o inténtalo de nuevo más tarde.",
  "client.topup.input.successMessage": "Has iniciado la sesión con el ID {userID}. Ahora puedes comprar en la página web.",
  "client.topup.input.username": "Usuario: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Nivel: {level}",
  "client.topup.input.user_not_found": "No hemos encontrado ningún usuario con el ID especificado.<br/>Inténtalo de nuevo.",
  "client.topup.input.serverError": "Se ha producido un error.<br>Si recibes este mensaje más de una vez, {contact_us}.",
  "client.topup.input.serverError.contact_us": "contáctanos",
  "client.topup.authorized_title": "Estás comprando estos artículos para el ID (identificador): ",
  "blocks.store.values.custom_title": "<h2>{gameTitle} tienda</h2>"
};

module.exports = localizations;
