import Color from 'color';

import type {
  ThemeType,
  PageThemeType,
  Authorization,
} from '#common/model/landing';

import { setAlpha, getColorTone } from '../colors';
import { Socials, Theme } from './types';

export const getLoginWidgetTheme = (
  landingTheme: ThemeType,
  pageTheme: PageThemeType,
  loginSettings: Authorization
) => {
  let theme: Theme = {
    header: {
      image: {
        url: loginSettings.logo.enable ? loginSettings.logo.img : '',
        size: loginSettings.logo.size,
      },
    },
    scene: {
      image: {
        url: loginSettings.background.enable
          ? loginSettings.background.img
          : '',
        size: loginSettings.background.size,
      },
      color: loginSettings.background.enable
        ? loginSettings.background.color
        : 'rgba(0, 0, 0, 0.6)',
    },
  };

  if (loginSettings.isSetSiteStyle) {
    theme = {
      ...theme,
      background: {
        color: Color(
          getColorTone(setAlpha(pageTheme.pictureBackground.color, 1), 800)
        ).string(),
      },
      rounding: {
        widget: `${landingTheme.buttonBorderRadius}px`,
        inputs: `${landingTheme.buttonBorderRadius}px`,
        buttons: `${landingTheme.buttonBorderRadius}px`,
      },
      primary_color: landingTheme.accentColor,
      secondary_color: Color(landingTheme.textColor).alpha(0.6).string(),
      text_color: landingTheme.textColor,
      error_color: '#B36666',
    };
  }
  return theme;
};

const colors = {
  amazon: '#FF9900',
  apple: '#000000',
  baidu: '#006CB7',
  battlenet: '#00AEFF',
  china_telecom: '#02489D',
  discord: '#7289DA',
  email: '#ec4c64',
  epicgames: '#2a2a2a',
  facebook: '#4065B4',
  github: '#333333',
  google: '#5285ED',
  'google-plus': '#DB4437',
  instagram: '#AD3A89',
  kakao: '#E7C80A',
  linkedin: '#3276B0',
  mailru: '#2660F0',
  microsoft: '#00A4EF',
  msn: '#0077C8',
  naver: '#5FB346',
  ok: '#F78408',
  paradox: '#1E1814',
  paypal: '#2F6FB5',
  pinterest: '#BB352F',
  qq: '#57BBF6',
  reddit: '#EB5626',
  steam: '#1A1919',
  slack: '#3ab07d',
  twitch: '#5F449F',
  twitter: '#1b95e0',
  vimeo: '#00A3DA',
  vk: '#536B8E',
  wechat: '#6EBD4B',
  weibo: '#F19E4A',
  xbox: '#197B1A',
  yahoo: '#6001D2',
  yandex: '#E93520',
  youtube: '#EA3620',
};

export const getColorBySocial = (social: Socials) => colors[`${social}`];
