var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Wyświetl więcej",
  "client.header.login.button": "Zaloguj się",
  "client.header.logout.button": "Wyloguj",
  "client.user-id-modal.input-placeholder": "ID użytkownika",
  "client.user-id-modal.input-incorrect-id": "Nieprawidłowe ID użytkownika.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Spróbuj skopiować i wkleić swój identyfikator z gry.",
  "client.user-id-modal.continue-button": "Kontynuuj",
  "client.user-id-modal.go-back": "Powrót",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Wyświetl więcej",
  "client.privacy-settings.open-button": "Ustawienia prywatności",
  "client.usa-privacy-settings.open-button": "Nie wyrażam zgody na sprzedaż moich danych",
  "client.user-id-modal.continue-with": "Kontynuuj przez {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Wprowadź swoje ID użytkownika",
  "document.pages.auth.subtitle": "lub wpisz identyfikator",
  "document.pages.auth.placeholder": "Przykład: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Nie możesz znaleźć ID użytkownika?",
  "document.pages.auth.instruction.steps.description": "Napisz jak użytkownik może znaleźć swój unikalny identyfikator. Możesz wstawić kilka kroków wraz z obrazami.",
  "document.pages.seo.title": "Nowa gra została już wydana!",
  "document.pages.seo.description": "Twoja gra zabierze graczy w niesamowitą przygodę pełną potworów, niebezpieczeństw, łowców nagród i innych atrakcji.",
  "document.pages.seo.ogTitle": "Nowa gra została już wydana!",
  "document.pages.seo.ogDescription": "Twoja gra zabierze graczy w niesamowitą przygodę pełną potworów, niebezpieczeństw, łowców nagród i innych atrakcji.",
  "default-data.templates.google-play.contact-us": "Kontakt: {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "DOŁADUJ PORTFEL W GRZE",
  "blocks.topup.values.description": "Wprowadź identyfikator użytkownika",
  "blocks.topup.values.userIdPlaceholder": "Przykład: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "NIE MOŻESZ ZNALEŹĆ ID UŻYTKOWNIKA?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Nazwa kroku",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Napisz jak użytkownik może znaleźć swój unikalny identyfikator. Możesz wstawić kilka kroków wraz z obrazami.",
  "default-data.templates.topup.app-support": "Obsługa aplikacji",
  "default-data.templates.topup.age-raiting": "Klasyfikacja wiekowa",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TYTUŁ TWOJEJ GRY</h1>",
  "blocks.retailers.values.title": "<h2>Wybierz swoje wydanie</h2>",
  "blocks.hero.values.description": "Twoja gra zabierze graczy w niesamowitą przygodę pełną potworów, niebezpieczeństw, łowców nagród i innych atrakcji.",
  "blocks.hero.values.affiliate": "Dochód z tego zakupu zostanie podzielony z uczestnikiem programu afiliacyjnego",
  "blocks.hero.components.platforms.label": "Dostępna na",
  "blocks.hero.components.subscribe.label": "Wpisz swój adres e-mail",
  "blocks.hero.components.subscribe.values.text" : "Zapisz się do naszego newslettera, aby otrzymywać najświeższe informacje i aktualności",
  "blocks.hero.components.scroll.label": "Suwak",
  "blocks.footer.values.name": "Wpisz swój tekst",
  "blocks.footer.values.affiliate": "Dołącz do programu afiliacyjnego",
  "blocks.hero.components.buy.label": "Kup teraz",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.hero.components.customButton.link.label": "Skonfiguruj przycisk",
  "blocks.header.components.customButton.link.label": "Skonfiguruj przycisk",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.packs.components.pack.customButton.link.label": "Skonfiguruj przycisk",
  "blocks.packs.components.pack.standart.components.buy.label": "Kup teraz",
  "blocks.packs.components.pack.premium.components.buy.label": "Kup teraz",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Kup teraz",
  "blocks.packs.components.pack.platinum.components.buy.label": "Kup teraz",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.packs.components.pack.standart.value.ribbon": "Najpopularniejsza",
  "blocks.packs.components.pack.premium.value.ribbon": "Najpopularniejsza",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Najpopularniejsza",
  "blocks.packs.components.pack.platinum.value.ribbon": "Najpopularniejsza",
  "blocks.description.values.title": "<h2>OPIS GRY</h2>",
  "blocks.description.components.text.label": "Twoja gra zabierze graczy w niesamowitą przygodę pełną potworów, niebezpieczeństw, łowców nagród i innych atrakcji. Dramatyczne wydarzenia odwróciły bieg twojego życia i musisz teraz walczyć o przetrwanie w nowym, nieprzyjaznym świecie. Odkrywaj, eksploruj i zdobywaj, przemierzając najróżniejsze wymiary rzeczywistości.",
  "blocks.faq.values.title": "<h2>Częste pytania</h2>",
  "blocks.faq.components.question.question": "Jak mogę zagrać w Your Game?",
  "blocks.faq.components.question.answer": "Aby zagrać w Your Game, musisz wcześniej ją zakupić i podać swój adres e-mail. Otrzymasz wówczas klucz aktywacyjny, który pozwoli ci uzyskać dostęp do gry.",
  "blocks.news.values.title": "<h2>Aktualności</h2>",
  "blocks.news.values.description": "Zajrzyj do naszych najnowszych artykułów, aby zapoznać się z aktualnościami.",
  "blocks.store.values.title": "<h2>Sklep</h2>",
  "blocks.store.buy_button.text": "KUP TERAZ ZA {amount}",
  "blocks.embed.values.title": "<h2>Widżety mediów społecznościowych</h2>",
  "blocks.embed.components.twitter.title": "<h3>KANAŁ TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>KANAŁ FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Wybierz edycję",
  "blocks.packs.values.description": "Każda edycja zawiera ekskluzywne nagrody i korzyści, które otrzymasz po zakupie.",
  "blocks.packs.components.pack.standart.value.subtext": "Dostępna teraz",
  "blocks.packs.components.pack.premium.value.subtext": "Dostępna teraz",
  "blocks.packs.components.pack.ultimate.value.subtext": "Dostępna teraz",
  "blocks.packs.components.pack.platinum.value.subtext": "Dostępna teraz",
  "blocks.packs.components.pack.standart.value.title": "<h3>Edycja standardowa</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Edycja premium</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Edycja platynowa</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Edycja ultimate</h3>",
  "blocks.packs.components.pack.standart.value.description": "Klucz gry, ekskluzywna odznaka, ścieżka dźwiękowa, mapa i zestaw z dodatkową zbroją",
  "blocks.packs.components.pack.premium.value.description": "Klucz gry, ekskluzywna odznaka, ścieżka dźwiękowa, mapa, zestaw z dodatkową zbroją i bronią",
  "blocks.packs.components.pack.platinum.value.description": "Klucz gry, ekskluzywna odznaka, ścieżka dźwiękowa, mapa, cyfrowe kompendium grafik, zestaw z dodatkową zbroją i bronią, zestaw z jedną losową skórką",
  "blocks.packs.components.pack.ultimate.value.description": "Klucz gry, ekskluzywna odznaka, ścieżka dźwiękowa, mapa, cyfrowe kompendium grafik i utworów muzycznych, zestaw z dodatkową zbroją i bronią, dwa dodatkowe zestawy broni i trzy zestawy z losowymi skórkami",
  "blocks.subscriptions.values.card.title": "Wybierz premium i zdobądź korzyści",
  "blocks.subscriptions.values.title": "Aktywuj subskrypcję",
  "blocks.subscriptions.values.heading": "Subskrypcje",
  "blocks.subscriptions.values.bullets.one.title": "Treści cyfrowe",
  "blocks.subscriptions.values.bullets.one.description": "Obrazy, tła pulpitu, materiały zakulisowe, ścieżki dźwiękowe z gry",
  "blocks.subscriptions.values.bullets.two.title": "Korzyści w grze",
  "blocks.subscriptions.values.bullets.two.description": "Skórki postaci, efekty wizualne, karty fabularne",
  "blocks.subscriptions.values.bullets.three.title": "3 skrzynki z nagrodami w grze",
  "blocks.subscriptions.values.bullets.three.description": "5 dodatkowych przepustek do Hali Chaosu, 10 odrodzeń postaci",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Następne odnowienie",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Odnów teraz",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Zarządzaj planem",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Wybierz swój plan:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Skonfiguruj subskrypcję",
  "editor.subscriptions-packs.button.trial.days.for.free": "Dni za darmo: {trial}",
  "editor.subscriptions-packs.button.then.price.per.day": "Później {amount} za dzień",
  "editor.subscriptions-packs.button.then.price.per.week": "Później {amount} za tydzień",
  "editor.subscriptions-packs.button.then.price.per.month": "Później {amount} za miesiąc",
  "editor.subscriptions-packs.button.then.price.per.year": "Później {amount} za rok",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Później {amount} za {periodValue} dni",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Później {amount} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Później {amount} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Później {amount} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Później {amount} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "w ramach pierwszej płatności, później {amount} za dzień",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "w ramach pierwszej płatności, później {amount} za tydzień",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "w ramach pierwszej płatności, później {amount} za miesiąc",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "w ramach pierwszej płatności, później {amount} za rok",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "w ramach pierwszej płatności, później {amount} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "w ramach pierwszej płatności, później {amount} za {periodValue} dni",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "w ramach pierwszej płatności, później {amount} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "w ramach pierwszej płatności, później {amount} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "w ramach pierwszej płatności, później {amount} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.price.per.day": "{amount} za dzień",
  "editor.subscriptions-packs.button.price.per.week": "{amount} za tydzień",
  "editor.subscriptions-packs.button.price.per.month": "{amount} za miesiąc",
  "editor.subscriptions-packs.button.price.per.year": "{amount} za rok",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} za {periodValue} dni",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za dzień przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za dzień",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za tydzień przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za tydzień",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za miesiąc przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za miesiąc",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za rok",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dni przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} dni",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} za {periodValue} tygodni(-e) przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} miesiące(-ęcy) przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} lat(-a) przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za{periodValue} lat(-a)",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za dzień",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za tydzień",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za miesiąc",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za rok",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za {periodValue} dni",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Później {amount} w ramach pierwszej płatności, a następnie {amountPromo} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za dzień",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za tydzień",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za miesiąc",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za rok",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} dni",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "przez {periodsCounts} okres(-y) rozliczeniowy(-e), a następnie {amount} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}; sezon dobiega końca {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}; sezon rozpoczyna się {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Później {amount}; sezon dobiega końca {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Później {amount}; sezon rozpoczyna się {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za dzień",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za tydzień",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za miesiąc",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dni",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.then.price.promo.price": "Później <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za dzień",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za tydzień",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za miesiąc",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dni",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za dożywotnią subskrypcję",
  "editor.subscriptions-packs.button.then.price": "Później {amount}",
  "editor.subscriptions-packs.button.season.ends": "Sezon dobiega końca {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Sezon rozpoczyna się {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / dzień",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / tydzień",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / miesiąc",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / rok",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} dni",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} tygodni(-e)",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} miesiące(-ęcy)",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} lat(-a)",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / dożywotnio",
  "blocks.requirements.values.title": "<h2>Wymagania systemowe</h2>",
  "blocks.requirements.components.platform_req.value.title": "Platforma",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Karta graficzna",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "System operacyjny",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Miejsce na dysku",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Procesor",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Karta dźwiękowa",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Pamięć",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "Mac OS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimalne",
  "default-data.values.requirements.title.recommended": "Zalecane",
  "default-data.values.requirements.item.directx.minimum": "Wersja 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2,5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Zgodna z DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Zgodna z DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Wersja 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Witaj, przyjacielu!",
  "default-data.customization.receipt.description": "Dziękujemy za dokonanie zakupu! Doceniamy, że grasz w naszą grę.",
  "default-data.customization.receipt.footer": "© Wszelkie prawa zastrzeżone.",
  "blocks.store.components.storeSection.title": "Elementy",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Pomyślnie zapisałeś się do naszego newslettera!",
  "notification.hero.components.subscribe.subscription.success": "Wysłaliśmy do Ciebie wiadomość e-mail z potwierdzeniem. Kliknij link w wiadomości, aby aktywować swoją subskrypcję.",
  "notification.hero.components.subscribe.error": "Coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z naszym zespołem ds. integracji pod adresem <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Zapisałeś się już do naszego newslettera.",
  /* notifications values END */
  "period.years_short": "lat",
  "period.months_short": "m.",
  "period.weeks_short": "tyg.",
  "period.days_short": "d.",
  "subscription.button.text": "Zapisz się do</br>{fullCurrency} na {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Twoja subskrypcja",
  "client.subscription.button.configuration": "Zarządzaj subskrypcją",
  "client.subscription.button.lifetime_text": "Zasubskrybuj na zawsze</br> za{fullCurrency}",
  "client.thank_you_modal.title": "Dziękujemy za dokonanie subskrypcji!",
  "client.thank_you_modal.description": "Twoja subskrypcja jest odnawiana automatycznie. Opłaty będą naliczane okresowo w zależności od warunków subskrypcji. Możesz anulować subskrypcję w dowolnym momencie.",
  "client.thank_you_modal.input.description": "Jeśli chcesz figurować na liście naszych subskrybentów, wpisz swój pseudonim poniżej.",
  "client.thank_you_modal.button.default.text": "Dzięki, pomiń",
  "client.thank_you_modal.input.placeholder": "Twój pseudonim",
  "client.thank_you_modal.button.show_list": "Pokaż wszystkich subskrybentów",
  "client.subscribers_list_modal.title": "Pełna lista subskrybentów",
  "client.subscribers_list_modal.description": "Serdecznie dziękujemy wszystkim użytkownikom, którzy dołączyli do grona subskrybentów gry!",
  "client.subscribers_list_modal.show_more_button": "Wyświetl więcej",
  "client.subscribers_list_modal.anonymous": "i anonimowi użytkownicy",
  "client.footer.button.list_of_subscribers": "Lista subskrybentów",
  "client.footer.xsollalogo.powered": "Obsługiwane przez", // копирайт в футере
  "client.footer.xsollalogo.sb": "Kreator stron Xsolla",
  "client.empty_subscribers_modal.title": "Subskrybenci będą widoczni tutaj",
  "client.empty_subscribers_modal.description": "Bądź pierwszym subskrybentem naszej gry.",
  "client.empty_subscribers_modal.button": "Wybierz swoją subskrypcję teraz",
  "client.news.blurred.description": "Zostań subskrybentem {subscription_name}, aby odblokować tę zawartość.",
  "client.news.blurred.button.join": "Dołącz teraz",
  "client.cart_modal.title": "Koszyk",
  "client.cart_modal.item.pre-order": "(przedsprzedaż)",
  "client.cart_modal.clear_all": "wyczyść wszystko",
  "client.cart_modal.item_purchased_description": "Zakupiłeś już ten przedmiot",
  "client.cart_modal.subtotal_title": "Razem",
  "client.cart_modal.subtotal_subtitle": "Do ceny mogą zostać doliczone dodatkowe opłaty i podatki",
  "client.cart_modal.continue_button": "kontynuuj zakupy",
  "client.cart_modal.checkout_button": "przejdź do realizacji",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Zaloguj się</button> lub <button class=\'xl_auth\' data-xl-route=\'signup\'>zarejestruj</button>, aby zapisać produkty w koszyku na później lub wyświetlić poprzednio zapisane produkty",
  "client.cart_modal.empty_cart_description": "Twój koszyk jest pusty. Przejdź do sklepu i znajdź coś wystrzałowego do kupienia.",
  "client.cart_modal.empty_cart_open_store_button": "otwórz sklep",
  "client.cart_modal.email_label": "Wyślij klucz aktywacyjny gry na adres:",
  "client.cart_modal.email_description": "Otrzymasz klucz, który należy aktywować, aby móc rozpocząć grę.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Zastosuj",
  "client.cart_modal.promo_codes.remove": "Usuń",
  "client.cart_modal.promo_codes.placeholder": "Kod promocyjny",
  "client.cart_modal.promo_codes.error_incorrect": "Nieprawidłowy kod promocyjny.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Wyczerpano limit realizacji kodu.",
  "client.cart_modal.promo_codes.error_expired": "Ten kod promocyjny wygasł.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Nie można użyć kodu promocyjnego. Ponów próbę później.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Kod promocyjny nie obejmuje produktów w Twoim koszyku.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Wybierz platformę gry, aby kontynuować.",
  "client.cart_modal.promo_codes.select_drm": "Wybierz platformę",
  "client.cart_modal.promo_codes.tooltip_drm": "Aby zmienić platformę, usuń kod promocyjny i zastosuj go ponownie",
  "client.bundle_modal.group_items": "Produkty w zestawie",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Brązowa</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Treści cyfrowe</h3><p>✓ Materiały graficzne</p><p>✓ Tapety</p><p><span style=\'color:rgb(127, 96, 0);\'>× Ścieżki dźwiękowe gry </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Materiały z procesu tworzenia gry</span></p><p><br></p><h3>3 Skrzynie</h3><h3>z przedmiotami w grze</h3><p>✓ Skórki dla postaci</p><p><span style=\'color:rgb(127, 96, 0);\'>× Efekty wizualne </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Karty kolekcjonerskie</span></p><p><br></p><h3>Dodatki w grze</h3><p>✓ x1 dodatkowa przepustka do Sali Chaosu</p><p>✓ x1 odrodzenie postaci</p><p><br></p><h3>Discord</h3><p>✓ Dostęp do kanału graczy</p><p><span style=\'color:rgb(127, 96, 0);\'>× Dostęp do kanału deweloperskiego</span></p><p><br></p><h3>Forum</h3><p>✓ Ekskluzywny kolor pseudonimu</p><p><span style=\'color:rgb(127, 96, 0);\'>× Ekskluzywne emotikony</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Animowany obrazek profilowy </span></p><p>✓ x2 dodatkowe punkty do wyróżnienia wpisu (codziennie)</p><p><br></p><h3>Gadżety</h3><p>✓ Przypinki i naklejki (co 6 miesięcy)</p><p>✓ Wysokiej jakości plakat (co 6 miesięcy)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Markowe zabawki pluszowe (co roku)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Wysokiej jakości figurki (co roku)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Wydarzenia offline</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Darmowy wstęp na nasze konwencje dla graczy</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Wycieczka po siedzibie firmy (co roku)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ dostęp do serwera Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Srebrna</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Treści cyfrowe</h3><p>✓ Materiały graficzne</p><p>✓ Tapety</p><p><span style=\"color:rgb(127, 96, 0);\">× Ścieżki dźwiękowe gry</span></p><p>✓ Materiały z procesu tworzenia gry</p><p><br></p><h3>6 Skrzynie</h3><h3>z przedmiotami w grze</h3><p>✓ Skórki dla postaci</p><p>✓ Efekty wizualne</p><p><span style=\"color:rgb(127, 96, 0);\">× Karty kolekcjonerskie</span></p><p><br></p><h3>Dodatki w grze</h3><p>✓ x2 dodatkowa przepustka do Sali Chaosu</p><p>✓ x2 odrodzenie postaci</p><h3><br></h3><h3>Discord</h3><p>✓ Dostęp do kanału graczy</p><p><span style=\"color:rgb(127, 96, 0);\">× Dostęp do kanału deweloperskiego</span></p><p><br></p><h3>Forum</h3><p>✓ Ekskluzywny kolor pseudonimu</p><p><span style=\"color:rgb(127, 96, 0);\">× Ekskluzywne emotikony</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Animowany obrazek profilowy</span></p><p>✓ 5x dodatkowe punkty do wyróżnienia wpisu (codziennie)</p><p><br></p><h3>Gadżety</h3><p>✓ Przypinki i naklejki (co 6 miesięcy)</p><p>✓ Wysokiej jakości plakat (co 6 miesięcy)</p><p><span style=\"color:rgb(127, 96, 0);\">× Markowe zabawki pluszowe (co roku)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Wysokiej jakości figurki (co roku)</span></p><p><br></p><h3>Wydarzenia offline</h3><p>✓ Darmowy wstęp na nasze konwencje dla graczy</p><p><span style=\"color:rgb(127, 96, 0);\">× Wycieczka po siedzibie firmy (co roku)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ dostęp do serwera Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Złota</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Treści cyfrowe</h3><p>✓ Materiały graficzne</p><p>✓ Tapety</p><p>✓ Ścieżki dźwiękowe gry</p><p>✓ Materiały z procesu tworzenia gry</p><p><br></p><h3>9 Skrzynie</h3><h3>z przedmiotami w grze</h3><p>✓ Skórki dla postaci</p><p>✓ Efekty wizualne</p><p>✓ Karty kolekcjonerskie</p><p><br></p><h3>Dodatki w grze</h3><p>✓ x5 dodatkowa przepustka do Sali Chaosu</p><p>✓ x10 odrodzenie postaci</p><p><br></p><h3>Discord</h3><p>✓ Dostęp do kanału graczy</p><p>✓ Dostęp do kanału deweloperskiego</p><p><br></p><h3>Forum</h3><p>✓ Ekskluzywny kolor pseudonimu</p><p>✓ Ekskluzywne emotikony</p><p>✓ Animowany obrazek profilowy</p><p>✓ x12 dodatkowe punkty do wyróżnienia wpisu (codziennie)</p><p><br></p><h3>Gadżety</h3><p>✓ Przypinki i naklejki (co 6 miesięcy)</p><p>✓ Wysokiej jakości plakat (co 6 miesięcy)</p><p>✓ Markowe zabawki pluszowe (co roku)</p><p>✓ Wysokiej jakości figurki (co roku)</p><p><br></p><h3>Wydarzenia offline</h3><p>✓ Darmowy wstęp na nasze konwencje dla graczy</p><p>✓ Wycieczka po siedzibie firmy (co roku)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ dostęp do serwera Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Treści cyfrowe</h3><p>Materiały graficzne</p><p>Tapety</p><p><span>Ścieżki dźwiękowe gry </span></p><p><span>Materiały z procesu tworzenia gry</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Skrzynie</h3><h3>z przedmiotami w grze</h3><p>Skórki dla postaci</p><p><span>Efekty wizualne </span></p><p><span>Karty kolekcjonerskie</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Dodatki w grze</h3><p>x1 dodatkowa przepustka do Sali Chaosu</p><p>x1 odrodzenie postaci</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Dostęp do kanału graczy</p><p><span>Dostęp do kanału deweloperskiego</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Ekskluzywny kolor pseudonimu</p><p><span>Ekskluzywne emotikony</span></p><p><span>Animowany obrazek profilowy </span></p><p>x2 dodatkowe punkty do wyróżnienia wpisu (codziennie)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Gadżety</h3><p>Przypinki i naklejki (co 6 miesięcy)</p><p>Wysokiej jakości plakat (co 6 miesięcy)</p><p><span>Markowe zabawki pluszowe (co roku)</span></p><p><span>Wysokiej jakości figurki (co roku)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Wydarzenia offline</h3><p>✓ Darmowy wstęp na nasze konwencje dla graczy</p><p>✓ Wycieczka po siedzibie firmy (co roku)</p>",
  "client.buy_button.text": "Kup teraz",
  "client.buy_button.unavailable": "Niedostępny",
  "client.buy_button.pre-order": "Zamów w przedsprzedaży",
  "client.buy_button.checkout": "Przejdź do realizacji",
  "client.store.cart.add_button": "Dodaj do koszyka",
  "client.store.cart.header_button": "Koszyk",
  "client.store.no_items": "Sklep jest chwilowo pusty. Prosimy wrócić później!",
  "client.store.error.gt10_gk": "W celu zapewnienia ochrony przed próbami oszustw, możesz zakupić jedynie do 10 kluczy naraz.",
  "client.store.error.no_keys": "Gra jest tymczasowo niedostępna. Wkrótce pojawi się ponownie. Zapraszamy do kontaktu z nami, aby dowiedzieć się więcej o aktualizacjach.",
  "client.store.item.purchased": "Zakupiono",
  "client.store.item.price_from": "od {price}",
  "client.store.item.show_more": "Wyświetl szczegóły",
  "client.store.successful_purchase": "Transakcja została zrealizowana. Dziękujemy za zakup!",
  "client.store.not_enough_vc": "Niewystarczająca ilość wirtualnej waluty.</br>Uzupełnij saldo, aby ukończyć zakup.",
  "client.store.non_consumable_item": "Kupiłeś ten przedmiot wcześniej i nie jest on już dostępny.",
  "client.store.user_id_input_before_submit_hint": "Produkty zostaną przypisane do tego konta.",
  "client.store.user_id_input_after_submit_hint": "Dokonujesz zakupu dla:",
  "client.common_error": "Coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z naszym zespołem ds. integracji pod adresem <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Twoje saldo",
  "client.header.logout": "Wyloguj",
  "client.retailers.edition.title": "Wybierz swoją kopię",
  "client.retailers.country.title": "Wybierz swój kraj",
  "client.retailers.platform.title": "Wybierz platformę",
  "client.retailers.retailer.title": "Wybierz sprzedawcę",
  "client.retailers.edition.digital": "Cyfrowa",
  "client.retailers.edition.physical": "Fizyczna",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Kup od programisty",
  "client.retailers.expand_button.collapse": "Zwiń",
  "client.retailers.buy_button.buy": "Kup teraz",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Najpopularniejsza",
  "blocks.retailers.components.retailers.standart.value.subtext": "Dostępna teraz",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Edycja standardowa</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Klucz gry, ekskluzywna odznaka, ścieżka dźwiękowa, mapa i zestaw z dodatkową zbroją",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TYTUŁ TWOJEJ GRY</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Twoja gra zabierze graczy w niesamowitą przygodę pełną potworów, niebezpieczeństw, łowców nagród i innych atrakcji.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Dostępna na",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Wpisz swój adres e-mail",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Zapisz się do naszego newslettera, aby otrzymywać najświeższe informacje i aktualności",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Skonfiguruj przycisk",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Kup teraz",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Skonfiguruj przycisk",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Skonfiguruj przycisk",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Skonfiguruj przycisk",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Niedostępny",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Skonfiguruj przycisk",
  "client.topup.input.errorMessage": "Coś poszło nie tak. Upewnij się, że identyfikator użytkownika jest poprawny lub spróbuj ponownie później.",
  "client.topup.input.successMessage": "Zalogowałeś się korzystając z identyfikatora {userID}. Możesz teraz dokonywać zakupów na stronie.",
  "client.topup.input.username": "Nazwa użytkownika: {name}",
  "client.topup.input.id": "Identyfikator: {id}",
  "client.topup.input.level": "Poziom: {level}",
  "client.topup.input.user_not_found": "Nie znaleziono użytkownika z określonym identyfikatorem.<br/>Ponów próbę.",
  "client.topup.input.serverError": "Coś poszło nie tak.<br>Jeśli natrafisz na ten komunikat ponownie, prosimy o {contact_us}.",
  "client.topup.input.serverError.contact_us": "kontakt",
  "client.topup.authorized_title": "Dokonujesz zakupu dla:",
  "blocks.store.values.custom_title": "<h2>Sklep {gameTitle}</h2>"
};

module.exports = localizations;
