var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Zobrazit více",
  "client.header.login.button": "Přihlásit se",
  "client.header.logout.button": "Odhlásit se",
  "client.user-id-modal.input-placeholder": "ID uživatele",
  "client.user-id-modal.input-incorrect-id": "Neplatné ID uživatele.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Zkopírujte a vložte své ID uživatele ze hry.",
  "client.user-id-modal.continue-button": "Pokračovat",
  "client.user-id-modal.go-back": "Zpět",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Zobrazit více",
  "client.privacy-settings.open-button": "Nastavení soukromí",
  "client.usa-privacy-settings.open-button": "Neprodávejte moje data",
  "client.user-id-modal.continue-with": "Pokračujte pomocí {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Vložte svoje ID uživatele",
  "document.pages.auth.subtitle": "nebo zadejte ID",
  "document.pages.auth.placeholder": "Příklad: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Nemůžete najít svoje ID uživatele?",
  "document.pages.auth.instruction.steps.description": "Popište, jak může uživatel najít ID uživatele. Můžete přidat několik kroků a obrázků.",
  "document.pages.seo.title": "Nová hra právě vyšla!",
  "document.pages.seo.description": "Vaše hra vezme hráče na velké dobrodružství plné zrádců, monster, lovců zlata a vážného nebezpečí.",
  "document.pages.seo.ogTitle": "Nová hra právě vyšla!",
  "document.pages.seo.ogDescription": "Vaše hra vezme hráče na velké dobrodružství plné zrádců, monster, lovců zlata a vážného nebezpečí.",
  "default-data.templates.google-play.contact-us": "Kontaktujte nás na {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "Dobijte si herní peněženku",
  "blocks.topup.values.description": "Vložte ID uživatele",
  "blocks.topup.values.userIdPlaceholder": "Příklad: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "NEMŮŽETE NAJÍT SVOJE ID UŽIVATELE?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Název kroku",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Popište, jak může uživatel najít ID uživatele. Můžete přidat několik kroků a obrázků.",
  "default-data.templates.topup.app-support": "Podpora aplikace",
  "default-data.templates.topup.age-raiting": "Věkové hodnocení",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>NÁZEV VAŠÍ HRY</h1>",
  "blocks.retailers.values.title": "<h2>Vyberte vaši edici</h2>",
  "blocks.hero.values.description": "Vaše hra vezme hráče na velké dobrodružství plné zrádců, monster, lovců zlata a vážného nebezpečí.",
  "blocks.hero.values.affiliate": "Příjmy z tohoto nákupu budou sdíleny s partnerem, který vás pozval",
  "blocks.hero.components.platforms.label": "Dostupné na",
  "blocks.hero.components.subscribe.label": "Vložte svůj e-mail",
  "blocks.hero.components.subscribe.values.text" : "Přihlaste se k odběru našich novinek a získejte přístup k nejnovějším zprávám a aktualizacím",
  "blocks.hero.components.scroll.label": "Posunout",
  "blocks.footer.values.name": "Vložte svůj text",
  "blocks.footer.values.affiliate": "Připojit se k partnerskému programu",
  "blocks.hero.components.buy.label": "Koupit nyní",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.hero.components.customButton.link.label": "Nastavit tlačítko",
  "blocks.header.components.customButton.link.label": "Nastavit tlačítko",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.packs.components.pack.customButton.link.label": "Nastavit tlačítko",
  "blocks.packs.components.pack.standart.components.buy.label": "Koupit nyní",
  "blocks.packs.components.pack.premium.components.buy.label": "Koupit nyní",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Koupit nyní",
  "blocks.packs.components.pack.platinum.components.buy.label": "Koupit nyní",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.packs.components.pack.standart.value.ribbon": "Nejpopulárnější",
  "blocks.packs.components.pack.premium.value.ribbon": "Nejpopulárnější",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Nejpopulárnější",
  "blocks.packs.components.pack.platinum.value.ribbon": "Nejpopulárnější",
  "blocks.description.values.title": "<h2>POPISEK HRY</h2>",
  "blocks.description.components.text.label": "Vaše hra vezme hráče na velké dobrodružství plné zrádců, monster, lovců zlata a vážného nebezpečí. Udály se dramatické události, které změnily váš život a teď musíte bojovat o přežití v novém nepřátelském světě. Objevujte, zkoumejte, dobývejte a přežívejte ve všech různých dimenzích reality!",
  "blocks.faq.values.title": "<h2>Často kladené otázky</h2>",
  "blocks.faq.components.question.question": "Jak si můžu zahrát Vaši hru?",
  "blocks.faq.components.question.answer": "Abyste si zahráli Vaši hru, musíte si ji zakoupit a zadat svůj e-mail. Obdržíte aktivační klíč, uplatníte jej a poté si můžete hru užít.",
  "blocks.news.values.title": "<h2>Novinky</h2>",
  "blocks.news.values.description": "Podívejte se na naše příspěvky, abyste zjistili, co je nového.",
  "blocks.store.values.title": "<h2>Obchod</h2>",
  "blocks.store.buy_button.text": "KOUPIT NYNÍ ZA {amount}",
  "blocks.embed.values.title": "<h2>Widgety pro sociální média</h2>",
  "blocks.embed.components.twitter.title": "<h3>PŘÍSPĚVKY Z TWITTERU</h3>",
  "blocks.embed.components.facebook.title": "<h3>PŘÍSPĚVKY Z FACEBOOKU</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Vyberte edici",
  "blocks.packs.values.description": "Každá edice obsahuje exkluzivní výhody a benefity, které obdržíte po jejím zakoupení.",
  "blocks.packs.components.pack.standart.value.subtext": "Dostupné nyní",
  "blocks.packs.components.pack.premium.value.subtext": "Dostupné nyní",
  "blocks.packs.components.pack.ultimate.value.subtext": "Dostupné nyní",
  "blocks.packs.components.pack.platinum.value.subtext": "Dostupné nyní",
  "blocks.packs.components.pack.standart.value.title": "<h3>Standardní edice</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Prémiová edice</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Platinová edice</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ultimátní edice</h3>",
  "blocks.packs.components.pack.standart.value.description": "Klíč ke hře, exkluzivní odznak, soundtrack, mapa a dodatečná zbroj",
  "blocks.packs.components.pack.premium.value.description": "Klíč ke hře, exkluzivní odznak, soundtrack, mapa, dodatečná zbroj a dodatečná zbraň",
  "blocks.packs.components.pack.platinum.value.description": "Klíč ke hře, exkluzivní odznak, soundtrack, mapa, digitální artbook, dodatečná zbroj, dodatečná zbraň a jeden náhodný vzhled",
  "blocks.packs.components.pack.ultimate.value.description": "Klíč ke hře, exkluzivní odznak, soundtrack, mapa, digitální artbook, digitální soundtrack, dodatečná zbroj, dvě dodatečné zbraně a tři náhodné vzhledy",
  "blocks.subscriptions.values.card.title": "Pořiďte si prémiové předplatné a získejte výhody",
  "blocks.subscriptions.values.title": "Aktivovat předplatné",
  "blocks.subscriptions.values.heading": "Předplatná",
  "blocks.subscriptions.values.bullets.one.title": "Digitální obsah",
  "blocks.subscriptions.values.bullets.one.description": "Obrázky, tapety, obsah ze zákulisí, herní soundtracky",
  "blocks.subscriptions.values.bullets.two.title": "Herní obsah navíc",
  "blocks.subscriptions.values.bullets.two.description": "Vzhledy postav, vizuální efekty, herní měna",
  "blocks.subscriptions.values.bullets.three.title": "3 herní lootboxy",
  "blocks.subscriptions.values.bullets.three.description": "5 extra vstupů do Hall of Chaos, 10 obnovení postavy",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Další obnova proběhne",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Obnovit",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Spravovat plán",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Vyberte svůj plán:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Nastavit předplatné",
  "editor.subscriptions-packs.button.trial.days.for.free": "Dní zdarma: {trial}",
  "editor.subscriptions-packs.button.then.price.per.day": "Poté {amount} za den",
  "editor.subscriptions-packs.button.then.price.per.week": "Poté {amount} za týden",
  "editor.subscriptions-packs.button.then.price.per.month": "Poté {amount} za měsíc",
  "editor.subscriptions-packs.button.then.price.per.year": "Poté {amount} za rok",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Poté {amount} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Poté {amount} napořád",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "jako první platba, poté {amount} za den",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "jako první platba, poté {amount} za týden",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "jako první platba, poté {amount} za měsíc",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "jako první platba, poté {amount} za rok",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "jako první platba, poté {amount} napořád",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "jako první platba, poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "jako první platba, poté {amount} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "jako první platba, poté {amount} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "jako první platba, poté {amount} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.price.per.day": "{amount} za den",
  "editor.subscriptions-packs.button.price.per.week": "{amount} za týden",
  "editor.subscriptions-packs.button.price.per.month": "{amount} za měsíc",
  "editor.subscriptions-packs.button.price.per.year": "{amount} za rok",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} napořád",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za den na {periodsCounts} dní, poté {amount} za den",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za týden na {periodsCounts} týdnů, poté {amount} za týden",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za měsíc na {periodsCounts} měsíců, poté {amount} za měsíc",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok na {periodsCounts} roků, poté {amount} za rok",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Pak <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dny/dnů na {periodsCounts} období, poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Pak <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} týdny/týdnů na {periodsCounts} období, poté {amount} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Pak <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} měsíce/měsíců na {periodsCounts} období, poté {amount} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Pak <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} roky/roků na {periodsCounts} období, poté {amount} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Poté {amount} jako první platba, poté {amountPromo} za den",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Poté {amount} jako první platba, poté {amountPromo} za týden",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Poté {amount} jako první platba, poté {amountPromo} za měsíc",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Poté {amount} jako první platba, poté {amountPromo} za rok",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Pak {amount} jako první platba, poté {amountPromo} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Pak {amount} jako první platba, poté {amountPromo} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Pak {amount} jako první platba, poté {amountPromo} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Pak {amount} jako první platba, poté {amountPromo} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Poté {amount} jako první platba, poté {amountPromo} napořád",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "na {periodsCounts} dní, potom {amount} za den",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "na {periodsCounts} týdnů, potom {amount} za týden",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "na {periodsCounts} měsíců, potom {amount} za měsíc",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "na {periodsCounts} roků, potom {amount} za rok",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "na {periodsCounts} období, poté {amount} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "na {periodsCounts} období, poté {amount} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "na {periodsCounts} období, poté {amount} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "na {periodsCounts} období, poté {amount} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezóna končí {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezóna začíná {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Poté {amount}, sezóna končí {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Poté {amount}, sezóna začíná {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za den",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za týden",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za měsíc",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} napořád",
  "editor.subscriptions-packs.button.then.price.promo.price": "Poté <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za den",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za týden",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za měsíc",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za rok",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} za {periodValue} roky/roků",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} napořád",
  "editor.subscriptions-packs.button.then.price": "Poté {amount}",
  "editor.subscriptions-packs.button.season.ends": "Sezóna končí {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Sezóna začíná {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount}/den",
  "editor.subscriptions-packs.button.price.period.week": "{amount}/týden",
  "editor.subscriptions-packs.button.price.period.month": "{amount}/měsíc",
  "editor.subscriptions-packs.button.price.period.year": "{amount}/rok",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} dny/dnů",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} týdny/týdnů",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} měsíce/měsíců",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} roky/roků",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} napořád",
  "blocks.requirements.values.title": "<h2>Systémové požadavky</h2>",
  "blocks.requirements.components.platform_req.value.title": "Platforma",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Grafická karta",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "Operační systém",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Úložiště",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Procesor",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Zvuková karta",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Paměť",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimální",
  "default-data.values.requirements.title.recommended": "Doporučené",
  "default-data.values.requirements.item.directx.minimum": "Verze 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx/ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2,5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Kompatibilní s DirectX 9",
  "default-data.values.requirements.item.sound_card.recommended": "Kompatibilní s DirectX 9",
  "default-data.values.requirements.item.directx.recommended": "Verze 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760/AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Zdravíme!",
  "default-data.customization.receipt.description": "Děkujeme za váš nákup! Vážíme si toho, že hrajete naši hru.",
  "default-data.customization.receipt.footer": "© Všechna práva vyhrazena.",
  "blocks.store.components.storeSection.title": "Položky",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Přihlášení k odběru novinek bylo úspěšné!",
  "notification.hero.components.subscribe.subscription.success": "Právě jsme vám poslali potvrzovací e-mail. Chcete-li odběr aktivovat, klikněte na odkaz v e-mailu.",
  "notification.hero.components.subscribe.error": "Něco se pokazilo. Zkuste to prosím znovu později nebo kontaktujte náš integrační tým na adrese <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "K odběru novinek jste se již přihlásili.",
  /* notifications values END */
  "period.years_short": "let",
  "period.months_short": "měs.",
  "period.weeks_short": "týd.",
  "period.days_short": "dní",
  "subscription.button.text": "Předplatit si</br>{fullCurrency} za {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Vaše předplatné",
  "client.subscription.button.configuration": "Spravovat předplatné",
  "client.subscription.button.lifetime_text": "Předplatit navždy</br>za {fullCurrency}",
  "client.thank_you_modal.title": "Děkujeme za přihlášení k odběru!",
  "client.thank_you_modal.description": "Vaše předplatné se obnoví automaticky. Platba bude účtována pravidelně podle podmínek předplatného. Můžete jej kdykoli zrušit.",
  "client.thank_you_modal.input.description": "Pokud chcete vidět své jméno v seznamu předplatitelů, zadejte jej níže.",
  "client.thank_you_modal.button.default.text": "Zavřít",
  "client.thank_you_modal.input.placeholder": "Vaše jméno",
  "client.thank_you_modal.button.show_list": "Zobrazit všechny předplatitele",
  "client.subscribers_list_modal.title": "Seznam všech předplatitelů",
  "client.subscribers_list_modal.description": "Děkujeme všem uživatelům, kteří se k nám připojili a podpořili nás!",
  "client.subscribers_list_modal.show_more_button": "Zobrazit více",
  "client.subscribers_list_modal.anonymous": "a anonymní uživatelé",
  "client.footer.button.list_of_subscribers": "Seznam předplatitelů",
  "client.footer.xsollalogo.powered": "Používá technologii", // копирайт в футере
  "client.footer.xsollalogo.sb": "Tvůrce stránek Xsolla",
  "client.empty_subscribers_modal.title": "Zde budou uvedeni předplatitelé",
  "client.empty_subscribers_modal.description": "Buďte mezi našimi prvními předplatiteli.",
  "client.empty_subscribers_modal.button": "Vybrat předplatné",
  "client.news.blurred.description": "Staňte se předplatitelem plánu „{subscription_name}“ a odemkněte tento obsah.",
  "client.news.blurred.button.join": "Připojit se",
  "client.cart_modal.title": "Košík",
  "client.cart_modal.item.pre-order": "(předobjednávka)",
  "client.cart_modal.clear_all": "odebrat vše",
  "client.cart_modal.item_purchased_description": "Tuto položku jste již zakoupili",
  "client.cart_modal.subtotal_title": "Celkem",
  "client.cart_modal.subtotal_subtitle": "Mohou se účtovat další daně a poplatky",
  "client.cart_modal.continue_button": "pokračovat v nákupu",
  "client.cart_modal.checkout_button": "přejít k pokladně",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Přihlaste se</button> nebo <button class=\'xl_auth\' data-xl-route=\'signup\'>se zaregistrujte</button> a uložte si položky do košíku na později nebo si prohlédněte dříve uložené položky",
  "client.cart_modal.empty_cart_description": "Váš košík je prázdný. Jděte do obchodu a najděte něco úžasného, co si budete chtít koupit.",
  "client.cart_modal.empty_cart_open_store_button": "otevřít obchod",
  "client.cart_modal.email_label": "Poslat aktivační klíč hry na:",
  "client.cart_modal.email_description": "Obdržíte klíč, který musíte aktivovat, abyste mohli začít hrát.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Použít",
  "client.cart_modal.promo_codes.remove": "Odebrat",
  "client.cart_modal.promo_codes.placeholder": "Promo kód",
  "client.cart_modal.promo_codes.error_incorrect": "Chybný promo kód.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Bylo dosaženo limitu pro uplatnění kódu.",
  "client.cart_modal.promo_codes.error_expired": "Platnost tohoto promo kódu vypršela.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Při použití promo kódu nastala chyba. Zkuste to znovu později.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Promo kód není pro položky ve Vašem košíku platný.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Chcete-li pokračovat, vyberte herní platformu.",
  "client.cart_modal.promo_codes.select_drm": "Vybrat platformu",
  "client.cart_modal.promo_codes.tooltip_drm": "Chcete-li změnit platformu, vymažte promo kód a použijte ho znovu",
  "client.bundle_modal.group_items": "Předměty v balíčku",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronzový</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Digitální obsah</h3><p>✓ Artbook</p><p>✓ Tapety</p><p><span style=\'color:rgb(127, 96, 0);\'>× Herní soundtrack </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Obsah ze zákulisí</span></p><p><br></p><h3>3 Herní</h3><h3>lootboxy</h3><p>✓ Vzhledy postav</p><p><span style=\'color:rgb(127, 96, 0);\'>× Vizuální efekty </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Herní měna</span></p><p><br></p><h3>Herní výhody</h3><p>✓ x1 extra vstup do Hall of Chaos</p><p>✓ x1 obnovení postavy</p><p><br></p><h3>Discord</h3><p>✓ Možnost si popovídat s hráči</p><p><span style=\'color:rgb(127, 96, 0);\'>× Možnost si popovídat s vývojáři</span></p><p><br></p><h3>Fórum</h3><p>✓ Exkluzivní barva přezdívky</p><p><span style=\'color:rgb(127, 96, 0);\'>× Exkluzivní emoji</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Animovaný profilový obrázek </span></p><p>✓ x2 extra body do žebříčku (denně)</p><p><br></p><h3>Merch</h3><p>✓ Brože a nálepky (každých 6 měsíců)</p><p>✓ Kvalitní plakáty (každých 6 měsíců)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Značkoví plyšáci (každý rok)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Kvalitní figurky (každý rok)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>× Offline events</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>Offline události</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Přístup na naše herní akce</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Návštěva kanceláří vývojáře (každý rok)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ získáš přístup k Discordu</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Stříbrný</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Digitální obsah</h3><p>✓ Artbook</p><p>✓ Tapety</p><p><span style=\"color:rgb(127, 96, 0);\">× Herní soundtrack</span></p><p>✓ Obsah ze zákulisí</p><p><br></p><h3>6 Herní</h3><h3>lootboxy</h3><p>✓ Vzhledy postav</p><p>✓ Vizuální efekty</p><p><span style=\"color:rgb(127, 96, 0);\">× Herní měna</span></p><p><br></p><h3>Herní výhody</h3><p>✓ x2 extra vstup do Hall of Chaos</p><p>✓ x2 obnovení postavy</p><h3><br></h3><h3>Discord</h3><p>✓ Možnost si popovídat s hráči</p><p><span style=\"color:rgb(127, 96, 0);\">× Možnost si popovídat s vývojáři</span></p><p><br></p><h3>Fórum</h3><p>✓ Exkluzivní barva přezdívky</p><p><span style=\"color:rgb(127, 96, 0);\">× Exkluzivní emoji</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Animovaný profilový obrázek</span></p><p>✓ 5x extra body do žebříčku (denně)</p><p><br></p><h3>Merch</h3><p>✓ Brože a nálepky (každých 6 měsíců)</p><p>✓ Kvalitní plakáty (každých 6 měsíců)</p><p><span style=\"color:rgb(127, 96, 0);\">× Značkoví plyšáci (každý rok)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Kvalitní figurky (každý rok)</span></p><p><br></p><h3>Offline události</h3><p>✓ Přístup na naše herní akce</p><p><span style=\"color:rgb(127, 96, 0);\">× Návštěva kanceláří vývojáře (každý rok)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ získáš přístup k Discordu</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Zlatý</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Digitální obsah</h3><p>✓ Artbook</p><p>✓ Tapety</p><p>✓ Herní soundtrack</p><p>✓ Obsah ze zákulisí</p><p><br></p><h3>9 Herní</h3><h3>lootboxy</h3><p>✓ Vzhledy postav</p><p>✓ Vizuální efekty</p><p>✓ Herní měna</p><p><br></p><h3>Herní výhody</h3><p>✓ x5 extra vstup do Hall of Chaos</p><p>✓ x10 obnovení postavy</p><p><br></p><h3>Discord</h3><p>✓ Možnost si popovídat s hráči</p><p>✓ Možnost si popovídat s vývojáři</p><p><br></p><h3>Fórum</h3><p>✓ Exkluzivní barva přezdívky</p><p>✓ Exkluzivní emoji</p><p>✓ Animovaný profilový obrázek</p><p>✓ x12 extra body do žebříčku (denně)</p><p><br></p><h3>Merch</h3><p>✓ Brože a nálepky (každých 6 měsíců)</p><p>✓ Kvalitní plakáty (každých 6 měsíců)</p><p>✓ Značkoví plyšáci (každý rok)</p><p>✓ Kvalitní figurky (každý rok)</p><p><br></p><h3>Offline události</h3><p>✓ Přístup na naše herní akce</p><p>✓ Návštěva kanceláří vývojáře (každý rok)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ získáš přístup k Discordu</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Digitální obsah</h3><p>Artbook</p><p>Tapety</p><p><span>Herní soundtrack </span></p><p><span>Obsah ze zákulisí</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Herní</h3><h3>lootboxy</h3><p>Vzhledy postav</p><p><span>Vizuální efekty </span></p><p><span>Herní měna</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Herní výhody</h3><p>x1 extra vstup do Hall of Chaos</p><p>x1 obnovení postavy</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Možnost si popovídat s hráči</p><p><span>× Možnost si popovídat s vývojáři</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Fórum</h3><p>Exkluzivní barva přezdívky</p><p><span>× Exkluzivní emoji</span></p><p><span>× Animovaný profilový obrázek</span></p><p>x2 extra body do žebříčku (denně)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merch</h3><p>Brože a nálepky (každých 6 měsíců)</p><p>Kvalitní plakáty (každých 6 měsíců)</p><p><span>Značkoví plyšáci (každý rok)</span></p><p><span>Kvalitní figurky (každý rok)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Offline události</h3><p>✓ Přístup na naše herní akce</p><p>✓ Návštěva kanceláří vývojáře (každý rok)</p>",
  "client.buy_button.text": "Koupit nyní",
  "client.buy_button.unavailable": "Nedostupné",
  "client.buy_button.pre-order": "Předobjednat",
  "client.buy_button.checkout": "Přejít k pokladně",
  "client.store.cart.add_button": "Přidat do košíku",
  "client.store.cart.header_button": "Košík",
  "client.store.no_items": "Obchod je prozatím prázdný. Přijďte později!",
  "client.store.error.gt10_gk": "V zájmu ochrany proti podvodům lze najednou zakoupit pouze 10 klíče.",
  "client.store.error.no_keys": "Hra je dočasně vyprodána, ale její zásoby budou brzy doplněny. Neváhejte nás kontaktovat ohledně aktualizací.",
  "client.store.item.purchased": "Zakoupeno",
  "client.store.item.price_from": "od {price}",
  "client.store.item.show_more": "Zobrazit podrobnosti",
  "client.store.successful_purchase": "Děkujeme za váš nákup!",
  "client.store.not_enough_vc": "Nemáte dostatek virtuální měny.</br>Chcete-li nákup dokončit, dobijte si ji.",
  "client.store.non_consumable_item": "Tuto položku jste již zakoupili a již není k dispozici.",
  "client.store.user_id_input_before_submit_hint": "Položky budou přidány na tento účet.",
  "client.store.user_id_input_after_submit_hint": "Nakupujete pro:",
  "client.common_error": "Něco se pokazilo. Zkuste to prosím znovu později nebo kontaktujte náš integrační tým na adrese <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Váš zůstatek",
  "client.header.logout": "Odhlásit se",
  "client.retailers.edition.title": "Vyberte edici",
  "client.retailers.country.title": "Vyberte zemi",
  "client.retailers.platform.title": "Vyberte platformu",
  "client.retailers.retailer.title": "Vyberte prodejce",
  "client.retailers.edition.digital": "Digitální",
  "client.retailers.edition.physical": "Fyzická",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Koupit od vývojáře",
  "client.retailers.expand_button.collapse": "Sbalit",
  "client.retailers.buy_button.buy": "Koupit nyní",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Nejpopulárnější",
  "blocks.retailers.components.retailers.standart.value.subtext": "Dostupné nyní",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Standardní edice</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Klíč ke hře, exkluzivní odznak, soundtrack, mapa a dodatečná zbroj",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>NÁZEV VAŠÍ HRY</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Vaše hra vezme hráče na velké dobrodružství plné zrádců, monster, lovců zlata a vážného nebezpečí.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Dostupné na",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Vložte svůj e-mail",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Přihlaste se k odběru našich novinek a získejte přístup k nejnovějším zprávám a aktualizacím",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Nastavit tlačítko",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Koupit nyní",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Nastavit tlačítko",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Nastavit tlačítko",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Nastavit tlačítko",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Nedostupné",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Nastavit tlačítko",
  "client.topup.input.errorMessage": "Něco se pokazilo. Ujistěte se, že je vaše ID uživatele správné, nebo to zkuste později.",
  "client.topup.input.successMessage": "Přihlásili jste se pod ID uživatele {userID}. Nyní můžete na stránkách nakupovat.",
  "client.topup.input.username": "Uživatelské jméno: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Úroveň: {level}",
  "client.topup.input.user_not_found": "Nemohli jsme najít uživatele s vámi zadaným ID.<br/>Prosím zkuste to znovu.",
  "client.topup.input.serverError": "Něco se pokazilo.<br>Pokud uvidíte tuto zprávu více než jednou, prosím {contact_us}.",
  "client.topup.input.serverError.contact_us": "kontaktujte nás",
  "client.topup.authorized_title": "Nakupujete pro:",
  "blocks.store.values.custom_title": "<h2>Obchod hry {gameTitle}</h2>"
};

module.exports = localizations;
