import type { IUserAccountIdServiceProps } from '../user-account-id-service';

import { CookieNames, setCookie } from '../../../../utils/cookie';
import { UserAccountIdService } from '../user-account-id-service';

export class UserAccountEAAuthService extends UserAccountIdService {
  protected static instance: UserAccountEAAuthService | null = null;

  constructor(props: IUserAccountIdServiceProps) {
    super(props);
  }

  get userName() {
    let name = '';
    if (this.tokenData?.type === 'social') {
      name = this.tokenData.server_custom_id || '';
    }
    if (!name) {
      name = super.userName;
    }
    return name;
  }

  get isAuthorized(): boolean {
    return !this.needUpdateToken && super.isAuthorized;
  }

  get needUpdateToken() {
    return (
      this.tokenData?.type === 'social' && !this.tokenData.server_custom_id
    );
  }

  get isSocialAuth() {
    return this.tokenData?.type === 'social';
  }

  get id() {
    return this.tokenData?.type === 'social'
      ? this.tokenData.server_custom_id
      : this.webhookUserInfo?.id;
  }

  authUser(
    userId: string,
    country?: string,
    needUpdateInstance?: boolean
  ): Promise<{ isAuth: boolean; status: number }> {
    if (this.isSocialAuth) {
      return this.updateTokenByUserId(userId, country);
    }
    return super.authUser(userId, country, needUpdateInstance);
  }

  async updateTokenByUserId(userId: string, country?: string) {
    const { userIdLoginId } = this.authSettings;
    const { merchantId, projectId } = this.projectInfo;
    const res = await this.userIdServiceApi.updateSocialTokenByUserId({
      settings: {
        projectId: Number(projectId),
        merchantId: Number(merchantId),
      },
      loginId: userIdLoginId,
      webhookUrl: this.getWebhookUrl(),
      user: {
        id: userId,
        country,
      },
      token: this.token,
    });
    if (res.status === 201) {
      const { token } = res.data;
      setCookie(CookieNames.USER_LOGIN_TOKEN, token);
      window.location.reload();
    }
    return { isAuth: res.status === 201, status: res.status };
  }

  static getInstance(
    { projectId, merchantId, auth }: IUserAccountIdServiceProps,
    needCreate = false
  ) {
    if (!UserAccountEAAuthService.instance || needCreate) {
      UserAccountEAAuthService.instance = new UserAccountEAAuthService({
        projectId,
        merchantId,
        auth,
      });
    }
    return UserAccountEAAuthService.instance;
  }
}
