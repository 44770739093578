var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Montrer plus",
  "client.header.login.button": "Se connecter",
  "client.header.logout.button": "Se déconnecter",
  "client.user-id-modal.input-placeholder": "ID utilisateur",
  "client.user-id-modal.input-incorrect-id": "ID utilisateur incorrect",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Essayez de copier et coller votre ID depuis le jeu.",
  "client.user-id-modal.continue-button": "Continuer",
  "client.user-id-modal.go-back": "Retour",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Jeux épiques",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Montrer plus",
  "client.privacy-settings.open-button": "Paramètres de confidentialité",
  "client.usa-privacy-settings.open-button": "Ne vendez pas mes données",
  "client.user-id-modal.continue-with": "Continuer avec {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Entrez votre ID utilisateur",
  "document.pages.auth.subtitle": "ou entrez ID",
  "document.pages.auth.placeholder": "Exemple : 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Vous ne trouvez pas votre ID utilisateur ?",
  "document.pages.auth.instruction.steps.description": "Décrivez comment l\'utilisateur peut trouver son ID personnel. Vous pouvez ajouter plusieurs étapes et images.",
  "document.pages.seo.title": "Le nouveau jeu vient de sortir !",
  "document.pages.seo.description": "Votre jeu emmène les joueurs dans une grande aventure remplie de détracteurs, de monstres, de chasseurs d\'or et de graves dangers.",
  "document.pages.seo.ogTitle": "Le nouveau jeu vient de sortir !",
  "document.pages.seo.ogDescription": "Votre jeu emmène les joueurs dans une grande aventure remplie de détracteurs, de monstres, de chasseurs d\'or et de graves dangers.",
  "default-data.templates.google-play.contact-us": "Contactez-nous {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "RECHARGER LE PORTE-MONNAIE INTÉGRÉ AU JEU",
  "blocks.topup.values.description": "Entrer l\'ID utilisateur",
  "blocks.topup.values.userIdPlaceholder": "Exemple : 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "VOUS NE TROUVEZ PAS VOTRE ID UTILISATEUR ?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Nom de l\'étape",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Décrivez comment l\'utilisateur peut trouver son ID personnel. Vous pouvez ajouter plusieurs étapes et images.",
  "default-data.templates.topup.app-support": "Assistance",
  "default-data.templates.topup.age-raiting": "Âge",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>LE TITRE DE VOTRE JEU</h1>",
  "blocks.retailers.values.title": "<h2>Choisissez votre édition</h2>",
  "blocks.hero.values.description": "Votre jeu emmène les joueurs dans une grande aventure remplie de détracteurs, de monstres, de chasseurs d\'or et de graves dangers.",
  "blocks.hero.values.affiliate": "Les revenus de cet achat seront partagés avec votre affilié.",
  "blocks.hero.components.platforms.label": "Disponible pour",
  "blocks.hero.components.subscribe.label": "Entrez votre email",
  "blocks.hero.components.subscribe.values.text" : "Inscrivez-vous à notre newsletter pour recevoir les dernières nouvelles et mises à jour",
  "blocks.hero.components.scroll.label": "Faire défiler",
  "blocks.footer.values.name": "Saisissez votre texte",
  "blocks.footer.values.affiliate": "Rejoignez le programme d\'affiliation",
  "blocks.hero.components.buy.label": "Acheter maintenant",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.hero.components.customButton.link.label": "Bouton de configuration",
  "blocks.header.components.customButton.link.label": "Bouton de configuration",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.packs.components.pack.customButton.link.label": "Bouton de configuration",
  "blocks.packs.components.pack.standart.components.buy.label": "Acheter maintenant",
  "blocks.packs.components.pack.premium.components.buy.label": "Acheter maintenant",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Acheter maintenant",
  "blocks.packs.components.pack.platinum.components.buy.label": "Acheter maintenant",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.packs.components.pack.standart.value.ribbon": "Les plus populaires",
  "blocks.packs.components.pack.premium.value.ribbon": "Les plus populaires",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Les plus populaires",
  "blocks.packs.components.pack.platinum.value.ribbon": "Les plus populaires",
  "blocks.description.values.title": "<h2>DESCRIPTION DU JEU</h2>",
  "blocks.description.components.text.label": "Votre jeu emmène les joueurs dans une grande aventure remplie de détracteurs, de monstres, de chasseurs d\'or et de graves dangers. Des événements dramatiques se sont déroulés qui ont bouleversé votre vie et maintenant vous devez vous battre pour survivre dans un nouveau monde hostile. Découvrez, explorez et conquérez en survivant à travers différentes dimensions de la réalité !",
  "blocks.faq.values.title": "<h2>FAQ</h2>",
  "blocks.faq.components.question.question": "Comment puis-Je jouer à Votre Jeu ?",
  "blocks.faq.components.question.answer": "Pour jouer à Notre Jeu, vous devez l\'acheter et spécifier votre email. Vous recevrez la clé d\'activation, soumettez-la et profitez du jeu.",
  "blocks.news.values.title": "<h2>Nouvelles</h2>",
  "blocks.news.values.description": "Consultez nos articles récents pour voir ce qu\'il y a de nouveau.",
  "blocks.store.values.title": "<h2>Boutique</h2>",
  "blocks.store.buy_button.text": "ACHETER MAINTENANT POUR {amount}",
  "blocks.embed.values.title": "<h2>Widgets des réseaux sociaux</h2>",
  "blocks.embed.components.twitter.title": "<h3>FLUX  TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>FLUX  FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Sélectionnez votre édition",
  "blocks.packs.values.description": "Chaque édition comprend des avantages exclusifs que vous obtiendrez après l\'achat.",
  "blocks.packs.components.pack.standart.value.subtext": "Disponible maintenant",
  "blocks.packs.components.pack.premium.value.subtext": "Disponible maintenant",
  "blocks.packs.components.pack.ultimate.value.subtext": "Disponible maintenant",
  "blocks.packs.components.pack.platinum.value.subtext": "Disponible maintenant",
  "blocks.packs.components.pack.standart.value.title": "<h3>Édition Standard</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Édition Premium</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Édition Platinum</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Édition Ultimate</h3>",
  "blocks.packs.components.pack.standart.value.description": "Clé de jeu, badge exclusif, bande son, carte et pack d\'armure supplémentaire",
  "blocks.packs.components.pack.premium.value.description": "Clé de jeu, badge exclusif, bande sonore, carte, pack d\'armure supplémentaire et pack d\'arme supplémentaire",
  "blocks.packs.components.pack.platinum.value.description": "Clé de jeu, badge exclusif, bande son, carte, livre d\'art numérique, pack d\'armure supplémentaire, pack d\'arme supplémentaire, un pack de peau aléatoire",
  "blocks.packs.components.pack.ultimate.value.description": "Clé de jeu, badge exclusif, bande son, carte, livre d\'art numérique, livre de chansons numérique, pack d\'armure supplémentaire, deux packs d\'armes supplémentaires et trois packs de peau aléatoires",
  "blocks.subscriptions.values.card.title": "Passez Premium et bénéficiez de plusieurs avantages",
  "blocks.subscriptions.values.title": "Activer l\'abonnement",
  "blocks.subscriptions.values.heading": "Abonnements",
  "blocks.subscriptions.values.bullets.one.title": "Contenu digital",
  "blocks.subscriptions.values.bullets.one.description": "Images, fonds d\'écran, contenus des coulisses, bandes-son de jeux",
  "blocks.subscriptions.values.bullets.two.title": "Aptitudes en jeu",
  "blocks.subscriptions.values.bullets.two.description": "Skins de personnages, effets visuels, cartes de scénario",
  "blocks.subscriptions.values.bullets.three.title": "3 lootbox en jeu",
  "blocks.subscriptions.values.bullets.three.description": "5 laissez-passer supplémentaires pour la Salle du Chaos, 10 résurrections de personnages",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Prochain renouvellement",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Renouveler maintenant",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Gérer le plan",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Sélectionner le plan:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configurer l\'abonnement",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} jour(s) gratuit(s)",
  "editor.subscriptions-packs.button.then.price.per.day": "Ensuite, {amount} par jour",
  "editor.subscriptions-packs.button.then.price.per.week": "Ensuite, {amount} par semaine",
  "editor.subscriptions-packs.button.then.price.per.month": "Ensuite, {amount} par mois",
  "editor.subscriptions-packs.button.then.price.per.year": "Ensuite, {amount} par an",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Ensuite {amount} par {periodValue} jours",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Ensuite {amount} par {periodValue} semaines",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Ensuite {amount} par {periodValue} mois",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Ensuite {amount} par {periodValue} ans",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Ensuite, {amount} à vie",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "comme premier paiement, ensuite {amount} par jour",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "comme premier paiement, ensuite {amount} par semaine",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "comme premier paiement, ensuite {amount} par mois",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "comme premier paiement, ensuite {amount} par an",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "comme premier paiement, ensuite {amount} à vie",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "comme premier paiement, ensuite {amount} par {periodValue} jours",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "comme premier paiement, ensuite {amount} par {periodValue} semaines",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "comme premier paiement, ensuite {amount} par {periodValue} mois",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "comme premier paiement, ensuite {amount} par {periodValue} ans",
  "editor.subscriptions-packs.button.price.per.day": "{amount} par jour",
  "editor.subscriptions-packs.button.price.per.week": "{amount} par semaine",
  "editor.subscriptions-packs.button.price.per.month": "{amount} par mois",
  "editor.subscriptions-packs.button.price.per.year": "{amount} par an",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} par {periodValue} jours",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} par {periodValue} semaines",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} par {periodValue} mois",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} par {periodValue} ans",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} à vie",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Ensuite, <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par jour pendant {periodsCounts} jour(s), puis {amount} par jour",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par semaine pendant {periodsCounts} semaine(s), puis {amount} par semaine",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par mois pendant {periodsCounts} mois, puis {amount} par mois",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par an, pendant {periodsCounts} an(s), puis {amount} par an",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} jours pour {periodsCounts} période(s), après {amount} par {periodValue} jours",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} semaines pour {periodsCounts} période(s), après {amount} par {periodValue} semaines",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} mois pour {periodsCounts} période(s), après {amount} par {periodValue} mois",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} ans pour {periodsCounts} période(s), après {amount} par {periodValue} ans",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Ensuite {amount} comme premier paiement, puis {amountPromo} par jour",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Ensuite {amount} comme premier paiement, puis {amountPromo} par semaine",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Ensuite {amount} comme premier paiement, puis {amountPromo} par mois",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Ensuite {amount} comme premier paiement, puis {amountPromo} par an",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Ensuite {amount} comme premier paiement, après {amountPromo} par {periodValue} jours",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Ensuite {amount} comme premier paiement, après {amountPromo} par {periodValue} semaines",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Ensuite {amount} comme premier paiement, après {amountPromo} par {periodValue} mois",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Ensuite {amount} comme premier paiement, après {amountPromo} par {periodValue} ans",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Ensuite {amount} comme premier paiement, puis {amountPromo} à vie",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "pendant {periodsCounts} jour(s), puis {amount} par jour",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "pendant {periodsCounts} semaine(s), puis {amount} par semaine",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "pendant {periodsCounts} mois, puis {amount} par mois",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "pendant {periodsCounts} an(s), puis {amount} par an",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "pour {periodsCounts} période(s), ensuite {amount} par {periodValue} jours",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "pour {periodsCounts} période(s), ensuite {amount} par {periodValue} semaines",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "pour {periodsCounts} période(s), ensuite {amount} par {periodValue} mois",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "pour {periodsCounts} période(s), ensuite {amount} par {periodValue} ans",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "-",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}, la saison se termine le {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}, la saison commence le {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Ensuite {amount}, la saison se termine le {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Ensuite {amount}, la saison commence le {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par jour",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par semaine",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par mois",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par an",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} jours",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} semaines",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} mois",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} ans",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} à vie",
  "editor.subscriptions-packs.button.then.price.promo.price": "Ensuite <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par jour",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par semaine",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par mois",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par an",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} jours",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} semaines",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} mois",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} par {periodValue} ans",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} à vie",
  "editor.subscriptions-packs.button.then.price": "Puis {amount}",
  "editor.subscriptions-packs.button.season.ends": "La saison se termine le {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "La saison commence le {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount}/jour",
  "editor.subscriptions-packs.button.price.period.week": "{amount}/semaine",
  "editor.subscriptions-packs.button.price.period.month": "{amount}/mois",
  "editor.subscriptions-packs.button.price.period.year": "{amount}/an",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount}/{periodValue} jours",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount}/{periodValue} semaines",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount}/{periodValue} mois",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount}/{periodValue} ans",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount}/à vie",
  "blocks.requirements.values.title": "<h2>Configuration système requise</h2>",
  "blocks.requirements.components.platform_req.value.title": "Plate-forme",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Graphique",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Stockage",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Processeur",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Carte son",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Mémoire",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimum",
  "default-data.values.requirements.title.recommended": "Recommandé",
  "default-data.values.requirements.item.directx.minimum": "Version 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GO",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GO RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Compatible DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Compatible DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Version 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GO",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GO RAM",
  "default-data.customization.receipt.title": "Bonjour, ami !",
  "default-data.customization.receipt.description": "Merci pour votre achat ! Nous apprécions que vous jouiez notre jeu.",
  "default-data.customization.receipt.footer": "© Tous droits réservés.",
  "blocks.store.components.storeSection.title": "Articles",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Vous vous êtes inscrit avec succès!",
  "notification.hero.components.subscribe.subscription.success": "Nous venons de vous envoyer un e-mail de confirmation. Veuillez suivre le lien dans le courriel pour activer votre abonnement.",
  "notification.hero.components.subscribe.error": "Quelque chose a mal tourné. Veuillez réessayer plus tard ou contacter notre équipe d\'intégration à <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Vous êtes déjà inscrit.",
  /* notifications values END */
  "period.years_short": "année.",
  "period.months_short": "mois.",
  "period.weeks_short": "semaines.",
  "period.days_short": "jours",
  "subscription.button.text": "Abonnez-vous à</br>{fullCurrency} par {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Votre abonnement",
  "client.subscription.button.configuration": "Gérer l\'abonnement",
  "client.subscription.button.lifetime_text": "Abonnez-vous à vie</br>pour {fullCurrency}",
  "client.thank_you_modal.title": "Merci de votre inscription !",
  "client.thank_you_modal.description": "Votre abonnement se renouvelle automatiquement. Le paiement sera facturé régulièrement en fonction de vos conditions d\'abonnement. Vous pouvez l\'annuler à tout moment.",
  "client.thank_you_modal.input.description": "Si vous souhaitez vous voir dans la liste de nos abonnés, entrez votre nom ci-dessous.",
  "client.thank_you_modal.button.default.text": "Merci, ",
  "client.thank_you_modal.input.placeholder": "Votre nom",
  "client.thank_you_modal.button.show_list": "Afficher tous les abonnés",
  "client.subscribers_list_modal.title": "Liste complète des abonnés",
  "client.subscribers_list_modal.description": "Un grand merci à tous les utilisateurs qui nous ont rejoints et qui se sont inscrits au jeu !",
  "client.subscribers_list_modal.show_more_button": "Montrer plus",
  "client.subscribers_list_modal.anonymous": "et les utilisateurs anonymes",
  "client.footer.button.list_of_subscribers": "Liste des abonnés",
  "client.footer.xsollalogo.powered": "Développé par", // копирайт в футере
  "client.footer.xsollalogo.sb": "Constructeur de site Xsolla",
  "client.empty_subscribers_modal.title": "Les abonnés seront listés ici",
  "client.empty_subscribers_modal.description": "Soyez le premier à vous abonner à notre jeu.",
  "client.empty_subscribers_modal.button": "Sélectionnez un abonnement maintenant",
  "client.news.blurred.description": "Devenez un abonné {subscription_name} pour déverrouiller ce contenu.",
  "client.news.blurred.button.join": "S\'inscrire maintenant",
  "client.cart_modal.title": "Panier",
  "client.cart_modal.item.pre-order": "(pré-commande)",
  "client.cart_modal.clear_all": "tout effacer",
  "client.cart_modal.item_purchased_description": "Vous avez déjà acheté cet article",
  "client.cart_modal.subtotal_title": "Total",
  "client.cart_modal.subtotal_subtitle": "Des taxes et des frais supplémentaires peuvent s\'appliquer",
  "client.cart_modal.continue_button": "Continuer à magasiner",
  "client.cart_modal.checkout_button": "Aller à la caisse",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Connectez-vous</button> ou <button class=\'xl_auth\' data-xl-route=\'signup\'>inscrivez-vous</button> pour enregistrer les articles dans le panier pour plus tard ou pour voir les articles précédemment enregistrés",
  "client.cart_modal.empty_cart_description": "Votre panier est vide. Allez au magasin et trouvez quelque chose de génial à acheter.",
  "client.cart_modal.empty_cart_open_store_button": "magasin ouvert",
  "client.cart_modal.email_label": "Envoyer la clé d\'activation du jeu à :",
  "client.cart_modal.email_description": "Vous recevrez une clé que vous devez activer pour commencer à jouer.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Appliquer",
  "client.cart_modal.promo_codes.remove": "Supprimer",
  "client.cart_modal.promo_codes.placeholder": "Code promo",
  "client.cart_modal.promo_codes.error_incorrect": "Code promo non valide.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "La limite d\'utilisation du code a été atteinte.",
  "client.cart_modal.promo_codes.error_expired": "Ce code promo a expiré.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Impossible d\'appliquer le code promo. Veuillez réessayer plus tard.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Le code promo n\'est pas valable pour les articles de votre panier.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Sélectionnez une plateforme de jeu pour continuer.",
  "client.cart_modal.promo_codes.select_drm": "Sélectionnez la plateforme",
  "client.cart_modal.promo_codes.tooltip_drm": "Pour changer de plateforme, supprimez le code promo puis appliquez-le de nouveau",
  "client.bundle_modal.group_items": "Articles dans le lot",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronze</h3> ",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Contenu numérique</h3><p>✓ Arts</p><p>✓ Fonds d\'écran</p><p><span style=\'color:rgb(127, 96, 0);\'>× Bandes-son de jeu </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Contenu des coulisses</span></p><p><br></p><h3>3 Coffres à </h3><h3>butin de jeu</h3><p>✓ Peaux de personnages</p><p><span style=\'color:rgb(127, 96, 0);\'>× Effets visuels </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Cartes de scénario</span></p><p><br></p><h3>Avantages du jeu</h3><p>✓ x1 Laissez-passer supplémentaire pour la Salle du Chaos</p><p>✓ x1 renaissance de caractère</p><p><br></p><h3>Discord</h3><p>✓ Accès à la chaîne des joueurs</p><p><span style=\'color:rgb(127, 96, 0);\'>× Accès à la chaîne des développeurs</span></p><p><br></p><h3>Forum</h3><p>✓ Couleur exclusive de surnom</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji exclusif</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Image de profil animée </span></p><p>✓ x2 points supplémentaires pour classer le poste (quotidiennement)</p><p><br></p><h3>Merch</h3><p>✓ Épingles et autocollants (tous les 6 mois)</p><p>✓ Affiche de haute qualité (tous les 6 mois)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Peluches de marque (chaque année)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Figurines de haute qualité (chaque année)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Evénements hors ligne</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Accès gratuit à nos conventions de jeux</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Visite au bureau de la société (chaque année)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ donne accès à Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Argent</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Contenu numérique</h3><p>✓ Arts</p><p>✓ Fonds d\'écran</p><p><span style=\"color:rgb(127, 96, 0);\">× Bandes-son de jeu</span></p><p>✓ Contenu des coulisses</p><p><br></p><h3>6 Coffres à </h3><h3>butin de jeu</h3><p>✓ Peaux de personnages</p><p>✓ Effets visuels</p><p><span style=\"color:rgb(127, 96, 0);\">× Cartes de scénario</span></p><p><br></p><h3>Avantages du jeu</h3><p>✓ x2 Laissez-passer supplémentaire pour la Salle du Chaos</p><p>✓ x2 renaissance de caractère</p><h3><br></h3><h3>Discord</h3><p>✓ Accès à la chaîne des joueurs</p><p><span style=\"color:rgb(127, 96, 0);\">× Accès à la chaîne des développeurs</span></p><p><br></p><h3>Forum</h3><p>✓ Couleur exclusive de surnom</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji exclusif</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Image de profil animée</span></p><p>✓ 5x points supplémentaires pour classer le poste (quotidiennement)</p><p><br></p><h3>Merch</h3><p>✓ Épingles et autocollants (tous les 6 mois)</p><p>✓ Affiche de haute qualité (tous les 6 mois)</p><p><span style=\"color:rgb(127, 96, 0);\">× Peluches de marque (chaque année)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Figurines de haute qualité (chaque année)</span></p><p><br></p><h3>Evénements hors ligne</h3><p>✓ Accès gratuit à nos conventions de jeux</p><p><span style=\"color:rgb(127, 96, 0);\">× Visite au bureau de la société (chaque année)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ donne accès à Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Or</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Contenu numérique</h3><p>✓ Arts</p><p>✓ Fonds d\'écran</p><p>✓ Bandes-son de jeu</p><p>✓ Contenu des coulisses</p><p><br></p><h3>9 Coffres à </h3><h3>butin de jeu</h3><p>✓ Peaux de personnages</p><p>✓ Effets visuels</p><p>✓ Cartes de scénario</p><p><br></p><h3>Avantages du jeu</h3><p>✓ x5 Laissez-passer supplémentaire pour la Salle du Chaos</p><p>✓ x10 renaissance de caractère</p><p><br></p><h3>Discord</h3><p>✓ Accès à la chaîne des joueurs</p><p>✓ Accès à la chaîne des développeurs</p><p><br></p><h3>Forum</h3><p>✓ Couleur exclusive de surnom</p><p>✓ Emoji exclusif</p><p>✓ Image de profil animée</p><p>✓ x12 points supplémentaires pour classer le poste (quotidiennement)</p><p><br></p><h3>Merch</h3><p>✓ Épingles et autocollants (tous les 6 mois)</p><p>✓ Affiche de haute qualité (tous les 6 mois)</p><p>✓ Peluches de marque (chaque année)</p><p>✓ Figurines de haute qualité (chaque année)</p><p><br></p><h3>Evénements hors ligne</h3><p>✓ Accès gratuit à nos conventions de jeux</p><p>✓ Visite au bureau de la société (chaque année)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ donne accès à Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Contenu numérique</h3><p>Arts</p><p>Fonds d\'écran</p><p><span>Bandes-son de jeu </span></p><p><span>Contenu des coulisses</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Coffres à </h3><h3>butin de jeu</h3><p>Peaux de personnages</p><p><span>Effets visuels </span></p><p><span>Cartes de scénario</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Avantages du jeu</h3><p>x1 Laissez-passer supplémentaire pour la Salle du Chaos</p><p>x1 renaissance de caractère</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Accès à la chaîne des joueurs</p><p><span>× Accès à la chaîne des développeurs</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Couleur exclusive de surnom</p><p><span>× Emoji exclusif</span></p><p><span>× Image de profil animée </span></p><p>x2 points supplémentaires pour classer le poste (quotidiennement)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merch</h3><p>Épingles et autocollants (tous les 6 mois)</p><p>Affiche de haute qualité (tous les 6 mois)</p><p><span>Peluches de marque (chaque année)</span></p><p><span>Figurines de haute qualité (chaque année)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Evénements hors ligne</h3><p>✓ Accès gratuit à nos conventions de jeux</p><p>✓ Visite au bureau de la société (chaque année)</p>",
  "client.buy_button.text": "Acheter maintenant",
  "client.buy_button.unavailable": "Indisponible",
  "client.buy_button.pre-order": "Pré-commande",
  "client.buy_button.checkout": "Aller à la caisse",
  "client.store.cart.add_button": "Ajouter au panier",
  "client.store.cart.header_button": "Panier",
  "client.store.no_items": "La boutique est vide pour l\'instant. Revenez plus tard!",
  "client.store.error.gt10_gk": "Pour vous protéger contre la fraude, vous ne pouvez acheter que 10 clés à la fois.",
  "client.store.error.no_keys": "Le jeu est temporairement en rupture de stock et sera bientôt réapprovisionné. N\'hésitez pas à nous contacter pour les mises à jour.",
  "client.store.item.purchased": "Acheté",
  "client.store.item.price_from": "à partir de {price}",
  "client.store.item.show_more": "Montrer plus",
  "client.store.successful_purchase": "Succès. Merci pour votre achat!",
  "client.store.not_enough_vc": "Monnaie virtuelle insuffisante.</br>Veuillez recharger votre compte pour effectuer l\'achat.",
  "client.store.non_consumable_item": "Vous avez déjà acheté cet article auparavant, mais il n\'est plus disponible.",
  "client.store.user_id_input_before_submit_hint": "Les articles seront crédités sur ce compte.",
  "client.store.user_id_input_after_submit_hint": "Vous faites des achats pour :",
  "client.common_error": "Quelque chose a mal tourné. Veuillez réessayer plus tard ou contacter notre équipe d\'intégration à <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Votre solde",
  "client.header.logout": "Se déconnecter",
  "client.retailers.edition.title": "Sélectionnez votre copie",
  "client.retailers.country.title": "Sélectionnez votre pays",
  "client.retailers.platform.title": "Sélectionnez la plate-forme",
  "client.retailers.retailer.title": "Sélectionnez le vendeur",
  "client.retailers.edition.digital": "Numérique",
  "client.retailers.edition.physical": "Physique",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Acheter auprès du développeur",
  "client.retailers.expand_button.collapse": "Dérouler",
  "client.retailers.buy_button.buy": "Acheter maintenant",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Les plus populaires",
  "blocks.retailers.components.retailers.standart.value.subtext": "Disponible maintenant",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Édition Standard</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Clé de jeu, badge exclusif, bande son, carte et pack d\'armure supplémentaire",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>LE TITRE DE VOTRE JEU</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Votre jeu emmène les joueurs dans une grande aventure remplie de détracteurs, de monstres, de chasseurs d\'or et de graves dangers.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Disponible pour",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Entrez votre email",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Inscrivez-vous à notre newsletter pour recevoir les dernières nouvelles et mises à jour",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Bouton de configuration",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Acheter maintenant",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Bouton de configuration",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Bouton de configuration",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Bouton de configuration",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Indisponible",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Bouton de configuration",
  "client.topup.input.errorMessage": "Une erreur est survenue. Assurez-vous que votre identifiant est correct ou réessayez plus tard.",
  "client.topup.input.successMessage": "Vous vous êtes connecté avec l\'ID {userID}. Vous pouvez désormais effectuer des achats sur le site.",
  "client.topup.input.username": "Nom d\'utilisateur : {name}",
  "client.topup.input.id": "ID : {id}",
  "client.topup.input.level": "Niveau : {level}",
  "client.topup.input.user_not_found": "L\'utilisateur avec l\'ID que vous avez spécifié n\'a pas pu être trouvé.<br/>Veuillez réessayer.",
  "client.topup.input.serverError": "Une erreur est survenue.<br>Si vous recevez ce message plus d\'une fois, veuillez {contact_us}.",
  "client.topup.input.serverError.contact_us": "nous contactez",
  "client.topup.authorized_title": "Vous faites des achats pour :",
  "blocks.store.values.custom_title": "<h2>{gameTitle} boutique</h2>"
};

module.exports = localizations;
