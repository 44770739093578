var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "더 보기",
  "client.header.login.button": "로그인",
  "client.header.logout.button": "로그아웃",
  "client.user-id-modal.input-placeholder": "사용자 ID",
  "client.user-id-modal.input-incorrect-id": "잘못된 사용자 ID 입니다.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "게임 ID를 복사하여 붙여넣으세요.",
  "client.user-id-modal.continue-button": "계속",
  "client.user-id-modal.go-back": "돌아가기",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "카카오톡",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "네이버",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "더 보기",
  "client.privacy-settings.open-button": "개인 정보 설정",
  "client.usa-privacy-settings.open-button": "내 데이터를 판매하지 않습니다",
  "client.user-id-modal.continue-with": "{socialName}(으)로 계속하기",
  /* default-data page texts */
  "document.pages.auth.title": "사용자 ID 입력",
  "document.pages.auth.subtitle": "혹은 ID 입력하기",
  "document.pages.auth.placeholder": "예시: 123~728",
  "document.pages.auth.instruction.instructionCutTextTag": "사용자 ID를 찾을 수 없나요?",
  "document.pages.auth.instruction.steps.description": "사용자가 자신의 개인 ID를 찾는 방법을 설명하세요. 여러 단계 및 이미지를 추가할 수 있습니다.",
  "document.pages.seo.title": "새로운 게임이 출시되었습니다!",
  "document.pages.seo.description": "여러분의 게임은 악당과 몬스터, 금사냥꾼 및 치명적인 위험으로 가득 찬 대모험으로 플레이어를 초대합니다.",
  "document.pages.seo.ogTitle": "새로운 게임이 출시되었습니다!",
  "document.pages.seo.ogDescription": "여러분의 게임은 악당과 몬스터, 금사냥꾼 및 치명적인 위험으로 가득 찬 대모험으로 플레이어를 초대합니다.",
  "default-data.templates.google-play.contact-us": "{email}로 문의하기",
  /* default-data topup block texts */
  "blocks.topup.values.title": "인게임 지갑 탑업",
  "blocks.topup.values.description": "사용자 ID 입력",
  "blocks.topup.values.userIdPlaceholder": "예시: 123~728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "사용자 ID를 찾을 수 없나요?",
  "blocks.topup.values.userInstruction.stepTitleTag": "단계 이름",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "사용자가 자신의 개인 ID를 찾는 방법을 설명하세요. 여러 단계 및 이미지를 추가할 수 있습니다.",
  "default-data.templates.topup.app-support": "App 지원",
  "default-data.templates.topup.age-raiting": "나이 등급",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>게임 제목</h1>",
  "blocks.retailers.values.title": "<h2>귀하의 에디션을 선택하세요</h2>",
  "blocks.hero.values.description": "여러분의 게임은 악당과 몬스터, 금사냥꾼 및 치명적인 위험으로 가득 찬 대모험으로 플레이어를 초대합니다.",
  "blocks.hero.values.affiliate": "이 구매로 인한 매출은 제휴사와 공유됩니다.",
  "blocks.hero.components.platforms.label": "이용 가능",
  "blocks.hero.components.subscribe.label": "이메일 입력",
  "blocks.hero.components.subscribe.values.text" : "최신 뉴스 및 업데이트를 받으려면 뉴스레터를 구독하세요.",
  "blocks.hero.components.scroll.label": "스크롤",
  "blocks.footer.values.name": "텍스트 입력",
  "blocks.footer.values.affiliate": "제휴 프로그램 가입",
  "blocks.hero.components.buy.label": "지금 구매하기",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.hero.components.customButton.link.label": "설정 버튼",
  "blocks.header.components.customButton.link.label": "설정 버튼",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.packs.components.pack.customButton.link.label": "설정 버튼",
  "blocks.packs.components.pack.standart.components.buy.label": "지금 구매하기",
  "blocks.packs.components.pack.premium.components.buy.label": "지금 구매하기",
  "blocks.packs.components.pack.ultimate.components.buy.label": "지금 구매하기",
  "blocks.packs.components.pack.platinum.components.buy.label": "지금 구매하기",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.packs.components.pack.standart.value.ribbon": "가장 인기",
  "blocks.packs.components.pack.premium.value.ribbon": "가장 인기",
  "blocks.packs.components.pack.ultimate.value.ribbon": "가장 인기",
  "blocks.packs.components.pack.platinum.value.ribbon": "가장 인기",
  "blocks.description.values.title": "<h2>게임 설명</h2>",
  "blocks.description.components.text.label": "여러분의 게임은 악당과 몬스터, 금사냥꾼 및 치명적인 위험으로 가득 찬 대모험으로 플레이어를 초대합니다. 여러분의 삶을 완전히 뒤바꿀 드라마틱한 일이 벌어졌습니다. 이제 적대적인 새로운 세상에서 생존을 위해 싸워야 합니다. 다양한 현실 차원에서 살아남으며 발견하고 탐험하고 정복하세요!",
  "blocks.faq.values.title": "<h2>FAQ</h2>",
  "blocks.faq.components.question.question": "게임을 플레이하려면 어떻게 해야 하나요?",
  "blocks.faq.components.question.answer": "게임을 플레이하려면 게임을 구매한 후 이메일을 입력해야 합니다. 활성화 키를 받은 후에 키를 제출하면 게임을 즐기실 수 있습니다.",
  "blocks.news.values.title": "<h2>뉴스</h2>",
  "blocks.news.values.description": "새 소식을 보려면 최신 기사를 확인해 보세요.",
  "blocks.store.values.title": "<h2>상점</h2>",
  "blocks.store.buy_button.text": "{amount}에 지금 구매",
  "blocks.embed.values.title": "<h2>소셜 미디어 위젯</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTER 피드</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOK 피드</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "에디션 선택",
  "blocks.packs.values.description": "각 에디션 구매 후에는 나만의 혜택과 이점을 누릴 수 있습니다.",
  "blocks.packs.components.pack.standart.value.subtext": "지금 이용 가능",
  "blocks.packs.components.pack.premium.value.subtext": "지금 이용 가능",
  "blocks.packs.components.pack.ultimate.value.subtext": "지금 이용 가능",
  "blocks.packs.components.pack.platinum.value.subtext": "지금 이용 가능",
  "blocks.packs.components.pack.standart.value.title": "<h3>스탠더드 에디션</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>프리미엄 에디션</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>플래티넘 에디션</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>얼티밋 에디션</h3>",
  "blocks.packs.components.pack.standart.value.description": "게임 키, 특별 배지, 사운드 트랙, 지도, 추가 아머팩",
  "blocks.packs.components.pack.premium.value.description": "게임키, 특별 배지, 사운드 트랙, 지도, 추가 아머팩, 추가 무기팩",
  "blocks.packs.components.pack.platinum.value.description": "게임키, 특별 배지, 사운드 트랙, 지도, 디지털 아트책, 추가 아머팩, 추가 무기팩, 랜덤 스킨팩 한 개",
  "blocks.packs.components.pack.ultimate.value.description": "게임키, 특별 배지, 사운드 트랙, 지도, 디지털 아트책, 디지털 노래책, 추가 아머팩, 추가 무기팩 두 개, 랜덤 스킨팩 세 개",
  "blocks.subscriptions.values.card.title": "프리미엄으로 전환하고 혜택 받기",
  "blocks.subscriptions.values.title": "구독 활성화",
  "blocks.subscriptions.values.heading": "구독",
  "blocks.subscriptions.values.bullets.one.title": "디지털 콘텐츠",
  "blocks.subscriptions.values.bullets.one.description": "이미지, 배경화면, 비하인드 스토리 콘텐츠, 인게임 사운드 트랙",
  "blocks.subscriptions.values.bullets.two.title": "인게임 아이템",
  "blocks.subscriptions.values.bullets.two.description": "캐릭터 스킨, 시각 효과, 스토리 라인 카드",
  "blocks.subscriptions.values.bullets.three.title": "인게임 루트박스 3개",
  "blocks.subscriptions.values.bullets.three.description": "카오스 홀 추가 통행권 5개, 캐릭터 부활 10개",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "다음 갱신",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "지금 갱신하기",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "플랜 관리",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "플랜 선택",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "구독 구성하기",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial}일 동안 무료",
  "editor.subscriptions-packs.button.then.price.per.day": "그리고 하루에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.week": "그리고 1주일에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.month": "그리고 1개월에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.year": "그리고 1년에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "그리고 {periodValue}일에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "그리고 {periodValue}주에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "그리고 {periodValue}개월에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "그리고 {periodValue}년에 {amount}",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "그리고 평생 동안 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "첫 번째 결제의 경우. 이후에는 하루에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "첫 번째 결제의 경우. 이후에는 1주일에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "첫 번째 결제의 경우. 이후에는 1개월에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "첫 번째 결제의 경우. 이후에는 1년에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "첫 번째 결제의 경우. 이후에는 평생 동안 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "첫 번째 결제의 경우, 그리고 {periodValue}일에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "첫 번째 결제의 경우, 그리고 {periodValue}주에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "첫 번째 결제의 경우, 그리고 {periodValue}개월에 {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "첫 번째 결제의 경우, 그리고 {periodValue}년에 {amount}",
  "editor.subscriptions-packs.button.price.per.day": "하루에 {amount}",
  "editor.subscriptions-packs.button.price.per.week": "1주일에 {amount}",
  "editor.subscriptions-packs.button.price.per.month": "1개월에 {amount}",
  "editor.subscriptions-packs.button.price.per.year": "1년에 {amount}",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{periodValue}일에 {amount}",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{periodValue}주에 {amount}",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{periodValue}개월에 {amount}",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{periodValue}년에 {amount}",
  "editor.subscriptions-packs.button.price.per.lifetime": "평생 동안 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "이후에는 {periodsCounts} 동안 하루에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. 그 다음에는 하루에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "이후에는 {periodsCounts} 동안 1주일에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. 그 다음에는 1주일에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "이후에는 {periodsCounts} 동안 1개월에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. 그 다음에는 1개월에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "이후에는 {periodsCounts} 동안 1년에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. 그 다음에는 1년에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "그리고 {periodsCounts}동안 {periodValue}일에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}. 그 이후에 {periodValue}일에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "그리고 {periodsCounts} 동안 {periodValue}주에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}. 그 이후에 {periodValue}주에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "그리고 {periodsCounts} 동안 {periodValue}개월에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}. 그 이후에 {periodValue}개월에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "그리고 {periodsCounts} 동안 {periodValue}년에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}. 그 이후에 {periodValue}년에 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "첫 번째 결제의 경우 {amount}. 이후에는 하루에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "첫 번째 결제의 경우 {amount}. 이후에는 1주일에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "첫 번째 결제의 경우 {amount}. 이후에는 1개월에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "첫 번째 결제의 경우 {amount}. 이후에는 1년에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "그리고 첫 번째 결제의 경우 {amount}, 그 이후에는 {periodValue}일에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "그리고 첫 번째 결제의 경우 {amount}, 그 이후에는 {periodValue}주에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "그리고 첫 번째 결제의 경우 {amount}, 그 이후에는 {periodValue}개월에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "그리고 첫 번째 결제의 경우 {amount}, 그 이후에는 {periodValue}년에 {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "첫 번째 결제의 경우 {amount}. 이후에는 평생 동안 {amountPromo}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "{periodsCounts} 동안. 이후에는 하루에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "{periodsCounts} 동안. 이후에는 1주일에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "{periodsCounts} 동안. 이후에는 1개월에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "{periodsCounts} 동안. 이후에는 1년에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "{periodsCounts}동안, 이후에는 {periodValue}일에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "{periodsCounts}동안, 이후에는 {periodValue}주에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "{periodsCounts}동안, 이후에는 {periodValue}개월에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "{periodsCounts}동안, 이후에는 {periodValue}년에 {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "{periodsCounts} 동안. 이후에는 평생 동안 {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "이후에는 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. {periodEnds}에 시즌 종료",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "이후에는 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}. {periodStarts}에 시즌 시작",
  "editor.subscriptions-packs.button.then.price.season.ends": "이후에는 {amount}. {periodEnds}에 시즌 종료",
  "editor.subscriptions-packs.button.then.price.season.starts": "이후에는 {amount}. {periodStarts}에 시즌 시작",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "이후에는 하루에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "이후에는 1주일에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "이후에는 1개월에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "이후에는 1년에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "이후에는 {periodValue}일에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "이후에는 {periodValue}주에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "이후에는 {periodValue}개월에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "이후에는 {periodValue}년에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "이후에는 평생 동안 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price": "이후에는 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "하루에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "1주일에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "1개월에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "1년에 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "{periodValue}일에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "{periodValue}주에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "{periodValue}개월에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "{periodValue}년에 <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "평생 동안 <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price": "이후에는 {amount}",
  "editor.subscriptions-packs.button.season.ends": "{periodEnds}에 시즌 종료",
  "editor.subscriptions-packs.button.season.starts": "{periodStarts}에 시즌 시작",
  "editor.subscriptions-packs.button.price.period.day": "{amount}/1일",
  "editor.subscriptions-packs.button.price.period.week": "{amount}/1주일",
  "editor.subscriptions-packs.button.price.period.month": "{amount}/1개월",
  "editor.subscriptions-packs.button.price.period.year": "{amount}/1년",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} 일",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} 주",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} 개월",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} 년",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount}/평생",
  "blocks.requirements.values.title": "<h2>시스템 요건</h2>",
  "blocks.requirements.components.platform_req.value.title": "플랫폼",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "그래픽",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "저장 공간",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "프로세서",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "사운드 카드",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "메모리",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "리눅스",
  "default-data.values.requirements.title.minimum": "최소",
  "default-data.values.requirements.title.recommended": "권장",
  "default-data.values.requirements.item.directx.minimum": "버전 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "DirectX9 호환",
  "default-data.values.requirements.item.sound_card.recommended": "DirectX9 호환",
  "default-data.values.requirements.item.directx.recommended": "버전 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2GB RAM",
  "default-data.customization.receipt.title": "여러분, 안녕!",
  "default-data.customization.receipt.description": "구매해주셔서 고맙습니다! 저희 게임을 플레이해주셔서 감사합니다.",
  "default-data.customization.receipt.footer": "© All rights reserved.",
  "blocks.store.components.storeSection.title": "아이템",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "성공적으로 구독했습니다!",
  "notification.hero.components.subscribe.subscription.success": "확인 이메일을 보내 드렸습니다. 구독을 활성화하려면 이메일과 함께 보내드린 링크를 따라주세요.",
  "notification.hero.components.subscribe.error": "문제가 발생했습니다. 나중에 다시 시도하거나 통합팀에 이메일(<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>)로 문의해주세요.",
  "notification.hero.components.subscribe.email.taken.error": "이미 구독하고 있습니다.",
  /* notifications values END */
  "period.years_short": "연",
  "period.months_short": "월",
  "period.weeks_short": "주",
  "period.days_short": "일",
  "subscription.button.text": "{fullCurrency}({periodCount}&thinsp;{periodName})</br>정기결제",
  "client.subscription.button.current.text": "귀하의 구독",
  "client.subscription.button.configuration": "구독 관리",
  "client.subscription.button.lifetime_text": "for {fullCurrency}(으)로 평생 구독",
  "client.thank_you_modal.title": "구독해 주셔서 감사합니다!",
  "client.thank_you_modal.description": "구독은 자동 갱신됩니다. 구독 조건에 따라 결제는 정기적으로 이루어집니다. 이는 언제나 취소 가능합니다.",
  "client.thank_you_modal.input.description": "구독을 하려면 아래에 이름을 입력해주세요.",
  "client.thank_you_modal.button.default.text": "감사합니다",
  "client.thank_you_modal.input.placeholder": "이름",
  "client.thank_you_modal.button.show_list": "전체 구독자 보기",
  "client.subscribers_list_modal.title": "구독자 전체 목록",
  "client.subscribers_list_modal.description": "가입하고 게임을 구독해주신 모든 분들께 진심으로 감사드립니다!",
  "client.subscribers_list_modal.show_more_button": "더 보기",
  "client.subscribers_list_modal.anonymous": "그리고 익명의 사용자들",
  "client.footer.button.list_of_subscribers": "구독자 목록",
  "client.footer.xsollalogo.powered": "제공", // копирайт в футере
  "client.footer.xsollalogo.sb": "엑솔라 웹사이트 빌더",
  "client.empty_subscribers_modal.title": "구독자는 여기에 열거됩니다",
  "client.empty_subscribers_modal.description": "저희 게임의 첫 구독자가 되어주세요.",
  "client.empty_subscribers_modal.button": "지금 정기구독을 선택해주세요",
  "client.news.blurred.description": "이 콘텐츠를 해제하려면 {subscription_name} 구독자가 되세요.",
  "client.news.blurred.button.join": "지금 참여하기",
  "client.cart_modal.title": "장바구니",
  "client.cart_modal.item.pre-order": "(선주문)",
  "client.cart_modal.clear_all": "모두 지우기",
  "client.cart_modal.item_purchased_description": "본 아이템은 이미 구매하였습니다",
  "client.cart_modal.subtotal_title": "합계",
  "client.cart_modal.subtotal_subtitle": "추가로 세금과 수수료가 부과될 수 있습니다",
  "client.cart_modal.continue_button": "계속 쇼핑하기",
  "client.cart_modal.checkout_button": "결제로 이동",
  "client.cart_modal.footer-auth": "나중에 사용할 수 있도록 장바구니에 저장하거나 이전에 저장한 항목을 보려면 <button class=\'xl_auth\' data-xl-route=\'login\'>로그인</button>하거나 <button class=\'xl_auth\' data-xl-route=\'signup\'>가입</button>하세요.",
  "client.cart_modal.empty_cart_description": "장바구니가 비었습니다. 상점으로 이동하여 근사한 상품을 찾아보세요.",
  "client.cart_modal.empty_cart_open_store_button": "상점 열기",
  "client.cart_modal.email_label": "게임 활성화 키 전송 대상:",
  "client.cart_modal.email_description": "플레이를 시작하려면 활성화해야 하는 키를 받게 됩니다.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "보너스",
  "client.cart_modal.promo_codes.apply": "적용",
  "client.cart_modal.promo_codes.remove": "제거",
  "client.cart_modal.promo_codes.placeholder": "프로모션 코드",
  "client.cart_modal.promo_codes.error_incorrect": "잘못된 프로모션 코드.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "코드 사용 한도에 도달했습니다.",
  "client.cart_modal.promo_codes.error_expired": "이 프로모션 코드는 만료되었습니다.",
  "client.cart_modal.promo_codes.error_could_not_apply": "프로모션 코드를 적용할 수 없습니다. 나중에 다시 시도하세요.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "장바구니의 항목에 대한 프로모션 코드가 유효하지 않습니다.",
  "client.cart_modal.promo_codes.error_select_game_platform": "계속하려면 게임 플랫폼을 선택하세요.",
  "client.cart_modal.promo_codes.select_drm": "플랫폼 선택",
  "client.cart_modal.promo_codes.tooltip_drm": "플랫폼을 변경하려면 프로모션 코드를 제거한 다음 다시 적용하세요.",
  "client.bundle_modal.group_items": "번들 항목",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>브론즈</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>디지털 콘텐츠</h3><p>✓ 아트</p><p>✓ 월페이퍼</p><p><span style=\'color:rgb(127, 96, 0);\'>× 인게임 사운드트랙 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 장면 뒤 콘텐츠</span></p><p><br></p><h3>3 인게임</h3><h3>전리품 상자</h3><p>✓ 캐릭터 스킨</p><p><span style=\'color:rgb(127, 96, 0);\'>× 시각 효과 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 줄거리 카드</span></p><p><br></p><h3>인게임 아이템</h3><p>✓ x1 카오스 홀로의 추가 통행권</p><p>✓ x1 캐릭터 부활</p><p><br></p><h3>디스코드</h3><p>✓ 게이머 채널 접근</p><p><span style=\'color:rgb(127, 96, 0);\'>× 개발자 채널 접근</span></p><p><br></p><h3>포럼</h3><p>✓ 독점적인 별명 색상</p><p><span style=\'color:rgb(127, 96, 0);\'>× 독점적 이모지</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 애니메이션 프로필 사진 </span></p><p>✓ x2 게시물 순위 추가 포인트 (매일)</p><p><br></p><h3>상인</h3><p>✓ 핀 &amp; 스티커(매 6개월)</p><p>✓ 고급 포스터 (매 6개월)</p><p><span style=\'color:rgb(127, 96, 0);\'>× 브랜드 봉제 완구 (매년)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 고급 피규어 (매년)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'> 오프라인 이벤트</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× 게임 컨벤션 무료 참가</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 회사 사무실 방문 (매년)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 디스코드 접속 허용</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>실버</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>디지털 콘텐츠</h3><p>✓ 아트</p><p>✓ 월페이퍼</p><p><span style=\"color:rgb(127, 96, 0);\">× 인게임 사운드트랙</span></p><p>✓ 장면 뒤 콘텐츠</p><p><br></p><h3>6 인게임</h3><h3>전리품 상자</h3><p>✓ 캐릭터 스킨</p><p>✓ 시각 효과</p><p><span style=\"color:rgb(127, 96, 0);\">× 줄거리 카드</span></p><p><br></p><h3>인게임 아이템</h3><p>✓ x2 카오스 홀로의 추가 통행권</p><p>✓ x2 캐릭터 부활</p><h3><br></h3><h3>디스코드</h3><p>✓ 게이머 채널 접근</p><p><span style=\"color:rgb(127, 96, 0);\">× 개발자 채널 접근</span></p><p><br></p><h3>포럼</h3><p>✓ 독점적인 별명 색상</p><p><span style=\"color:rgb(127, 96, 0);\">× 독점 이모지</span></p><p><span style=\"color:rgb(127, 96, 0);\">× 애니메이션 프로필 사진</span></p><p>✓ 5x 게시물 순위 추가 포인트 (매일)</p><p><br></p><h3>상인</h3><p>✓ 핀 &amp; 스티커 (매 6개월)</p><p>✓ 고급 포스터 (매 6개월)</p><p><span style=\"color:rgb(127, 96, 0);\">× 브랜드 봉제 인형(매년)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× 고급 피규어(매년)</span></p><p><br></p><h3>오프라인 이벤트</h3><p>✓ 게임 컨벤션 무료 참가</p><p><span style=\"color:rgb(127, 96, 0);\">× 회사 사무실 방문(매년)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 디스코드 접속 허용</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>골드</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>디지털 콘텐츠</h3><p>✓ 아트</p><p>✓ 월페이퍼</p><p>✓ 인게임 사운드트랙</p><p>✓ 장면 뒤 컨텐츠</p><p><br></p><h3>9 인게임</h3><h3>전리품 상자</h3><p>✓ 캐릭터 스킨</p><p>✓ 시각 효과</p><p>✓ 줄거리 카드</p><p><br></p><h3>인게임 아이템</h3><p>✓ x5 카오스 홀 추가 통행권</p><p>✓ x10 캐릭터 부활</p><p><br></p><h3>디스코드</h3><p>✓ 게이머 채널 접근</p><p>✓ 개발자 채널 접근</p><p><br></p><h3>포럼</h3><p>✓ 독점적 별명 색상</p><p>✓ 독점 이모지</p><p>✓ 애니메이션 프로필 사진</p><p>✓ x12 게시물 순위 추가 포인트 (매일)</p><p><br></p><h3>상인</h3><p>✓ 핀 &amp; 스티커 (매 6개월)</p><p>✓ 고급 포스터 (매 6개월)</p><p>✓ 브랜드 봉제 인형(매년)</p><p>✓ 고급 피규어(매년)</p><p><br></p><h3>오프라인 이벤트</h3><p>✓ 게임 컨벤션 무료 참가</p><p>✓ 회사 사무실 방문(매년)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 디스코드 접속 허용</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>디지털 콘텐츠</h3><p>아트</p><p>월페이퍼</p><p><span>인게임 사운드트랙 </span></p><p><span>장면 뒤 컨텐츠</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>인게임</h3><h3>전리품 상자</h3><p>캐릭터 스킨</p><p><span>시각 효과 </span></p><p><span>줄거리 카드</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>인게임 아이템</h3><p>x1 카오스 홀 추가 통행권</p><p>x1 캐릭터 부활</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>디스코드</h3><p>게이머 채널 접근</p><p><span>× 개발자 채널 접근</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>포럼</h3><p>독점적 별명 색상</p><p><span>× 독점 이모지</span></p><p><span>× 애니메이션 프로필 사진 </span></p><p>x2 게시물 순위 추가 포인트 (매일)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>상인</h3><p>핀 &amp; 스티커 (매 6개월)</p><p>고급 포스터 (매 6개월)</p><p><span>브랜드 봉제 인형(매년)</span></p><p><span>고급 피규어(매년)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>오프라인 이벤트</h3><p>✓ 게임 컨벤션에 무료 참가</p><p>✓ 본사 사무실 방문(매년)</p>",
  "client.buy_button.text": "지금 구매하기",
  "client.buy_button.unavailable": "이용 불가",
  "client.buy_button.pre-order": "선주문",
  "client.buy_button.checkout": "결제로 이동",
  "client.store.cart.add_button": "장바구니에 추가",
  "client.store.cart.header_button": "장바구니",
  "client.store.no_items": "지금은 상점에 상품이 없습니다. 나중에 다시 오세요!",
  "client.store.error.gt10_gk": "사기 피해 방지를 위해, 키는 한 번에 10개만 구매할 수 있습니다.",
  "client.store.error.no_keys": "이 게임은 일시 품절 상태이며 곧 재입고됩니다. 최신 정보와 관련하여 편하게 연락해 주십시오.",
  "client.store.item.purchased": "구매 완료",
  "client.store.item.price_from": "{price}부터",
  "client.store.item.show_more": "세부 정보 표시",
  "client.store.successful_purchase": "성공입니다. 구매에 감사드립니다!",
  "client.store.not_enough_vc": "가상 화폐가 부족합니다.</br>구매를 완료하려면 충전해주세요.",
  "client.store.non_consumable_item": "이 아이템을 이미 구매하여서 더 이상은 불가능합니다.",
  "client.store.user_id_input_before_submit_hint": "이 계정으로 아이템 비용이 청구됩니다.",
  "client.store.user_id_input_after_submit_hint": "구매 대상:",
  "client.common_error": "문제가 발생했습니다. 나중에 다시 시도하거나 통합팀에 이메일(<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>)로 문의해주세요.",
  "client.user_account.balance_title": "잔액",
  "client.header.logout": "로그아웃",
  "client.retailers.edition.title": "카피 선택",
  "client.retailers.country.title": "국가 선택",
  "client.retailers.platform.title": "플랫폼 선택",
  "client.retailers.retailer.title": "판매자 선택",
  "client.retailers.edition.digital": "디지털",
  "client.retailers.edition.physical": "실물",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "개발자로부터 구매",
  "client.retailers.expand_button.collapse": "접기",
  "client.retailers.buy_button.buy": "지금 구매하기",
  "blocks.retailers.components.retailers.standart.value.ribbon": "가장 인기",
  "blocks.retailers.components.retailers.standart.value.subtext": "지금 이용 가능",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>스탠더드 에디션</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "게임 키, 특별 배지, 사운드 트랙, 지도, 추가 아머팩",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>게임 제목</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "여러분의 게임은 악당과 몬스터, 금사냥꾼 및 치명적인 위험으로 가득 찬 대모험으로 플레이어를 초대합니다.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "이용 가능",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "이메일 입력",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "최신 뉴스 및 업데이트를 받으려면 뉴스레터를 구독하세요.",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "설정 버튼",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "지금 구매하기",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "설정 버튼",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "설정 버튼",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "설정 버튼",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "이용 불가",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "설정 버튼",
  "client.topup.input.errorMessage": "오류가 발생하였습니다. 사용자 ID를 확인하고 나중에 다시 시도해 주세요.",
  "client.topup.input.successMessage": "{userID} ID로 로그인하였습니다. 이제 사이트에서 구매할 수 있습니다.",
  "client.topup.input.username": "사용자 이름: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "레벨: {level}",
  "client.topup.input.user_not_found": "명시하신 ID의 사용자를 찾을 수 없습니다.<br/>다시 시도해 주세요.",
  "client.topup.input.serverError": "오류가 발생했습니다.<br>한 번 이상 이 메시지를 받으셨다면 {contact_us}에 연락해 주십시오.",
  "client.topup.input.serverError.contact_us": "연락처",
  "client.topup.authorized_title": "구매 대상:",
  "blocks.store.values.custom_title": "<h2>{gameTitle} 스토어</h2>"
};

module.exports = localizations;
