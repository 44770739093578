/** @prettier */
// @flow
import { ScriptsState } from '../../reducers/types/scripts-state';
import { CookieNames, getCountryCode } from '../../utils/cookie';
import { analyticsManager } from '../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../afterBlocks/analytics/analyticsCategories';
import { XsollaMetrikaEventNames } from '../afterBlocks/analytics/analyticsEventsList';
import { UserAccountIdService } from '../blocks/user-account-services/user-account-id-service';
import { xsollaAnalytics } from '../helpers/analytics';
import { parseJwt, setCookie } from '../helpers/common';

export const AFTER_REGISTRATION_FLAG = 'after-registration';
export const AFTER_REGISTRATION_REDIRECT_FLAG = 'after-registration-redirect';

export const isAfterRegistration = () =>
  localStorage.getItem(AFTER_REGISTRATION_FLAG) === '1';
export const isAfterRedirectRegistration = () =>
  localStorage.getItem(AFTER_REGISTRATION_REDIRECT_FLAG) === '1';
export const clearAfterRedirectRegistration = () =>
  localStorage.removeItem(AFTER_REGISTRATION_REDIRECT_FLAG);

export const saveSearchParams = async (data: ScriptsState) => {
  const searchParams = new URLSearchParams(window.location.search);

  const userId = searchParams.get('user-id');
  if (userId) {
    const { projectId, merchantId, auth } = data;
    const userService = UserAccountIdService.getInstance({
      projectId,
      auth,
      merchantId,
    });
    await userService.authUser(userId, getCountryCode(), true);
    xsollaAnalytics((XA) =>
      XA.elementClick(XsollaMetrikaEventNames.IDENTIFICATION_BY_USER_ID, {
        ev: userId,
      })
    );
    searchParams.delete('user-id');
  }

  const xsollaTokenFromUrl = searchParams.get('token');
  if (xsollaTokenFromUrl) {
    const parsedJwt = parseJwt(xsollaTokenFromUrl);
    const isCorrectJwt = parsedJwt && parsedJwt.xsolla_login_access_key;
    if (isCorrectJwt) {
      setCookie(CookieNames.USER_LOGIN_TOKEN, xsollaTokenFromUrl);
      analyticsManager.sendEventAfterListenerSubscribe({
        category: AnalyticsCategories.HEADER,
        event: AnalyticsActions.AUTH_SUCCESS,
        page: `landing_${AnalyticsActions.AUTH_SUCCESS}`,
      });
      localStorage.setItem(AFTER_REGISTRATION_FLAG, '1');
      const tokenPayload =
        parsedJwt && parsedJwt.payload && JSON.parse(parsedJwt.payload);
      if (tokenPayload && tokenPayload.redirectUrl) {
        localStorage.setItem(AFTER_REGISTRATION_REDIRECT_FLAG, '1');
        window.location.href = tokenPayload.redirectUrl;
        return { needRedirect: true };
      }
    }
  } else {
    localStorage.removeItem(AFTER_REGISTRATION_FLAG);
  }

  searchParams.delete('token');
  searchParams.delete('is_new');
  searchParams.delete('remember_me');

  const url =
    searchParams.toString() !== ''
      ? `${document.location.pathname}?${searchParams.toString()}`
      : document.location.pathname;
  const { hash } = window.location;
  window.history.replaceState('', '', url + hash);
  return { needRedirect: false };
};
