"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LandingType = exports.ChangeablePageSettings = exports.AuthorizationType = void 0;
const AuthorizationType = {
  USER_ID: 'user-id',
  LOGIN: 'login'
};
exports.AuthorizationType = AuthorizationType;
const ChangeablePageSettings = {
  AUTH: 'auth',
  SEO: 'seo',
  THEME: 'theme',
  CART: 'cart'
};
exports.ChangeablePageSettings = ChangeablePageSettings;
const LandingType = {
  CFD: 'cfd',
  GPLAY: 'gplay',
  RFP: 'rfppage',
  SELLING_PAGE: 'sellingpage',
  STEAM: 'steam',
  WEBSTORE: 'store',
  TOPUP: 'topup'
};
exports.LandingType = LandingType;