/* eslint-disable camelcase */
import type { UserDataPayload } from './types';

import { getTokenFromCookie } from '../../../utils/cookie';
import { parseJwt } from '../../helpers/common';
import { logOut } from './utils';

export class UserAccountService {
  protected readonly token: string;

  protected readonly tokenData?: UserDataPayload;

  protected readonly projectInfo: {
    projectId: string;
    merchantId: string;
  };

  protected listeners: Array<() => void> = [];

  constructor({ projectId, merchantId }) {
    this.token = getTokenFromCookie() || '';
    if (this.token) {
      this.tokenData = parseJwt(this.token);
    }
    this.projectInfo = {
      projectId,
      merchantId,
    };
  }

  get userToken() {
    return this.isAuthorized ? this.token : '';
  }

  get isAuthorized() {
    return !!this.token;
  }

  get userName() {
    return this.getUserNameOrEmail();
  }

  get picture() {
    return this.tokenData?.picture;
  }

  private getUserNameOrEmail = () => {
    if (!this.tokenData) {
      return '';
    }
    const { name, email, username } = this.tokenData;
    if (username) {
      return username;
    }
    if (name) {
      const [firstName, lastName] = name.split(' ');
      if (firstName && lastName) {
        return `${firstName.charAt(0)}.${lastName}`;
      }
    }
    if (email) {
      return email;
    }
    return '';
  };

  addListener(fn: () => void) {
    this.listeners.push(fn);
  }

  logOut() {
    logOut();
  }

  protected notify() {
    this.listeners.forEach((listener) => listener());
  }
}

export interface IUserAccountServiceProps {
  projectId: string;
  merchantId: string;
}
