var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Daha fazlasını göster",
  "client.header.login.button": "Oturum aç",
  "client.header.logout.button": "Oturumu kapat",
  "client.user-id-modal.input-placeholder": "Kullanıcı kimliği",
  "client.user-id-modal.input-incorrect-id": "Yanlış kullanıcı kimliği.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Kimliğinizi oyundan kopyalayıp yapıştırmayı deneyin.",
  "client.user-id-modal.continue-button": "Devam et",
  "client.user-id-modal.go-back": "Geri dön",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Daha fazlasını göster",
  "client.privacy-settings.open-button": "Gizlilik ayarları",
  "client.usa-privacy-settings.open-button": "Verilerimi satma",
  "client.user-id-modal.continue-with": "{socialName} ile devam edin",
  /* default-data page texts */
  "document.pages.auth.title": "Kullanıcı kimliğinizi girin",
  "document.pages.auth.subtitle": "veya ID girin",
  "document.pages.auth.placeholder": "Örnek: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Kullanıcı kimliğinizi bulamıyor musunuz?",
  "document.pages.auth.instruction.steps.description": "Kullanıcının kişisel kimliğini nasıl bulacağını belirtin. Birden fazla adım ve görsel ekleyebilirsiniz.",
  "document.pages.seo.title": "Yeni Oyun çıktı!",
  "document.pages.seo.description": "Oyunda, oyuncular hilebazlarla, canavarlarla, altın avcılarıyla ve büyük tehlikelerle dolu muazzam bir maceraya atılır.",
  "document.pages.seo.ogTitle": "Yeni Oyun çıktı!",
  "document.pages.seo.ogDescription": "Oyunda, oyuncular hilebazlarla, canavarlarla, altın avcılarıyla ve büyük tehlikelerle dolu muazzam bir maceraya atılır.",
  "default-data.templates.google-play.contact-us": "Bizimle iletişime geçin {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "OYUN İÇİ CÜZDANA PARA YÜKLEME",
  "blocks.topup.values.description": "Kullanıcı Kimliğini Girin",
  "blocks.topup.values.userIdPlaceholder": "Örnek: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "KULLANICI KİMLİĞİNİZİ BULAMIYOR MUSUNUZ?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Adım adı",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Kullanıcının kişisel kimliğini nasıl bulacağını belirtin. Birden fazla adım ve görsel ekleyebilirsiniz.",
  "default-data.templates.topup.app-support": "Uygulama desteği",
  "default-data.templates.topup.age-raiting": "Yaş derecelendirmesi",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>OYUNUNUZUN ADI</h1>",
  "blocks.retailers.values.title": "<h2>Sürümünüzü seçin</h2>",
  "blocks.hero.values.description": "Oyunda, oyuncular hilebazlarla, canavarlarla, altın avcılarıyla ve büyük tehlikelerle dolu muazzam bir maceraya atılır.",
  "blocks.hero.values.affiliate": "Bu satın almadan elde edilen gelir satış ortağınız ile paylaşılacaktır",
  "blocks.hero.components.platforms.label": "Şunun için kullanılabilir",
  "blocks.hero.components.subscribe.label": "E-posta adresini gir",
  "blocks.hero.components.subscribe.values.text" : "En yeni haberleri ve güncellemeleri almak için bültenimize abone olun",
  "blocks.hero.components.scroll.label": "Kaydır",
  "blocks.footer.values.name": "Metin gir",
  "blocks.footer.values.affiliate": "Satış ortağı programına katılın",
  "blocks.hero.components.buy.label": "Şimdi satın al",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.hero.components.customButton.link.label": "Düğmeyi ayarlayın",
  "blocks.header.components.customButton.link.label": "Düğmeyi ayarlayın",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.packs.components.pack.customButton.link.label": "Düğmeyi ayarlayın",
  "blocks.packs.components.pack.standart.components.buy.label": "Şimdi satın al",
  "blocks.packs.components.pack.premium.components.buy.label": "Şimdi satın al",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Şimdi satın al",
  "blocks.packs.components.pack.platinum.components.buy.label": "Şimdi satın al",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.packs.components.pack.standart.value.ribbon": "En popüler",
  "blocks.packs.components.pack.premium.value.ribbon": "En popüler",
  "blocks.packs.components.pack.ultimate.value.ribbon": "En popüler",
  "blocks.packs.components.pack.platinum.value.ribbon": "En popüler",
  "blocks.description.values.title": "<h2>OYUNUN TANIMI</h2>",
  "blocks.description.components.text.label": "Oyununuzda, oyuncular hilebazlarla, canavarlarla, altın avcılarıyla ve büyük tehlikelerle dolu muazzam bir maceraya atılır. Hayatınızı altüst eden dramatik olaylar yaşandı ve size düşman bu yeni dünyada hayatta kalmak için savaşmalısın. Gerçekliğin farklı boyutlarından geçerken araştır, keşfet ve fethet!",
  "blocks.faq.values.title": "<h2>SSS</h2>",
  "blocks.faq.components.question.question": "Your Game\'i nasıl oynayabilirim?",
  "blocks.faq.components.question.answer": "Your Game\'i oynamak için oyunu satın almanız ve bir e-posta adresi belirtmeniz gerekir. Etkinleştirme kodunu aldıktan sonra bunu göndererek oyunun keyfini çıkarabilirsiniz.",
  "blocks.news.values.title": "<h2>Haberler</h2>",
  "blocks.news.values.description": "Yenilikleri görmek için son makalelerimize göz atın.",
  "blocks.store.values.title": "<h2>Mağaza</h2>",
  "blocks.store.buy_button.text": "Şimdi satın al {amount}",
  "blocks.embed.values.title": "<h2>Sosyal medya widget\'ları</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTER AKIŞI</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOK AKIŞI</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Sürümünüzü seçin",
  "blocks.packs.values.description": "Her sürüm, satın aldıktan sonra elde edeceğiniz özel avantajlar içerir.",
  "blocks.packs.components.pack.standart.value.subtext": "Şimdi kullanılabilir",
  "blocks.packs.components.pack.premium.value.subtext": "Şimdi kullanılabilir",
  "blocks.packs.components.pack.ultimate.value.subtext": "Şimdi kullanılabilir",
  "blocks.packs.components.pack.platinum.value.subtext": "Şimdi kullanılabilir",
  "blocks.packs.components.pack.standart.value.title": "<h3>Standart Sürüm</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Premium Sürüm</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Platin Sürüm</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ultimate Sürüm</h3>",
  "blocks.packs.components.pack.standart.value.description": "Oyun anahtarı, özel rozet, film müziği, harita ve ek zırh paketi",
  "blocks.packs.components.pack.premium.value.description": "Oyun anahtarı, özel rozet, film müziği, harita, ek zırh paketi ve ek silah paketi",
  "blocks.packs.components.pack.platinum.value.description": "Oyun anahtarı, özel rozet, film müziği, harita, dijital resim kitabı, ek zırh paketi, ek silah paketi, bir random skin paketi",
  "blocks.packs.components.pack.ultimate.value.description": "Oyun anahtarı, özel rozet, film müziği, harita, dijital resim kitabı, dijital müzik kitabı, ek zırh paketi, ek iki silah paketi ve üç random skin paketi ",
  "blocks.subscriptions.values.card.title": "Premium\'a geçin ve avantajlardan yararlanın",
  "blocks.subscriptions.values.title": "Aboneliğinizi etkinleştirin",
  "blocks.subscriptions.values.heading": "Abonelikler",
  "blocks.subscriptions.values.bullets.one.title": "Dijital içerikler",
  "blocks.subscriptions.values.bullets.one.description": "Resimler, duvar kağıdı, kamera arkası içerikleri, oyun içi müzikler",
  "blocks.subscriptions.values.bullets.two.title": "Oyun içi avantajlar",
  "blocks.subscriptions.values.bullets.two.description": "Karakter görünümleri, görsel efektler, hikaye kartları",
  "blocks.subscriptions.values.bullets.three.title": "3 adet oyun içi ganimet kutusu",
  "blocks.subscriptions.values.bullets.three.description": "Kaos Salonuna 5 ekstra geçiş, 10 adet karakter yeniden doğuşu",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Bir sonraki yenileme",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Şimdi yenile",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Paketi yönet",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Paketinizi seçin:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Aboneliği yapılandır",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} gün boyunca ücretsiz",
  "editor.subscriptions-packs.button.then.price.per.day": "Ardından günde {amount}",
  "editor.subscriptions-packs.button.then.price.per.week": "Ardından haftada {amount}",
  "editor.subscriptions-packs.button.then.price.per.month": "Ardından ayda {amount}",
  "editor.subscriptions-packs.button.then.price.per.year": "Ardından yılda {amount}",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Ardından {periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Ardından {periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Ardından {periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Ardından {periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Ardından {amount} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "ilk ödeme olarak, ardından günde {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "ilk ödeme olarak, ardından haftada {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "ilk ödeme olarak, ardından ayda {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "ilk ödeme olarak, ardından yılda {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "ilk ödeme olarak, ardından {amount} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "ilk ödeme olarak, ardından {periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "ilk ödeme olarak, ardından {periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "ilk ödeme olarak, ardından {periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "ilk ödeme olarak, ardından {periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.price.per.day": "Günde {amount}",
  "editor.subscriptions-packs.button.price.per.week": "Haftada {amount}",
  "editor.subscriptions-packs.button.price.per.month": "Ayda {amount}",
  "editor.subscriptions-packs.button.price.per.year": "Yılda {amount}",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Ardından {periodsCounts} gün boyunca <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından günde {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Ardından {periodsCounts} hafta boyunca <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından haftada {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Ardından {periodsCounts} ay boyunca <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından ayda {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Ardından {periodsCounts} yıl boyunca <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından yılda {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Ardından {periodsCounts} gün boyunca {periodValue} gün için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından {periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Ardından {periodsCounts} hafta boyunca {periodValue} hafta için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından {periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Ardından {periodsCounts} ay boyunca {periodValue} ay için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından {periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Ardından {periodsCounts} yıl boyunca {periodValue} yıl için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, ardından {periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Ardından ilk ödeme olarak {amount}, ardından günde {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Ardından ilk ödeme olarak {amount}, ardından haftada {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Ardından ilk ödeme olarak {amount}, ardından ayda {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Ardından ilk ödeme olarak {amount}, ardından yılda {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Ardından ilk ödeme olarak {amount}, ardından {periodValue} gün için {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Ardından ilk ödeme olarak {amount}, ardından {periodValue} hafta için {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Ardından ilk ödeme olarak {amount}, ardından {periodValue} ay için {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Ardından ilk ödeme olarak {amount}, ardından {periodValue} yıl için {amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Ardından ilk ödeme olarak {amount}, ardından {amountPromo} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "{periodsCounts} gün için, ardından günde {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "{periodsCounts} hafta için, ardından haftada {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "{periodsCounts} ay için, ardından ayda {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "{periodsCounts} yıl için, ardından yılda {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "{periodsCounts} gün için, ardından {periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "{periodsCounts} hafta için, ardından {periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "{periodsCounts} ay için, ardından {periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "{periodsCounts} yıl için, ardından {periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Ardından <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezon {periodEnds} tarihinde sona ermektedir",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Ardından <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, sezon {periodStarts} tarihinde başlamaktadır",
  "editor.subscriptions-packs.button.then.price.season.ends": "Ardından {amount}, sezon {periodEnds} tarihinde sona ermektedir",
  "editor.subscriptions-packs.button.then.price.season.starts": "Ardından {amount}, sezon {periodStarts} tarihinde başlamaktadır",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Ardından günde <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Ardından haftada <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Ardından ayda <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Ardından yılda <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Ardından {periodValue} gün için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Ardından {periodValue} hafta için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Ardından {periodValue} ay için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Ardından {periodValue} yıl için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Ardından <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.then.price.promo.price": "Ardından <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "Günde <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "Haftada <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "Ayda <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "Yılda <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "{periodValue} gün için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "{periodValue} hafta için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "{periodValue} ay için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "{periodValue} yıl için <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} tutarında tek ödemeyle ömür boyu",
  "editor.subscriptions-packs.button.then.price": "Ardından {amount}",
  "editor.subscriptions-packs.button.season.ends": "Sezon {periodEnds} tarihinde sona ermektedir",
  "editor.subscriptions-packs.button.season.starts": "Sezon {periodStarts} tarihinde başlamaktadır",
  "editor.subscriptions-packs.button.price.period.day": "Günlük {amount}",
  "editor.subscriptions-packs.button.price.period.week": "Haftalık {amount}",
  "editor.subscriptions-packs.button.price.period.month": "Aylık {amount}",
  "editor.subscriptions-packs.button.price.period.year": "Yıllık {amount}",
  "editor.subscriptions-packs.button.price.period.value.day": "{periodValue} gün için {amount}",
  "editor.subscriptions-packs.button.price.period.value.week": "{periodValue} hafta için {amount}",
  "editor.subscriptions-packs.button.price.period.value.month": "{periodValue} ay için {amount}",
  "editor.subscriptions-packs.button.price.period.value.year": "{periodValue} yıl için {amount}",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} tutarında tek ödemeyle ömür boyu",
  "blocks.requirements.values.title": "<h2>Sistem gereksinimleri</h2>",
  "blocks.requirements.components.platform_req.value.title": "Platform",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Grafik",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "OS",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Depolama",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "İşlemci",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Ses kartı",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Hafıza",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimum",
  "default-data.values.requirements.title.recommended": "Önerilen",
  "default-data.values.requirements.item.directx.minimum": "Versiyon 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "DirectX9 Compatible",
  "default-data.values.requirements.item.sound_card.recommended": "DirectX9 Compatible",
  "default-data.values.requirements.item.directx.recommended": "Versiyon 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Merhaba!",
  "default-data.customization.receipt.description": "Oyunumuzu satın aldığın ve oynadığın için teşekkürler!",
  "default-data.customization.receipt.footer": "© Tüm hakları saklıdır.",
  "blocks.store.components.storeSection.title": "Öğeler",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Başarıyla abone oldunuz!",
  "notification.hero.components.subscribe.subscription.success": "Size bir doğrulama e-postası gönderdik. Lütfen aboneliğinizi etkinleştirmek için e-postadaki bağlantıyı izleyin.",
  "notification.hero.components.subscribe.error": "Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin veya <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a> adresinden entegrasyon ekibimizle iletişim geçin.",
  "notification.hero.components.subscribe.email.taken.error": "Zaten abonesiniz.",
  /* notifications values END */
  "period.years_short": "yıl",
  "period.months_short": "ay",
  "period.weeks_short": "hafta",
  "period.days_short": "gün",
  "subscription.button.text": "{periodCount}&thinsp;{periodName} için</br>{fullCurrency} ile abone olun",
  "client.subscription.button.current.text": "Aboneliğiniz",
  "client.subscription.button.configuration": "Aboneliğinizi yönetin",
  "client.subscription.button.lifetime_text": "Ömür boyu abone olun</br>Sadece {fullCurrency}",
  "client.thank_you_modal.title": "Abone olduğunuz için teşekkürler!",
  "client.thank_you_modal.description": "Aboneliğiniz otomatik olarak yenilenir. Ödeme, abonelik koşullarınıza bağlı olarak düzenli şekilde tahsil edilir. Aboneliğinizi dilediğiniz zaman iptal edebilirsiniz.",
  "client.thank_you_modal.input.description": "Abonelerimizin listesi içinde adınızı görmek istiyorsanız lütfen adınızı aşağıya yazın.",
  "client.thank_you_modal.button.default.text": "Teşekkürler, kapat",
  "client.thank_you_modal.input.placeholder": "Adınız",
  "client.thank_you_modal.button.show_list": "Tüm aboneleri göster",
  "client.subscribers_list_modal.title": "Tüm abonelerin listesi",
  "client.subscribers_list_modal.description": "Bize katılıp oyuna abone olan tüm kullanıcımıza çok teşekkür ederiz!",
  "client.subscribers_list_modal.show_more_button": "Daha fazlasını göster",
  "client.subscribers_list_modal.anonymous": "ve anonim kullanıcılar",
  "client.footer.button.list_of_subscribers": "Abonelerin şistesi",
  "client.footer.xsollalogo.powered": "Şunun tarafından desteklenmektedir:", // копирайт в футере
  "client.footer.xsollalogo.sb": "Xsolla Site Kurucusu",
  "client.empty_subscribers_modal.title": "Abonelerin listesi burada gösterilir",
  "client.empty_subscribers_modal.description": "Oyunumuza ilk abone olan siz olun.",
  "client.empty_subscribers_modal.button": "Şimdi aboneliği seçin",
  "client.news.blurred.description": "Bu içeriği açmak için {subscription_name} abonesi olun.",
  "client.news.blurred.button.join": "Hemen katılın",
  "client.cart_modal.title": "Sepet",
  "client.cart_modal.item.pre-order": "(ön sipariş)",
  "client.cart_modal.clear_all": "tümünü temizle",
  "client.cart_modal.item_purchased_description": "Bunu zaten satın aldınız",
  "client.cart_modal.subtotal_title": "Toplam",
  "client.cart_modal.subtotal_subtitle": "Ek vergi ve ücretler geçerli olabilir",
  "client.cart_modal.continue_button": "alışverişe devam et",
  "client.cart_modal.checkout_button": "ödeme adımına git",
  "client.cart_modal.footer-auth": "Ürünleri sonrası için sepete kaydetmek veya önceden kaydedilen ürünleri görmek için <button class=\'xl_auth\' data-xl-route=\'login\'>Giriş yapın</button> veya <button class=\'xl_auth\' data-xl-route=\'signup\'>kaydolun</button>",
  "client.cart_modal.empty_cart_description": "Sepetiniz boş. Mağazada satın alacak harika şeyler bulabilirsiniz.",
  "client.cart_modal.empty_cart_open_store_button": "mağazayı aç",
  "client.cart_modal.email_label": "Oyun aktivasyon anahtarını şuraya gönder:",
  "client.cart_modal.email_description": "Oynamaya başlayabilmek için bir aktivasyon anahtarı alacaksınız.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Uygula",
  "client.cart_modal.promo_codes.remove": "Kaldır",
  "client.cart_modal.promo_codes.placeholder": "Promosyon kodu",
  "client.cart_modal.promo_codes.error_incorrect": "Hatalı promosyon kodu.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Kod kullanım sınırına ulaşıldı.",
  "client.cart_modal.promo_codes.error_expired": "Bu promosyon kodunun geçerlilik süresi dolmuş.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Promosyon kodu uygulanamadı. Lütfen daha sonra tekrar deneyin.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Promosyon kodu, sepetinizdeki ürünler için geçerli değildir.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Devam etmek için bir oyun platformu seçin.",
  "client.cart_modal.promo_codes.select_drm": "Platform seçin",
  "client.cart_modal.promo_codes.tooltip_drm": "Platformu değiştirmek için promosyon kodunu kaldırın, ardından tekrar uygulayın",
  "client.bundle_modal.group_items": "Paketteki eşyalar",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronz</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Dijital içerikler</h3><p>✓ Sanat</p><p>✓ Duvar kağıtları</p><p><span style=\'color:rgb(127, 96, 0);\'>× Oyun içi film müzikleri </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Kamera arkası içerikleri</span></p><p><br></p><h3>Oyun içi</h3><h3>yağma kutuları</h3><p>✓ Karakter görünümleri</p><p><span style=\'color:rgb(127, 96, 0);\'>× Görsel efektler </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Olay örgüsü kartları</span></p><p><br></p><h3>Oyun içi avantajlar</h3><p>✓ Kaos Salonuna x1 ekstra geçiş</p><p>✓ x1 yeniden karakter doğuşu</p><p><br></p><h3>Discord</h3><p>✓ Oyuncuların kanalına erişim</p><p><span style=\'color:rgb(127, 96, 0);\'>× Geliştiricilerin kanalına erişim</span></p><p><br></p><h3>Forum</h3><p>✓ Özel takma ad rengi</p><p><span style=\'color:rgb(127, 96, 0);\'>× Özel emoji</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Animasyonlu profil resmi </span></p><p>✓ Gönderim puanlama için x2 ekstra puan (günlük)</p><p><br></p><h3>Oyun ürünleri</h3><p>✓ Rozetler ve yükseltici; etiketler (6 ayda bir)</p><p>✓ Yüksek kaliteli poster (6 ayda bir)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Markalı pelüş oyuncaklar (her yıl)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Yüksek kaliteli figürinler (her yıl)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Çevrimndışı etkinlikler</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Oyun konferanslarımıza ücretsiz erişim</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Şirket ofisini ziyaret etme olanağı (her yıl)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Discord\'a erişim sağlar</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Gümüş</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Dijital içerikler</h3><p>✓ Sanat</p><p>✓ Duvar kağıtları</p><p><span style=\'color:rgb(127, 96, 0);\'>× Oyun içi film müzikleri</span></p><p>✓ Kamera arkası içerikleri</p><p><br></p><h3>Oyun içi</h3><h3>yağma kutuları</h3><p>✓ Karakter görünümleri</p><p>✓ Görsel efektler</p><p><span style=\'color:rgb(127, 96, 0);\'>× Olay örgüsü kartları</span></p><p><br></p><h3>Oyun içi avantajlar</h3><p>✓ Kaos Salonuna x2 ekstra geçiş</p><p>✓ x2 yeniden karakter doğuşu</p><h3><br></h3><h3>Discord</h3><p>✓ Oyuncuların kanalına erişim</p><p><span style=\'color:rgb(127, 96, 0);\'>× Geliştiricilerin kanalına erişim</span></p><p><br></p><h3>Forum</h3><p>✓ Özel takma ad rengi</p><p><span style=\'color:rgb(127, 96, 0);\'>× Özel emoji</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Animasyonlu profil resmi</span></p><p>✓ Gönderim puanlama için x5 ekstra puan (günlük)</p><p><br></p><h3>Oyun ürünleri</h3><p>✓ Rozetler ve yükseltici; etiketler (6 ayda bir)</p><p>✓ Yüksek kaliteli poster (6 ayda bir)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Markalı pelüş oyuncaklar (her yıl)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Yüksek kaliteli figürinler (her yıl)</span></p><p><br></p><h3>Çevrimndışı etkinlikler</h3><p>✓ Oyun konferanslarımıza ücretsiz erişim</p><p><span style=\'color:rgb(127, 96, 0);\'>× Şirket ofisini ziyaret etme olanağı (her yıl)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Discord\'a erişim sağlar</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Altın</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Dijital içerikler</h3><p>✓ Sanat</p><p>✓ Duvar kağıtları</p><p>✓ Oyun içi film müzikleri</p><p>✓ Kamera arkası içerikleri</p><p><br></p><h3>Oyun içi</h3><h3>yağma kutuları</h3><p>✓ Karakter görünümleri</p><p>✓ Görsel efektler</p><p>✓ Olay örgüsü kartları</p><p><br></p><h3>Oyun içi avantajlar</h3><p>✓ Kaos Salonuna x5 ekstra geçiş</p><p>✓ x10 yeniden karakter doğuşu</p><p><br></p><h3>Discord</h3><p>✓ Oyuncuların kanalına erişim</p><p>✓ Geliştiricilerin kanalına erişim</p><p><br></p><h3>Forum</h3><p>✓ Özel takma ad rengi</p><p>✓ Özel emoji</p><p>✓ Animasyonlu profil resmi</p><p>✓ Gönderim puanlama için x12 ekstra puan (günlük)</p><p><br></p><h3>Oyun ürünleri</h3><p>✓ Rozetler ve yükseltici; etiketler (6 ayda bir)</p><p>✓ Yüksek kaliteli poster (6 ayda bir)</p><p>✓ Markalı pelüş oyuncaklar (her yıl)</p><p>✓ Yüksek kaliteli figürinler (her yıl)</p><p><br></p><h3>Çevrimndışı etkinlikler</h3><p>✓ Oyun konferanslarımıza ücretsiz erişim</p><p>✓ Şirket ofisini ziyaret etme olanağı (her yıl)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Discord\'a erişim sağlar</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Dijital içerikler</h3><p>Sanat</p><p>Duvar kağıtları</p><p><span>Oyun içi film müzikleri </span></p><p><span>Kamera arkası içerikleri</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Oyun içi</h3><h3>yağma kutuları</h3><p>Karakter görünümleri</p><p><span>Görsel efektler </span></p><p><span>Olay örgüsü kartları</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Oyun içi avantajlar</h3><p>Kaos Salonuna x1 ekstra geçiş</p><p>x1 yeniden karakter doğuşu</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Oyuncuların kanalına erişim</p><p><span>× Geliştiricilerin kanalına erişim</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Özel takma ad rengi</p><p><span>× Özel emoji</span></p><p><span>× Animasyonlu profil resmi </span></p><p>Gönderim puanlama için x2 ekstra puan (günlük)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Oyun ürünleri</h3><p>Rozetler ve yükseltici; etiketler (6 ayda bir)</p><p>Yüksek kaliteli poster (6 ayda bir)</p><p><span>Markalı pelüş oyuncaklar (her yıl)</span></p><p><span>Yüksek kaliteli figürinler (her yıl)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Çevrimndışı etkinlikler</h3><p>✓ Oyun konferanslarımıza ücretsiz erişim</p><p>✓ Şirket ofisini ziyaret etme olanağı (her yıl)</p>",
  "client.buy_button.text": "Şimdi satın al",
  "client.buy_button.unavailable": "Müsait değil",
  "client.buy_button.pre-order": "Ön sipariş",
  "client.buy_button.checkout": "Ödeme adımına git",
  "client.store.cart.add_button": "Sepete ekle",
  "client.store.cart.header_button": "Sepet",
  "client.store.no_items": "Mağazada şu an için bir şey yok. Daha sonra tekrar uğrayın!",
  "client.store.error.gt10_gk": "Sizi dolandırıcılıktan koruyabilmemiz için bir seferde yalnızca 10 anahtar satın alabilirsiniz.",
  "client.store.error.no_keys": "Oyun geçici olarak stoklarda bulunmamaktadır ve yakında tekrar stoklara gelecektir. Güncellemeler için bizimle iletişime geçmekten çekinmeyin.",
  "client.store.item.purchased": "Satın alınan",
  "client.store.item.price_from": "En düşük fiyat: {price}",
  "client.store.item.show_more": "Detayları göster",
  "client.store.successful_purchase": "Başarılı. Satın aldığınız için teşekkürler!",
  "client.store.not_enough_vc": "Yeterli sanal para yok.</br>Satın alma işlemini gerçekleştirmek için lütfen sanal para miktarını artırın.",
  "client.store.non_consumable_item": "Bu öğeyi zaten daha önce satın aldınız ve öğe artık kullanılamaz.",
  "client.store.user_id_input_before_submit_hint": "Öğeler bu hesaba ücretlendirilecektir.",
  "client.store.user_id_input_after_submit_hint": "Şunun için satın alıyorsunuz:",
  "client.common_error": "Bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin veya <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a> adresinden entegrasyon ekibimizle iletişim geçin.",
  "client.user_account.balance_title": "Bakiyeniz",
  "client.header.logout": "Oturumu kapat",
  "client.retailers.edition.title": "Kopyanızı seçin",
  "client.retailers.country.title": "Ülkenizi seçin",
  "client.retailers.platform.title": "Platformu seçin",
  "client.retailers.retailer.title": "Satıcıyı seçin",
  "client.retailers.edition.digital": "Dijital",
  "client.retailers.edition.physical": "Fiziki",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Geliştiriciden satın al",
  "client.retailers.expand_button.collapse": "Kapat",
  "client.retailers.buy_button.buy": "Şimdi satın al",
  "blocks.retailers.components.retailers.standart.value.ribbon": "En popüler",
  "blocks.retailers.components.retailers.standart.value.subtext": "Şimdi kullanılabilir",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Standart Sürüm</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Oyun anahtarı, özel rozet, film müziği, harita ve ek zırh paketi",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>OYUNUNUZUN ADI</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Oyunda, oyuncular hilebazlarla, canavarlarla, altın avcılarıyla ve büyük tehlikelerle dolu muazzam bir maceraya atılır.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Şunun için kullanılabilir",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "E-posta adresini gir",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "En yeni haberleri ve güncellemeleri almak için bültenimize abone olun",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Düğmeyi ayarlayın",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Şimdi satın al",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Düğmeyi ayarlayın",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Düğmeyi ayarlayın",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Düğmeyi ayarlayın",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Müsait değil",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Düğmeyi ayarlayın",
  "client.topup.input.errorMessage": "Bir sorun oluştu. Kullanıcı kimliğinizin doğru olduğundan emin olun veya tekrar deneyin.",
  "client.topup.input.successMessage": "{userID} kimliği ile oturum açtınız. Artık site üzerinden satın alma işlemleri yapabilirsiniz.",
  "client.topup.input.username": "Kullanıcı adı: {name}",
  "client.topup.input.id": "Kimlik: {id}",
  "client.topup.input.level": "Seviye: {level}",
  "client.topup.input.user_not_found": "Belirttiğiniz kimliğe sahip bir kullanıcı bulamadık.<br/>Lütfen tekrar deneyin.",
  "client.topup.input.serverError": "Bir sorun oluştu.<br>Bu mesajı birden çok kez aldıysanız lütfen {contact_us}.",
  "client.topup.input.serverError.contact_us": "bizimle iletişime geçin",
  "client.topup.authorized_title": "Şunun için satın alıyorsunuz:",
  "blocks.store.values.custom_title": "<h2>{gameTitle} mağazası</h2>"
};

module.exports = localizations;
