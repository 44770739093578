/** @prettier */
import React from 'react';

import './style.pcss';

type AddingToCartNotificationProps = {
  name: string,
  img: string,
  text: string,
};

export const AddingToCartNotification = ({
  img,
  name,
  text,
}: AddingToCartNotificationProps) => {
  return (
    <div className='exp-notification'>
      <div
        className='exp-notification__image'
        style={{ backgroundImage: img }}
      />
      <span className='exp-notification__title'>
        {name}
        <br /> {text}
      </span>
    </div>
  );
};
