var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Hiện thêm",
  "client.header.login.button": "Đăng nhập",
  "client.header.logout.button": "Đăng xuất",
  "client.user-id-modal.input-placeholder": "Mã người dùng",
  "client.user-id-modal.input-incorrect-id": "Mã người dùng không đúng.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Hãy thử sao chép và sử dụng mã của bạn từ trò chơi.",
  "client.user-id-modal.continue-button": "Tiếp tục",
  "client.user-id-modal.go-back": "Trở lại",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Hiện thêm",
  "client.privacy-settings.open-button": "Thiết lập bảo mật",
  "client.usa-privacy-settings.open-button": "Không được bán dữ liệu của tôi",
  "client.user-id-modal.continue-with": "Tiếp tục với {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Nhập mã người dùng",
  "document.pages.auth.subtitle": "hoặc nhập ID",
  "document.pages.auth.placeholder": "Ví dụ: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Không tìm thấy mã người dùng?",
  "document.pages.auth.instruction.steps.description": "Mô tả cách người dùng có thể tìm thấy mã cá nhân. Bạn có thể thêm một số bước và hình ảnh.",
  "document.pages.seo.title": "Trò chơi mới đã ra mắt!",
  "document.pages.seo.description": "Trò chơi của bạn đưa người chơi tham gia một cuộc phiêu lưu đầy cam go với những quái vật, những kẻ săn vàng và bao hiểm nguy rình rập.",
  "document.pages.seo.ogTitle": "Trò chơi mới đã ra mắt!",
  "document.pages.seo.ogDescription": "Trò chơi của bạn đưa người chơi tham gia một cuộc phiêu lưu đầy cam go với những quái vật, những kẻ săn vàng và bao hiểm nguy rình rập.",
  "default-data.templates.google-play.contact-us": "Liên hệ với chúng tôi {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "NẠP TIỀN VÀO VÍ TRONG TRÒ CHƠI",
  "blocks.topup.values.description": "Điền mã người dùng",
  "blocks.topup.values.userIdPlaceholder": "Ví dụ: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "KHÔNG TÌM THẤY MÃ NGƯỜI DÙNG?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Tên bước",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Mô tả cách người dùng có thể tìm thấy mã cá nhân. Bạn có thể thêm một số bước và hình ảnh.",
  "default-data.templates.topup.app-support": "Hỗ trợ ứng dụng",
  "default-data.templates.topup.age-raiting": "Xếp hạng độ tuổi",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TỰA TRÒ CHƠI CỦA BẠN</h1>",
  "blocks.retailers.values.title": "<h2>Chọn phiên bản</h2>",
  "blocks.hero.values.description": "Trò chơi của bạn đưa người chơi tham gia một cuộc phiêu lưu đầy cam go với những quái vật, những kẻ săn vàng và bao hiểm nguy rình rập.",
  "blocks.hero.values.affiliate": "Doanh thu từ giao dịch này sẽ được chia sẻ với bên liên kết của bạn",
  "blocks.hero.components.platforms.label": "Hiện có trên",
  "blocks.hero.components.subscribe.label": "Nhập địa chỉ email của bạn",
  "blocks.hero.components.subscribe.values.text" : "Đăng ký nhận tin từ chúng tôi để biết được những thông tin và cập nhật mới nhất",
  "blocks.hero.components.scroll.label": "Cuộn",
  "blocks.footer.values.name": "Nhập nội dung của bạn",
  "blocks.footer.values.affiliate": "Tham gia chương trình liên kết",
  "blocks.hero.components.buy.label": "Mua ngay",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.hero.components.customButton.link.label": "Nút thiết lập",
  "blocks.header.components.customButton.link.label": "Nút thiết lập",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.packs.components.pack.customButton.link.label": "Nút thiết lập",
  "blocks.packs.components.pack.standart.components.buy.label": "Mua ngay",
  "blocks.packs.components.pack.premium.components.buy.label": "Mua ngay",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Mua ngay",
  "blocks.packs.components.pack.platinum.components.buy.label": "Mua ngay",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.packs.components.pack.standart.value.ribbon": "Phổ biến nhất",
  "blocks.packs.components.pack.premium.value.ribbon": "Phổ biến nhất",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Phổ biến nhất",
  "blocks.packs.components.pack.platinum.value.ribbon": "Phổ biến nhất",
  "blocks.description.values.title": "<h2>MÔ TẢ TRÒ CHƠI</h2>",
  "blocks.description.components.text.label": "Trò chơi của bạn đưa người chơi tham gia một cuộc phiêu lưu đầy cam go với những quái vật, những kẻ săn vàng và bao hiểm nguy rình rập. Sự kiện kịch tính dần hé lộ làm cuộc sống của bạn đảo lộn và giờ đây buộc bạn phải lao vào một cuộc đấu tranh sinh tồn trong một thế giới mới đầy thù địch. Vừa khám phá, thám hiểm và chinh phạt vừa cố gắng sống sót qua nhiều chiều không gian của thực tại!",
  "blocks.faq.values.title": "<h2>HỎI ĐÁP</h2>",
  "blocks.faq.components.question.question": "Làm sao để tôi có thể chơi Your Game?",
  "blocks.faq.components.question.answer": "Để chơi Your Game, bạn cần mua và điền địa chỉ email. Bạn sẽ nhận thông tin mã để kích hoạt và tận hưởng trò chơi.",
  "blocks.news.values.title": "<h2>Tin tức</h2>",
  "blocks.news.values.description": "Xem các bài đăng gần đây để cập nhật tin tức.",
  "blocks.store.values.title": "<h2>Cửa hàng</h2>",
  "blocks.store.buy_button.text": "MUA NGAY VỚI GIÁ {amount}",
  "blocks.embed.values.title": "<h2>Tiện ích mạng xã hội</h2>",
  "blocks.embed.components.twitter.title": "<h3>TIN TỨC TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>TIN TỨC FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Chọn phiên bản",
  "blocks.packs.values.description": "Mỗi phiên bản bao gồm các phần thưởng và ưu đãi đặc biệt bạn sẽ nhận sau khi giao dịch.",
  "blocks.packs.components.pack.standart.value.subtext": "Đã ra mắt",
  "blocks.packs.components.pack.premium.value.subtext": "Đã ra mắt",
  "blocks.packs.components.pack.ultimate.value.subtext": "Đã ra mắt",
  "blocks.packs.components.pack.platinum.value.subtext": "Đã ra mắt",
  "blocks.packs.components.pack.standart.value.title": "<h3>Phiên bản Chuẩn</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Phiên bản Cao cấp</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Phiên bản Bạch kim</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Phiên bản Tối thượng</h3>",
  "blocks.packs.components.pack.standart.value.description": "Mã kích hoạt trò chơi, huy hiệu đặc biệt, nhạc nền, bản đồ và gói giáp trụ bổ sung",
  "blocks.packs.components.pack.premium.value.description": "Mã kích hoạt trò chơi, huy hiệu đặc biệt, nhạc nền, bản đồ, gói giáp trụ bổ sung và gói vũ khí bổ sung",
  "blocks.packs.components.pack.platinum.value.description": "Mã kích hoạt trò chơi, huy hiệu đặc biệt, nhạc nền, bản đồ, sách tranh điện tử, gói giáp trụ bổ sung, gói vũ khí bổ sung và 1 gói diện mạo ngẫu nhiên",
  "blocks.packs.components.pack.ultimate.value.description": "Mã kích hoạt trò chơi, huy hiệu đặc biệt, nhạc nền, bản đồ, sách tranh điện tử, sách bài hát điện tử, gói giáp trụ bổ sung, 2 gói vũ khí bổ sung và 3 gói diện mạo ngẫu nhiên",
  "blocks.subscriptions.values.card.title": "Đăng ký gói cao cấp và nhận ngay ưu đãi",
  "blocks.subscriptions.values.title": "Kích hoạt gói đăng ký",
  "blocks.subscriptions.values.heading": "Gói đăng ký",
  "blocks.subscriptions.values.bullets.one.title": "Nội dung số",
  "blocks.subscriptions.values.bullets.one.description": "Hình ảnh, hình nền, nội dung hậu trường, bản nhạc trong trò chơi",
  "blocks.subscriptions.values.bullets.two.title": "Ưu đãi trong trò chơi",
  "blocks.subscriptions.values.bullets.two.description": "Giao diện nhân vật, hiệu ứng hình ảnh, thẻ cốt truyện",
  "blocks.subscriptions.values.bullets.three.title": "3 hòm vật phẩm trong trò chơi",
  "blocks.subscriptions.values.bullets.three.description": "5 thẻ thông hành phụ vào Hội trường Hỗn loạn, 10 lần tái sinh nhân vật",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Gia hạn tiếp theo",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Gia hạn ngay bây giờ",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Quản lý gói đăng ký",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Chọn gói đăng ký",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Điều chỉnh gói đăng ký",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} ngày miễn phí",
  "editor.subscriptions-packs.button.then.price.per.day": "Sau đó là {amount} mỗi ngày",
  "editor.subscriptions-packs.button.then.price.per.week": "Sau đó là {amount} mỗi tuần",
  "editor.subscriptions-packs.button.then.price.per.month": "Sau đó là {amount} mỗi tháng",
  "editor.subscriptions-packs.button.then.price.per.year": "Sau đó là {amount} mỗi năm",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Sau đó là {amount} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Sau đó là {amount} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Sau đó là {amount} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Sau đó là {amount} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Sau đó là {amount} trọn đời",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "thanh toán đợt đầu tiên, sau đó là {amount} mỗi ngày",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "thanh toán đợt đầu tiên, sau đó là {amount} mỗi tuần",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "thanh toán đợt đầu tiên, sau đó là {amount} mỗi tháng",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "thanh toán đợt đầu tiên, sau đó là {amount} mỗi năm",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "thanh toán đợt đầu tiên, sau đó là {amount} trọn đời",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "cho lần thanh toán đầu tiên, sau đó là {amount} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "cho lần thanh toán đầu tiên, sau đó là {amount} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "cho lần thanh toán đầu tiên, sau đó là {amount} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "cho lần thanh toán đầu tiên, sau đó là {amount} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.price.per.day": "{amount} mỗi ngày",
  "editor.subscriptions-packs.button.price.per.week": "{amount} mỗi tuần",
  "editor.subscriptions-packs.button.price.per.month": "{amount} mỗi tháng",
  "editor.subscriptions-packs.button.price.per.year": "{amount} mỗi năm",
  "editor.subscriptions-packs.button.price.per.day.multiple": "Sau đó là {amount} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.price.per.week.multiple": "Sau đó là {amount} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.price.per.month.multiple": "Sau đó là {amount} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.price.per.year.multiple": "Sau đó là {amount} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} trọn đời",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi ngày trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi ngày",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tuần trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi tuần",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tháng trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi tháng",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi năm trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi năm",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Sau đó là {amount} thanh toán đợt đầu tiên, sau đó là {amountPromo} mỗi ngày",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Sau đó là {amount} thanh toán đợt đầu tiên, sau đó là {amountPromo} mỗi tuần",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Sau đó là {amount} thanh toán đợt đầu tiên, sau đó là {amountPromo} mỗi tháng",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Sau đó là {amount} thanh toán đợt đầu tiên, sau đó là {amountPromo} mỗi năm",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Sau đó là {amount} cho lần thanh toán đầu tiên, sau đó là {amountPromo} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Sau đó là {amount} cho lần thanh toán đầu tiên, sau đó là {amountPromo} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Sau đó là {amount} cho lần thanh toán đầu tiên, sau đó là {amountPromo} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Sau đó là {amount} cho lần thanh toán đầu tiên, sau đó là {amountPromo} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Sau đó là {amount} thanh toán đợt đầu tiên, sau đó là {amountPromo} trọn đời",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi ngày",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi tuần",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi tháng",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "trong {periodsCounts} quãng thời gian, sau đó là {amount} mỗi năm",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "trong {periodsCounts} kỳ liên tiếp, sau đó là {amount} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "trong {periodsCounts} quãng thời gian, sau đó là {amount} trọn đời",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, mùa kết thúc vào {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, mùa bắt đầu vào {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Sau đó là {amount}, mùa kết thúc vào {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Sau đó là {amount}, mùa bắt đầu vào {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi ngày",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tuần",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tháng",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi năm",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} trọn đời",
  "editor.subscriptions-packs.button.then.price.promo.price": "Sau đó là <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi ngày",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tuần",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi tháng",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi năm",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} ngày",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} tuần",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} tháng",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} mỗi {periodValue} năm",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} trọn đời",
  "editor.subscriptions-packs.button.then.price": "Sau đó là {amount}",
  "editor.subscriptions-packs.button.season.ends": "Mùa kết thúc vào {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Mùa bắt đầu vào {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / ngày",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / tuần",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / tháng",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / năm",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} ngày",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} tuần",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} tháng",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} năm",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / trọn đời",
  "blocks.requirements.values.title": "<h2>Yêu cầu hệ thống</h2>",
  "blocks.requirements.components.platform_req.value.title": "Hệ máy",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Đồ họa",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "Hệ điều hành",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Dung lượng đĩa trống",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Bộ xử lý",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Card âm thanh",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Bộ nhớ",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Tối thiểu",
  "default-data.values.requirements.title.recommended": "Khuyến nghị",
  "default-data.values.requirements.item.directx.minimum": "Phiên bản 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Tương thích DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Tương thích DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Phiên bản 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760/AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Chào bạn!",
  "default-data.customization.receipt.description": "Cảm ơn bạn đã giao dịch! Chúng tôi rất cảm kích bạn chơi trò chơi của chúng tôi.",
  "default-data.customization.receipt.footer": "© Bảo lưu mọi quyền.",
  "blocks.store.components.storeSection.title": "Vật phẩm",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Bạn đã đăng ký thành công!",
  "notification.hero.components.subscribe.subscription.success": "Chúng tôi vừa gửi email xác nhận cho bạn. Hãy theo liên kết trong email để kích hoạt gói đăng ký của bạn.",
  "notification.hero.components.subscribe.error": "Có lỗi xảy ra. Vui lòng thử lại sau hoặc liên hệ với đội ngũ tích hợp của chúng tôi tại <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Bạn đã đăng ký rồi.",
  /* notifications values END */
  "period.years_short": "năm",
  "period.months_short": "tháng",
  "period.weeks_short": "tuần",
  "period.days_short": "ngày",
  "subscription.button.text": "Đăng ký</br>{fullCurrency} mỗi {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Gói đăng ký của bạn",
  "client.subscription.button.configuration": "Quản lý gói đăng ký",
  "client.subscription.button.lifetime_text": "Đăng ký trọn đời</br>với giá {fullCurrency}",
  "client.thank_you_modal.title": "Cảm ơn bạn đã đăng ký!",
  "client.thank_you_modal.description": "Gói đăng ký của bạn sẽ tự động gia hạn. Thanh toán sẽ được xử lý định kỳ dựa theo điều khoản gói đăng ký của bạn. Bạn có thể hủy gói đăng ký bất cứ lúc nào.",
  "client.thank_you_modal.input.description": "Nếu bạn muốn thấy tên bạn trong danh sách người đăng ký, hãy điền tên bạn dưới đây.",
  "client.thank_you_modal.button.default.text": "Cảm ơn, ẩn đi",
  "client.thank_you_modal.input.placeholder": "Tên của bạn",
  "client.thank_you_modal.button.show_list": "Hiển thị tất cả người đăng ký",
  "client.subscribers_list_modal.title": "Danh sách người đăng ký đầy đủ",
  "client.subscribers_list_modal.description": "Xin cảm ơn tất cả người chơi đã tham gia cùng chúng tôi và đăng ký trò chơi!",
  "client.subscribers_list_modal.show_more_button": "Hiện thêm",
  "client.subscribers_list_modal.anonymous": "và người dùng ẩn danh",
  "client.footer.button.list_of_subscribers": "Danh sách người đăng ký",
  "client.footer.xsollalogo.powered": "Cung cấp bởi", // копирайт в футере
  "client.footer.xsollalogo.sb": "Trình tạo trang web Xsolla",
  "client.empty_subscribers_modal.title": "Người đăng ký sẽ được liệt kê ở đây",
  "client.empty_subscribers_modal.description": "Hãy là người đầu tiên đăng ký trò chơi của chúng tôi.",
  "client.empty_subscribers_modal.button": "Chọn gói đăng ký ngay",
  "client.news.blurred.description": "Hãy đăng ký {subscription_name} để mở khóa nội dung này.",
  "client.news.blurred.button.join": "Tham gia ngay",
  "client.cart_modal.title": "Giỏ hàng",
  "client.cart_modal.item.pre-order": "(đặt trước)",
  "client.cart_modal.clear_all": "xóa tất cả",
  "client.cart_modal.item_purchased_description": "Bạn đã mua vật phẩm này rồi",
  "client.cart_modal.subtotal_title": "Tổng",
  "client.cart_modal.subtotal_subtitle": "Thuế và phụ phí có thể được áp dụng",
  "client.cart_modal.continue_button": "tiếp tục mua sắm",
  "client.cart_modal.checkout_button": "tiến hành thanh toán",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Đăng nhập</button> hoặc <button class=\'xl_auth\' data-xl-route=\'signup\'>đăng ký</button> để lưu các sản phẩm trong giỏ hàng để mua sau hoặc xem lại các sản phẩm đã lưu trước đó",
  "client.cart_modal.empty_cart_description": "Giỏ hàng của bạn trống. Hãy đến cửa hàng và tìm món gì đó thú vị để mua nhé.",
  "client.cart_modal.empty_cart_open_store_button": "mở cửa hàng",
  "client.cart_modal.email_label": "Gửi mã kích hoạt trò chơi đến:",
  "client.cart_modal.email_description": "Bạn sẽ nhận được mã kích hoạt để bắt đầu trò chơi.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Phần thưởng",
  "client.cart_modal.promo_codes.apply": "Áp dụng",
  "client.cart_modal.promo_codes.remove": "Xóa",
  "client.cart_modal.promo_codes.placeholder": "Mã khuyến mại",
  "client.cart_modal.promo_codes.error_incorrect": "Mã khuyến mại không đúng.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Đã vượt giới hạn quy đổi mã.",
  "client.cart_modal.promo_codes.error_expired": "Mã khuyến mại này đã hết hạn.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Không thể áp dụng mã khuyến mại. Vui lòng thử lại sau.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Mã khuyến mại này không hợp lệ với các sản phẩm trong giỏ hàng của bạn.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Chọn một nền tảng trò chơi để tiếp tục.",
  "client.cart_modal.promo_codes.select_drm": "Chọn nền tảng",
  "client.cart_modal.promo_codes.tooltip_drm": "Để thay đổi nền tảng, hãy bỏ chọn mã khuyến mại và sau đó áp dụng lần nữa",
  "client.bundle_modal.group_items": "Vật phẩm trong gói",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Đồng</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Nội dung số</h3><p>✓ Hình artwork<p>✓ Hình nền<span style=\'color:rgb(127, 96, 0);\'>× Nhạc trong trò chơi </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Nội dung hậu trường</span></p><p><br></p><h3>3 </h3><h3>vật phẩm</h3> trong trò chơi<p>✓ Giao diện nhân vật</p><p><span style=\'color:rgb(127, 96, 0);\'>× Hiệu ứng hình ảnh </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Thẻ cốt truyện</span></p><p><br></p><h3>Phần thưởng trong trò chơi</h3><p>✓ x1 thẻ thông hành đến Sảnh Hỗn Loạn</p><p>✓ x1 hồi sinh nhân vật</p><p><br></p><h3>Discord</h3><p>✓ Truy cập kênh cho người chơi</p><p><span style=\'color:rgb(127, 96, 0);\'>× Truy cập kênh cho nhà phát triển</span></p><p><br></p><h3>Diễn đàn</h3><p>✓ Màu biệt danh độc quyền</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji độc quyền</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Hình đại diện động </span></p><p>✓ x2 điểm xếp hạng bài đăng (mỗi ngày)</p><p><br></p><h3>Vật phẩm</h3><p>✓ Huy hiệu và nhãn dán (mỗi 6 tháng)</p><p>✓ Poster chất lượng cao (mỗi 6 tháng)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Đồ chơi nhồi bông có thương hiệu (mỗi năm)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Mô hình chất lượng cao (mỗi năm)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Sự kiện offline</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Miễn phí tham dự hội nghị về trò chơi của chúng tôi</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Tham quan văn phòng công ty (mỗi năm)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ cho phép truy cập vào Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Bạc</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Nội dung số</h3><p>✓ Hình artwork</p><p>✓ Hình nền</p><p><span style=\"color:rgb(127, 96, 0);\">× Nhạc trong trò chơi</span></p><p>✓ Nội dung hậu trường</p><p><br></p><h3>6 </h3><h3>vật phẩm</h3> trong trò chơi<p>✓ Giao diện nhân vật</p><p>✓ Hiệu ứng hình ảnh</p><p><span style=\"color:rgb(127, 96, 0);\">× Thẻ cốt truyện</span></p><p><br></p><h3>Phần thưởng trong trò chơi</h3><p>✓ x2 thẻ thông hành đến Sảnh Hỗn Loạn</p><p>✓ x2 hồi sinh nhân vật</p><h3><br></h3><h3>Discord</h3><p>✓ Truy cập kênh cho người chơi</p><p><span style=\"color:rgb(127, 96, 0);\">× Truy cập kênh cho nhà phát triển</span></p><p><br></p><h3>Diễn đàn</h3><p>✓ Màu biệt danh độc quyền</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji độc quyền</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Hình đại diện động</span></p><p>✓ 5x điểm xếp hạng bài đăng (mỗi ngày)</p><p><br></p><h3>Vật phẩm</h3><p>✓ Huy hiệu và nhãn dán (mỗi 6 tháng)</p><p>✓ Poster chất lượng cao (mỗi 6 tháng)</p><p><span style=\"color:rgb(127, 96, 0);\">× Đồ chơi nhồi bông có thương hiệu (mỗi năm)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Mô hình chất lượng cao (mỗi năm)</span></p><p><br></p><h3>Sự kiện offline</h3><p>✓ Miễn phí tham dự hội nghị về trò chơi của chúng tôi</p><p><span style=\"color:rgb(127, 96, 0);\">× Tham quan văn phòng (mỗi năm)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ cho phép truy cập vào Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Vàng</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Nội dung số</h3><p>✓ Hình artwork</p><p>✓ Hình nền</p><p>✓ Nhạc trong trò chơi</p><p>✓ Nội dung hậu trường</p><p><br></p><h3>9  vật phẩm trong trò chơi + 1 vật phẩm HUYỀN THOẠI</h3><p>✓ Giao diện nhân vật</p><p>✓ Hiệu ứng hình ảnh</p><p>✓ Thẻ cốt truyện</p><p><br></p><h3>Phần thưởng trong trò chơi</h3><p>✓ x5 thẻ thông hành đến Sảnh Hỗn Loạn</p><p>✓ x10 hồi sinh nhân vật</p><p><br></p><h3>Discord</h3><p>✓ Truy cập kênh cho người chơi</p><p>✓ Truy cập kênh cho nhà phát triển</p><p><br></p><h3>Diễn đàn</h3><p>✓ Màu biệt danh độc quyền</p><p>✓ Emoji độc quyền</p><p>✓ Hình đại diện động</p><p>✓ x12 điểm xếp hạng bài đăng (mỗi ngày)</p><p><br></p><h3>Vật phẩm</h3><p>✓ Huy hiệu và nhãn dán (mỗi 6 tháng)</p><p>✓ Poster chất lượng cao (mỗi 6 tháng)</p><p>✓ Đồ chơi nhồi bông có thương hiệu (mỗi năm)</p><p>✓ Mô hình chất lượng cao (mỗi năm)</p><p><br></p><h3>Sự kiện offline</h3><p>✓ Miễn phí tham dự hội nghị về trò chơi của chúng tôi</p><p>✓ Tham quan văn phòng công ty (mỗi năm)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ cho phép truy cập vào Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Nội dung số</h3><p>Hình artwork</p><p>Hình nền</p><p><span>Nhạc trong trò chơi</span></p><p><span>Nội dung hậu trường</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3></h3><h3>Vật phẩm</h3> trong trò chơi<p>Giao diện nhân vật</p><p><span>Hiệu ứng hình ảnh </span></p><p><span>Thẻ cốt truyện</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Phần thưởng trong trò chơi</h3><p>x1 Thẻ thông hành đến Sảnh Hỗn Loạn</p><p>x1 hồi sinh nhân vật</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Truy cập kênh cho người chơi</p><p><span>× Truy cập kênh cho nhà phát triển</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Diễn đàn</h3><p>Màu biệt danh độc quyền </p><p><span>× Emoji độc quyền</span></p><p><span>× Hình đại diện động </span></p><p>x2 điểm xếp hạng bài đăng (mỗi ngày)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Vật phẩm</h3><p>Huy hiệu và nhãn dán (mỗi 6 tháng)</p><p>Ảnh lớn chất lượng cao (mỗi 6 tháng)</p><p><span>Đồ chơi nhồi bông có thương hiệu (mỗi năm)</span></p><p><span>Mô hình chất lượng cao (mỗi năm)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Sự kiện ngoại tuyến</h3><p>✓ Tham dự miễn phí các sự kiện về trò chơi của chúng tôi </p><p>✓ Đến thăm văn phòng công ty (hàng năm)</p>",
  "client.buy_button.text": "Mua ngay",
  "client.buy_button.unavailable": "Không khả dụng",
  "client.buy_button.pre-order": "Đặt trước",
  "client.buy_button.checkout": "Tiến hành thanh toán",
  "client.store.cart.add_button": "Thêm vào giỏ hàng",
  "client.store.cart.header_button": "Giỏ hàng",
  "client.store.no_items": "Cửa hàng đang trống. Hãy quay lại sau!",
  "client.store.error.gt10_gk": "Để bảo vệ bạn khỏi nguy cơ lừa đảo, bạn chỉ được phép mua tối đa 10 mã trò chơi một lúc.",
  "client.store.error.no_keys": "Trò chơi tạm thời hết hàng và sẽ sớm có hàng trở lại. Xin liên hệ chúng tôi để được cập nhật.",
  "client.store.item.purchased": "Đã mua",
  "client.store.item.price_from": "từ {price}",
  "client.store.item.show_more": "Hiển thị chi tiết",
  "client.store.successful_purchase": "Thành công. Cảm ơn bạn đã giao dịch!",
  "client.store.not_enough_vc": "Không đủ tiền ảo.</br>Vui lòng nạp tiền để hoàn tất giao dịch mua hàng.",
  "client.store.non_consumable_item": "Bạn đã mua vật phẩm này trước đây, hiện tại đã không còn khả dụng.",
  "client.store.user_id_input_before_submit_hint": "Mặt hàng sẽ được ghi nhận cho tài khoản này.",
  "client.store.user_id_input_after_submit_hint": "Bạn đang mua hàng cho:",
  "client.common_error": "Có lỗi xảy ra. Vui lòng thử lại sau hoặc liên hệ với đội ngũ tích hợp của chúng tôi tại <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Số dư của bạn",
  "client.header.logout": "Đăng xuất",
  "client.retailers.edition.title": "Chọn phiên bản",
  "client.retailers.country.title": "Chọn quốc gia",
  "client.retailers.platform.title": "Chọn nền tảng",
  "client.retailers.retailer.title": "Chọn người bán",
  "client.retailers.edition.digital": "Điện tử",
  "client.retailers.edition.physical": "Thực tế",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Mua từ nhà phát triển",
  "client.retailers.expand_button.collapse": "Thu gọn",
  "client.retailers.buy_button.buy": "Mua ngay",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Phổ biến nhất",
  "blocks.retailers.components.retailers.standart.value.subtext": "Đã ra mắt",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Phiên bản Chuẩn</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Mã kích hoạt trò chơi, huy hiệu đặc biệt, nhạc nền, bản đồ và gói giáp trụ bổ sung",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TỰA TRÒ CHƠI CỦA BẠN</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Trò chơi của bạn đưa người chơi tham gia một cuộc phiêu lưu đầy cam go với những quái vật, những kẻ săn vàng và bao hiểm nguy rình rập.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Hiện có trên",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Nhập địa chỉ email của bạn",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Đăng ký nhận tin từ chúng tôi để biết được những thông tin và cập nhật mới nhất",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Nút thiết lập",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Mua ngay",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Nút thiết lập",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Nút thiết lập",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Nút thiết lập",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Không khả dụng",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Nút thiết lập",
  "client.topup.input.errorMessage": "Có lỗi xảy ra. Vui lòng đảm bảo mã người dùng của bạn là chính xác hoặc thử lại sau.",
  "client.topup.input.successMessage": "Bạn đã đăng nhập bằng mã người dùng {userID}. Bây giờ bạn có thể bắt đầu giao dịch trên trang web.",
  "client.topup.input.username": "Tên người dùng: {name}",
  "client.topup.input.id": "Mã: {id}",
  "client.topup.input.level": "Cấp độ: {level}",
  "client.topup.input.user_not_found": "Không thể tìm thấy mã người dùng như bạn cung cấp.<br/>Vui lòng thử lại.",
  "client.topup.input.serverError": "Có lỗi xảy ra.<br>Nếu bạn nhận được thông báo này nhiều hơn một lần, vui lòng {contact_us}.",
  "client.topup.input.serverError.contact_us": "liên hệ chúng tôi",
  "client.topup.authorized_title": "Bạn đang mua hàng cho:",
  "blocks.store.values.custom_title": "<h2>Cửa hàng {gameTitle}</h2>"
};

module.exports = localizations;
