var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Mostra di più",
  "client.header.login.button": "Login",
  "client.header.logout.button": "Logout",
  "client.user-id-modal.input-placeholder": "ID utente",
  "client.user-id-modal.input-incorrect-id": "ID utente errato.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Copia e incolla l\'ID dal gioco.",
  "client.user-id-modal.continue-button": "Continua",
  "client.user-id-modal.go-back": "Indietro",
  "client.user-id-modal.socials.amazon": "Amazon",
  "client.user-id-modal.socials.apple": "Apple",
  "client.user-id-modal.socials.baidu": "Baidu",
  "client.user-id-modal.socials.battlenet": "Battle.net",
  "client.user-id-modal.socials.discord": "Discord",
  "client.user-id-modal.socials.epicgames": "Epic Games",
  "client.user-id-modal.socials.facebook": "Facebook",
  "client.user-id-modal.socials.github": "GitHub",
  "client.user-id-modal.socials.google": "Google",
  "client.user-id-modal.socials.kakao": "KakaoTalk",
  "client.user-id-modal.socials.linkedin": "LinkedIn",
  "client.user-id-modal.socials.mailru": "Mail.Ru",
  "client.user-id-modal.socials.microsoft": "Microsoft",
  "client.user-id-modal.socials.msn": "MSN",
  "client.user-id-modal.socials.naver": "Naver",
  "client.user-id-modal.socials.ok": "OK",
  "client.user-id-modal.socials.paypal": "PayPal",
  "client.user-id-modal.socials.qq": "QQ",
  "client.user-id-modal.socials.reddit": "Reddit",
  "client.user-id-modal.socials.slack": "Slack",
  "client.user-id-modal.socials.steam": "Steam",
  "client.user-id-modal.socials.twitch": "Twitch",
  "client.user-id-modal.socials.twitter": "Twitter",
  "client.user-id-modal.socials.vimeo": "Vimeo",
  "client.user-id-modal.socials.vk": "VK",
  "client.user-id-modal.socials.wechat": "WeChat",
  "client.user-id-modal.socials.weibo": "Weibo",
  "client.user-id-modal.socials.xbox": "Xbox Live",
  "client.user-id-modal.socials.yahoo": "Yahoo",
  "client.user-id-modal.socials.yandex": "Yandex",
  "client.user-id-modal.socials.youtube": "YouTube",
  "client.view-nickname-modal.show-more.button": "Mostra di più",
  "client.privacy-settings.open-button": "Impostazioni Privacy",
  "client.usa-privacy-settings.open-button": "Non vendere i miei dati",
  "client.user-id-modal.continue-with": "Continua con {socialName}",
  /* default-data page texts */
  "document.pages.auth.title": "Inserisci l\'ID utente",
  "document.pages.auth.subtitle": "o inserisci ID",
  "document.pages.auth.placeholder": "Esempio: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Non riesci a trovare l\'ID utente?",
  "document.pages.auth.instruction.steps.description": "Descrive in che modo l\'utente può trovare il proprio ID personale. Si possono aggiungere vari passaggi e immagini.",
  "document.pages.seo.title": "È appena uscito il Nuovo Gioco!",
  "document.pages.seo.description": "Il tuo Gioco conduce i giocatori in una nuova avventura piena di detrattori, mostri, cercatori d\'oro e grandi pericoli.",
  "document.pages.seo.ogTitle": "È appena uscito il Nuovo Gioco!",
  "document.pages.seo.ogDescription": "Il tuo Gioco conduce i giocatori in una nuova avventura piena di detrattori, mostri, cercatori d\'oro e grandi pericoli.",
  "default-data.templates.google-play.contact-us": "Contattaci {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "RICARICA IL WALLET IN-GAME",
  "blocks.topup.values.description": "Inserisci ID utente",
  "blocks.topup.values.userIdPlaceholder": "Esempio: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "NON RIESCI A TROVARE L\'ID UTENTE?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Passaggio nome",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Descrive in che modo l\'utente può trovare il proprio ID personale. Si possono aggiungere vari passaggi e immagini.",
  "default-data.templates.topup.app-support": "Supporto app",
  "default-data.templates.topup.age-raiting": "Classificazione età",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TITOLO DEL TUO GIOCO</h1>",
  "blocks.retailers.values.title": "<h2>Scegli la tua edizione</h2>",
  "blocks.hero.values.description": "Il tuo Gioco conduce i giocatori in una nuova avventura piena di detrattori, mostri, cercatori d\'oro e grandi pericoli.",
  "blocks.hero.values.affiliate": "Le entrate derivanti da questo acquisto verranno condivise con il tuo affiliato",
  "blocks.hero.components.platforms.label": "Disponibile per",
  "blocks.hero.components.subscribe.label": "Inserisci la tua e-mail",
  "blocks.hero.components.subscribe.values.text" : "Iscriviti alla nostra newsletter per ricevere le notizie e gli aggiornamenti più recenti",
  "blocks.hero.components.scroll.label": "Scorri",
  "blocks.footer.values.name": "Inserisci il tuo testo",
  "blocks.footer.values.affiliate": "Iscriviti al programma di affiliazione",
  "blocks.hero.components.buy.label": "Compra ora",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.hero.components.customButton.link.label": "Configura pulsante",
  "blocks.header.components.customButton.link.label": "Configura pulsante",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.packs.components.pack.customButton.link.label": "Configura pulsante",
  "blocks.packs.components.pack.standart.components.buy.label": "Compra ora",
  "blocks.packs.components.pack.premium.components.buy.label": "Compra ora",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Compra ora",
  "blocks.packs.components.pack.platinum.components.buy.label": "Compra ora",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.packs.components.pack.standart.value.ribbon": "Più popolari",
  "blocks.packs.components.pack.premium.value.ribbon": "Più popolari",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Più popolari",
  "blocks.packs.components.pack.platinum.value.ribbon": "Più popolari",
  "blocks.description.values.title": "<h2>DESCRIZIONE GIOCO</h2>",
  "blocks.description.components.text.label": "Il tuo Gioco conduce i giocatori in una nuova avventura piena di detrattori, mostri, cercatori d\'oro e grandi pericoli. Eventi drammatici accaduti che hanno stravolto la tua vita e ora devi combattere per sopravvivere in un nuovo mondo ostile. Scopri, esplora e conquista mentre sopravvivi a diverse dimensioni della realtà!",
  "blocks.faq.values.title": "<h2>DOMANDE FREQUENTI</h2>",
  "blocks.faq.components.question.question": "Come faccio a giocare a Your Game?",
  "blocks.faq.components.question.answer": "Per giocare a Your Game devi acquistare il gioco e specificare il tuo indirizzo email. Riceverai una chiave di attivazione che dovrai inserire per poi accedere al gioco.",
  "blocks.news.values.title": "<h2>News</h2>",
  "blocks.news.values.description": "Dai uno sguardo agli articoli recenti per scoprire cosa c\'è di nuovo.",
  "blocks.store.values.title": "<h2>Negozio</h2>",
  "blocks.store.buy_button.text": "COMPRA ORA PER {amount}",
  "blocks.embed.values.title": "<h2>Widget social media</h2>",
  "blocks.embed.components.twitter.title": "<h3>FEED DI TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>FEED DI FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Seleziona la tua edizione",
  "blocks.packs.values.description": "Tutte le edizioni includono vantaggi e benefici esclusivi che riceverete una volta effettuato l\'acquisto.",
  "blocks.packs.components.pack.standart.value.subtext": "Disponibile ora",
  "blocks.packs.components.pack.premium.value.subtext": "Disponibile ora",
  "blocks.packs.components.pack.ultimate.value.subtext": "Disponibile ora",
  "blocks.packs.components.pack.platinum.value.subtext": "Disponibile ora",
  "blocks.packs.components.pack.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Premium Edition</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Platinum Edition</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ultimate Edition</h3>",
  "blocks.packs.components.pack.standart.value.description": "Chiave gioco, badge esclusivo, colonna sonora, mappa e pacchetto armatura aggiuntivo",
  "blocks.packs.components.pack.premium.value.description": "Chiave gioco, badge esclusivo, colonna sonora, mappa, pacchetto armatura aggiuntivo e pacchetto arma aggiuntivo",
  "blocks.packs.components.pack.platinum.value.description": "Chiave gioco, badge esclusivo, colonna sonora, mappa, album digital art, pacchetto armatura aggiuntivo, pacchetto arma aggiuntivo, un pacchetto skin casuale",
  "blocks.packs.components.pack.ultimate.value.description": "Chiave gioco, badge esclusivo, colonna sonora, mappa, album digital art, album canzone digitale, pacchetto armatura aggiuntivo, due pacchetti armi aggiuntivi e tre pacchetti skin casuali",
  "blocks.subscriptions.values.card.title": "Passa a premium e scopri i vantaggi",
  "blocks.subscriptions.values.title": "Attiva abbonamento",
  "blocks.subscriptions.values.heading": "Abbonamenti",
  "blocks.subscriptions.values.bullets.one.title": "Contenuto digitale",
  "blocks.subscriptions.values.bullets.one.description": "Immagini, sfondi, contenuti dietro le quinte, colonne sonore in-game",
  "blocks.subscriptions.values.bullets.two.title": "Vantaggi in-game",
  "blocks.subscriptions.values.bullets.two.description": "Skin personaggi, effetti visivi, carte storyline",
  "blocks.subscriptions.values.bullets.three.title": "3 loot box in-game",
  "blocks.subscriptions.values.bullets.three.description": "5 pass extra per Hall of Chaos, 10 rinascite personaggio",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Prossimo rinnovo",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Rinnova ora",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Gestisci piano",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Seleziona il tuo piano:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configura abbonamento",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} giorno(i) gratis",
  "editor.subscriptions-packs.button.then.price.per.day": "Dopodiché {amount} al giorno",
  "editor.subscriptions-packs.button.then.price.per.week": "Dopodiché {amount} alla settimana",
  "editor.subscriptions-packs.button.then.price.per.month": "Dopodiché {amount} al mese",
  "editor.subscriptions-packs.button.then.price.per.year": "Dopodiché {amount} all\'anno",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Dopodiché {amount} per {periodValue} giorni",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Dopodiché {amount} per {periodValue} settimane",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Dopodiché {amount} per {periodValue} mesi",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Dopodiché {amount} per {periodValue} anni",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Dopodiché {amount} una tantum",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "come primo pagamento, dopodiché {amount} al giorno",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "come primo pagamento, dopodiché {amount} alla settimana",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "come primo pagamento, dopodiché {amount} al mese",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "come primo pagamento, dopodiché {amount} all\'anno",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "come primo pagamento, dopodiché {amount} una tantum",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "come primo pagamento, dopodiché {amount} per {periodValue} giorni",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "come primo pagamento, dopodiché {amount} per {periodValue} settimane",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "come primo pagamento, dopodiché {amount} per {periodValue} mesi",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "come primo pagamento, dopodiché {amount} per {periodValue} anni",
  "editor.subscriptions-packs.button.price.per.day": "{amount} al giorno",
  "editor.subscriptions-packs.button.price.per.week": "{amount} alla settimana",
  "editor.subscriptions-packs.button.price.per.month": "{amount} al mese",
  "editor.subscriptions-packs.button.price.per.year": "{amount} all\'anno",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} per {periodValue} giorni",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} per {periodValue} settimane",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} per {periodValue} mesi",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} per {periodValue} anni",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} una tantum",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} al giorno per {periodsCounts} giorno(i), dopodiché {amount} al giorno",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} alla settimana per {periodsCounts} settimana(e), dopodiché {amount} alla settimana",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} al mese per {periodsCounts} mese(i), dopodiché {amount} al mese",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} all\'anno per {periodsCounts} anno(i), dopodiché {amount} all\'anno",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} giorni per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} giorni",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} settimane per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} settimane",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} mesi per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} mesi",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} anni per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} anni",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} al giorno",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} alla settimana",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} al mese",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} all\'anno",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} per {periodValue} giorni",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} per {periodValue} settimane",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} per {periodValue} mesi",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} per {periodValue} anni",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Dopodiché {amount} come primo pagamento, in seguito {amountPromo} una tantum",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "per {periodsCounts} giorno(i), dopodiché {amount} al giorno",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "per {periodsCounts} settimana(e), dopodiché {amount} alla settimana",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "per {periodsCounts} mese(i), dopodiché {amount} al mese",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "per {periodsCounts} anno(i), dopodiché {amount} all\'anno",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} giorni",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} settimane",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} mesi",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "per {periodsCounts} periodo(i), dopodiché {amount} per {periodValue} anni",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "-",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, il periodo finisce il {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, il periodo inizia il {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Dopodiché {amount}, il periodo finisce il {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Dopodiché, il periodo inizia il {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} al giorno",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} alla settimana",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} al mese",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} all\'anno",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} giorni",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} settimane",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} mesi",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} anni",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} una tantum",
  "editor.subscriptions-packs.button.then.price.promo.price": "Dopodiché <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} al giorno",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} alla settimana",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} al mese",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} all\'anno",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} giorni",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} settimane",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} mesi",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo} per {periodValue} anni",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} una tantum",
  "editor.subscriptions-packs.button.then.price": "Dopodiché {amount}",
  "editor.subscriptions-packs.button.season.ends": "Il periodo finisce il {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Il periodo inizia il {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / al giorno",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / alla settimana",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / al mese",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / all\'anno",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} giorni",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} settimane",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} mesi",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} anni",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / una tantum",
  "blocks.requirements.values.title": "<h2>Requisiti di sistema</h2>",
  "blocks.requirements.components.platform_req.value.title": "Piattaforma",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Scheda Grafica",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "Sistema Operativo",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Spazio libero su disco",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Processore",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Scheda Audio",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Memoria",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimi",
  "default-data.values.requirements.title.recommended": "Consigliati",
  "default-data.values.requirements.item.directx.minimum": "Versione 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Compatibile DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Compatibile DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Versione 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Ciao!",
  "default-data.customization.receipt.description": "Grazie per il tuo acquisto! Ci fa piacere che tu stia giocando con il nostro gioco.",
  "default-data.customization.receipt.footer": "© Tutti i diritti riservati.",
  "blocks.store.components.storeSection.title": "Articoli",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "La tua iscrizione è avvenuta con successo!",
  "notification.hero.components.subscribe.subscription.success": "Ti abbiamo appena inviato un\'e-mail di conferma. Segui il link contenuto nella email per attivare la tua iscrizione.",
  "notification.hero.components.subscribe.error": "Qualcosa è andato storto. Riprova più tardi o contatta il nostro team di integrazione all\'indirizzo <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Sei già iscritto.",
  /* notifications values END */
  "period.years_short": "aa.",
  "period.months_short": "mesi",
  "period.weeks_short": "set.",
  "period.days_short": "gg.",
  "subscription.button.text": "Abbonati a</br>{fullCurrency} per {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Il tuo abbonamento",
  "client.subscription.button.configuration": "Gestione abbonamento",
  "client.subscription.button.lifetime_text": "Abbonati per sempre,</br>per soli {fullCurrency}",
  "client.thank_you_modal.title": "Grazie per esserti abbonato!",
  "client.thank_you_modal.description": "Il tuo abbonamento si rinnova automaticamente. Il pagamento verrà addebitato regolarmente secondo le condizioni del tuo abbonamento. Puoi cancellarlo in qualunque momento.",
  "client.thank_you_modal.input.description": "Se vuoi apparire nella lista dei nostri abbonati, inserisci il tuo nome qui sotto.",
  "client.thank_you_modal.button.default.text": "Grazie, ignora",
  "client.thank_you_modal.input.placeholder": "Il tuo nome",
  "client.thank_you_modal.button.show_list": "Mostra tutti gli abbonati",
  "client.subscribers_list_modal.title": "Lista completa degli abbonati",
  "client.subscribers_list_modal.description": "Grazie a tutti gli utenti che si sono uniti a noi e si sono abbonati al gioco!",
  "client.subscribers_list_modal.show_more_button": "Mostra di più",
  "client.subscribers_list_modal.anonymous": "e altri utenti anonimi",
  "client.footer.button.list_of_subscribers": "Lista degli abbonati",
  "client.footer.xsollalogo.powered": "Fornito da", // копирайт в футере
  "client.footer.xsollalogo.sb": "Costruttore di Sito Xsolla",
  "client.empty_subscribers_modal.title": "Gli abbonati verranno elencati qui",
  "client.empty_subscribers_modal.description": "Abbonati per primo al nostro gioco.",
  "client.empty_subscribers_modal.button": "Seleziona ora l\'abbonamento",
  "client.news.blurred.description": "Abbonati a {subscription_name} per sbloccare questi contenuti.",
  "client.news.blurred.button.join": "Abbonati ora",
  "client.cart_modal.title": "Carrello",
  "client.cart_modal.item.pre-order": "(pre-ordine)",
  "client.cart_modal.clear_all": "cancella tutto",
  "client.cart_modal.item_purchased_description": "Hai già acquistato questo articolo",
  "client.cart_modal.subtotal_title": "Totale",
  "client.cart_modal.subtotal_subtitle": "Potrebbero essere applicate imposte e tasse",
  "client.cart_modal.continue_button": "continua gli acquisti",
  "client.cart_modal.checkout_button": "vai alla cassa",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Accedi</button> o <button class=\'xl_auth\' data-xl-route=\'signup\'>registrati</button> per salvare gli articoli del carrello per rivederli in un secondo momento o per controllare gli articoli salvati in precedenza.",
  "client.cart_modal.empty_cart_description": "Il carrello è vuoto. Vai al negozio e trova qualcosa di fantastico da comprare.",
  "client.cart_modal.empty_cart_open_store_button": "apri negozio",
  "client.cart_modal.email_label": "Invia la chiave di attivazione del gioco a:",
  "client.cart_modal.email_description": "Riceverai una chiave che dovrai attivare per poter iniziare a giocare.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Bonus",
  "client.cart_modal.promo_codes.apply": "Applica",
  "client.cart_modal.promo_codes.remove": "Rimuovi",
  "client.cart_modal.promo_codes.placeholder": "Codice promozionale",
  "client.cart_modal.promo_codes.error_incorrect": "Codice promozionale errato.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Hai raggiunto il limite fissato per riscattare il codice.",
  "client.cart_modal.promo_codes.error_expired": "Questo codice promozionale è scaduto.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Non è stato possibile applicare il codice promozionale. Riprova più tardi.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Il codice promozionale non è valido per gli articoli del tuo carrello.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Seleziona una piattaforma di gioco per continuare.",
  "client.cart_modal.promo_codes.select_drm": "Seleziona piattaforma",
  "client.cart_modal.promo_codes.tooltip_drm": "Per cambiare piattaforma, rimuovi il codice promozionale e invia di nuovo",
  "client.bundle_modal.group_items": "Articoli del pacchetto",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronzo</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Contenuti digitali</h3><p>✓ Arte</p><p>✓ Sfondi</p><p><span style=\'color:rgb(127, 96, 0);\'>× Colonne sonore in-game </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Contenuti dietro le quinte</span></p><p><br></p><h3>3 In-game</h3><h3>loot box</h3><p>✓ Skin personaggi</p><p><span style=\'color:rgb(127, 96, 0);\'>× Effetti visivi </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Carte Storyline</span></p><p><br></p><h3>Vantaggi in-game</h3><p>✓ x1 Pass extra per Hall of Chaos</p><p>✓ x1 rinascita personaggio</p><p><br></p><h3>Discord</h3><p>✓ Accesso al canale dei giocatori</p><p><span style=\'color:rgb(127, 96, 0);\'>× Accesso al canale degli sviluppatori</span></p><p><br></p><h3>Forum</h3><p>✓ Colore esclusivo nickname</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji esclusiva</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Immagine profilo animata </span></p><p>✓ x2 Punti extra per il posizionamento del post (giornalmente)</p><p><br></p><h3>Gadget</h3><p>✓ Spille &amp; adesivi (ogni 6 mesi)</p><p>✓ Poster di alta qualità (ogni 6 mesi)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Peluche di marca (ogni anno)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Statuette di alta qualità (ogni anno)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Eventi offline</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Accesso gratuito alle nostre gaming convention</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Visita agli uffici dell\'azienda (ogni anno)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ permette l\'accesso a Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Argento</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Contenuti digitali</h3><p>✓ Arte</p><p>✓ Sfondi</p><p><span style=\"color:rgb(127, 96, 0);\">× Colonne sonore in-game</span></p><p>✓ Contenuti dietro le quinte</p><p><br></p><h3>6 In-game</h3><h3>loot box</h3><p>✓ Skin personaggi</p><p>✓ Effetti visivi</p><p><span style=\"color:rgb(127, 96, 0);\">× Carte Storyline</span></p><p><br></p><h3>Vantaggi in-game</h3><p>✓ x2 Pass extra per Hall of Chaos</p><p>✓ x2 rinascita personaggio</p><h3><br></h3><h3>Discord</h3><p>✓ Accesso al canale dei giocatori</p><p><span style=\"color:rgb(127, 96, 0);\">× Accesso al canale degli sviluppatori</span></p><p><br></p><h3>Forum</h3><p>✓ Colore esclusivo nickname</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji esclusiva</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Immagine profilo animata</span></p><p>✓ 5x Punti extra per il posizionamento del post (giornalmente)</p><p><br></p><h3>Gadget</h3><p>✓ Spille &amp; adesivi (ogni 6 mesi)</p><p>✓ Poster di alta qualità (ogni 6 mesi)</p><p><span style=\"color:rgb(127, 96, 0);\">× Peluche di marca (ogni anno)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Statuette di alta qualità (ogni anno)</span></p><p><br></p><h3>Eventi offline</h3><p>✓ Accesso gratuito alle nostre gaming convention</p><p><span style=\"color:rgb(127, 96, 0);\">× Visita agli uffici dell\'azienda (ogni anno)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ permette l\'accesso a Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Oro</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Contenuti digitali</h3><p>✓ Arte</p><p>✓ Sfondi</p><p>✓ Colonne sonore in-game</p><p>✓ Contenuti dietro le quinte</p><p><br></p><h3>9 In-game</h3><h3>loot box</h3><p>✓ Skin personaggi</p><p>✓ Effetti visivi</p><p>✓ Carte Storyline</p><p><br></p><h3>Vantaggi in-game</h3><p>✓ x5 Pass extra per Hall of Chaos</p><p>✓ x10 rinascita personaggio</p><p><br></p><h3>Discord</h3><p>✓ Accesso al canale dei giocatori</p><p>✓ Accesso al canale degli sviluppatori</p><p><br></p><h3>Forum</h3><p>✓ Colore esclusivo nickname</p><p>✓ Emoji esclusiva</p><p>✓ Immagine profilo animata</p><p>✓ x12 Punti extra per il posizionamento del post (giornalmente)</p><p><br></p><h3>Gadget</h3><p>✓ Spille &amp; adesivi (ogni 6 mesi)</p><p>✓ Poster di alta qualità (ogni 6 mesi)</p><p>✓ Peluche di marca (ogni anno)</p><p>✓ Statuette di alta qualità (ogni anno)</p><p><br></p><h3>Eventi offline</h3><p>✓ Accesso gratuito alle nostre gaming convention</p><p>✓ Visita agli uffici dell\'azienda (ogni anno)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ permette l\'accesso a Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Contenuti digitali</h3><p>Arte</p><p>Sfondi</p><p><span>Colonne sonore in-game </span></p><p><span>Contenuti dietro le quinte</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>In-game</h3><h3>loot box</h3><p>Skin personaggi</p><p><span>Effetti visivi</span></p><p><span>Carte Storyline</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Vantaggi in-game</h3><p>x1 Pass extra per Hall of Chaos</p><p>x1 rinascita personaggio </p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Accesso al canale dei giocatori</p><p><span>x Accesso al canale degli sviluppatori</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Colore esclusivo nickname</p><p><span>x Emoji esclusiva</span></p><p><span>x Immagine profilo animata</span></p><p>x2 Punti extra per il posizionamento del post (giornalmente)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Gadget</h3><p>Spille &amp; adesivi (ogni 6 mesi)</p><p>Poster di alta qualità (ogni 6 mesi)</p><p><span>Peluche di marca (ogni anno)</span></p><p><span>Statuette di alta qualità (ogni anno)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Eventi offline</h3><p>✓ Accesso gratuito alle nostre gaming convention</p><p>✓ Visita agli uffici dell\'azienda (ogni anno)</p>",
  "client.buy_button.text": "Compra ora",
  "client.buy_button.unavailable": "Non disponibile",
  "client.buy_button.pre-order": "Pre-ordine",
  "client.buy_button.checkout": "Vai alla cassa",
  "client.store.cart.add_button": "Aggiungi al carrello",
  "client.store.cart.header_button": "Carrello",
  "client.store.no_items": "Il negozio è vuoto al momento. Torna più tardi!",
  "client.store.error.gt10_gk": "Per proteggerti dalle frodi, puoi acquistare solo 10 chiavi alla volta.",
  "client.store.error.no_keys": "Il gioco è temporaneamente esaurito e verrà rifornito presto. Per aggiornamenti in merito non esitate a contattarci.",
  "client.store.item.purchased": "Acquistato",
  "client.store.item.price_from": "a partire da {price}",
  "client.store.item.show_more": "Mostra i dettagli",
  "client.store.successful_purchase": "Il tuo acquisto è avvenuto con successo. Grazie!",
  "client.store.not_enough_vc": "Moneta virtuale non sufficiente.</br>Si prega di ricaricare per completare l\'acquisto.",
  "client.store.non_consumable_item": "Hai già acquistato questo prodotto in passato e non è più disponibile.",
  "client.store.user_id_input_before_submit_hint": "Gli articoli verranno accreditati su questo conto.",
  "client.store.user_id_input_after_submit_hint": "Stai acquistando per:",
  "client.common_error": "Qualcosa è andato storto. Riprova più tardi o contatta il nostro team di integrazione all\'indirizzo <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Il vostro saldo",
  "client.header.logout": "Logout",
  "client.retailers.edition.title": "Seleziona la tua copia",
  "client.retailers.country.title": "Seleziona il tuo paese",
  "client.retailers.platform.title": "Seleziona la piattaforma",
  "client.retailers.retailer.title": "Seleziona il rivenditore",
  "client.retailers.edition.digital": "Digitale",
  "client.retailers.edition.physical": "Fisica",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Acquista dallo sviluppatore",
  "client.retailers.expand_button.collapse": "Riduci",
  "client.retailers.buy_button.buy": "Compra ora",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Più popolari",
  "blocks.retailers.components.retailers.standart.value.subtext": "Disponibile ora",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Chiave gioco, badge esclusivo, colonna sonora, mappa e pacchetto armatura aggiuntivo",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TITOLO DEL TUO GIOCO</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Il tuo Gioco conduce i giocatori in una nuova avventura piena di detrattori, mostri, cercatori d\'oro e grandi pericoli.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Disponibile per",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Inserisci la tua e-mail",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Iscriviti alla nostra newsletter per ricevere le notizie e gli aggiornamenti più recenti",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Configura pulsante",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Compra ora",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Configura pulsante",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Configura pulsante",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Configura pulsante",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Non disponibile",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Configura pulsante",
  "client.topup.input.errorMessage": "Qualcosa è andato storto. Verifica la correttezza del tuo ID e riprova in un secondo momento.",
  "client.topup.input.successMessage": "Hai eseguito l\'accesso con l\'ID {userID}. Ora puoi effettuare acquisti sul sito.",
  "client.topup.input.username": "Nome utente: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Livello: {level}",
  "client.topup.input.user_not_found": "Non è stato possibile trovare un utente con l\'ID specificato.<br/>Riprova.",
  "client.topup.input.serverError": "Qualcosa è andato storto.<br>Se ricevi questo messaggio più di una volta, {contact_us}.",
  "client.topup.input.serverError.contact_us": "contattaci",
  "client.topup.authorized_title": "Stai acquistando per:",
  "blocks.store.values.custom_title": "<h2>Negozio {gameTitle}</h2>"
};

module.exports = localizations;
