/** @prettier */
// Envs
export const clientEnvironments = {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
};

// URLs
export const PUBLISHER_URL = clientEnvironments.isDev
  ? 'http://localhost:9000'
  : 'https://publisher.xsolla.com';
export const BASE_URL = '/api';
export const MERCHANT_API = 'https://api.xsolla.com/merchant';
export const STORE_ORIGIN = 'https://store.xsolla.com';
export const STORE_API = `${STORE_ORIGIN}/api`;
export const SECURE_HOST = 'secure.xsolla.com';
export const SANDBOX_SECURE_HOST = 'sandbox-secure.xsolla.com';
export const SECURE_API = `https://${SECURE_HOST}`;
export const SANDBOX_SECURE_API = `https://${SANDBOX_SECURE_HOST}`;
export const SUBSCRIPTIONS_CLIENT_API =
  'https://subscriptions-client-api.xsolla.com/api';
export const LOGIN_API = 'https://login.xsolla.com/api';
export const LOGIN_WIDGET_BASE_URL = 'https://xl-widget.xsolla.com';
export const LOGIN_DEFAULT_URL = `${LOGIN_WIDGET_BASE_URL}/auth.html`;
export const LOGIN_WIDGET_URL = 'https://login-widget.xsolla.com/latest';
export const STORE_URL = 'https://store.xsolla.com/api/v2';
export const DOC_URL = 'https://developers.xsolla.com';
export const LAUNCHER_API = 'https://launcher.xsolla.com';
export const SUBSCRIPTIONS_API = 'https://subscriptions.xsolla.com';
export const WEBHOOK_SUB = 'https://nowebhook.com';

// Scripts
export const XSOLLA_BB_WIDGET_LINK =
  'https://cdn.xsolla.net/embed/buy-button/3.1.6/widget.min.js';
export const XSOLLA_PAY_STATION_WIDGET_LINK =
  'https://cdn.xsolla.net/embed/paystation/1.2.0/widget.min.js';
export const XSOLLA_ANALYTICS_SERVER = 'https://datagather.xsolla.com/hit';

// Analytics
export const XSOLLA_FACEBOOK_PIXEL_ID = '316480065406334';
export const XSOLLA_TWITTER_PIXEL_ID = 'nxcnh';
export const XSOLLA_GOOGLE_ANALYTICS_ID = 'UA-36937364-1';

// Common
export const EIGHTY_LV_MERCHANT = 29100;
export const TINYMCE_API_KEY =
  'agbrrfrocr4zgvtgn1bxepf42yptl8pyhk3r26v0yn4fhaw0';
export const AIRTABLE_API_KEY = 'keyYtk801xq47v8dT';
