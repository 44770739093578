import type { ScriptsState } from '../reducers/types/scripts-state';
import type { Translations } from './translations';

import {
  initServices,
  LandingServicesState,
  updateCart,
} from '../reducers/modules/site-builder/landingServices';
import { CustomAmountService } from './actions/buyCustomAmount';
import { GlobalUniversalCart } from './blocks/store/cart/GlobalUniversalCart';
import { InventoryService } from './blocks/store/services/InventoryService';
import { StoreAPIDataService } from './blocks/store/services/StoreAPIDataService';
import { getLoginService } from './blocks/user-account-services/get-login-service';
import { getStore } from './store';

export const initBlocksServices = (
  data: ScriptsState,
  translations: Translations
) => {
  const {
    cartId,
    projectId,
    locale,
    isPreviewFrameMode,
    landingType,
    renderMode,
    analyticsCounterId,
    merchantId,
    environment,
    loginId,
    loginWidgetGeneration,
    loginTheme,
    auth,
    payStationReturnUrl,
  } = data;

  const userService = getLoginService({
    merchantId,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
    auth,
    isPreviewFrameMode,
    environment,
  });

  const { userToken } = userService;

  const storeAPIDataService = StoreAPIDataService.getInstance(
    cartId,
    userToken,
    projectId,
    locale
  );

  storeAPIDataService.setLandingType(landingType);

  const cart = GlobalUniversalCart.getInstance(
    projectId,
    cartId,
    storeAPIDataService,
    userToken
  );

  const customAmountService = new CustomAmountService({
    authType: auth.type,
    projectId,
    locale,
    renderMode,
    analyticsCounterId,
    merchantId,
    environment,
    translations,
    loginId,
    payStationReturnUrl,
  });

  const services: LandingServicesState = {
    cart,
    storeAPIDataService,
    inventory: new InventoryService(userToken, projectId),
    customAmountService,
    userService,
  };

  const store = getStore();

  store.dispatch(initServices(services));
  cart.addListener(() => store.dispatch(updateCart(cart)));
};
