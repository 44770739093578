interface Listener {
  selector: string;
  handle: (element: Element, ev: Event) => void;
}

export class DocumentListener {
  listeners: Array<Listener> = [];

  constructor(type: string) {
    document.addEventListener(type, (ev) => {
      this.listeners.forEach((listener) => {
        const element = (ev.target as Element)?.closest(listener.selector);
        if (element) {
          listener.handle(element, ev);
        }
      });
    });
  }

  addListener(listener: Listener) {
    this.listeners.push(listener);
  }
}

export const documentClickListener = new DocumentListener('click');
