var localizations = {
  /* Menu header */
  "nav_block.header.save_status.completed-process": "Changes saved",
  "nav_block.header.save_status.saving-process": "Saving...",
  "nav_block.header.save_status.error-process": "Changes not saved",
  /* settings menu for editing landing */
  "nav_block.settings.settings_title": "Xsolla Site Builder",
  "nav_block.hero_settings.title": "Call-to-action block",
  "nav_block.hero_settings.toggle_background": "Custom background",
  "nav_block.site_blocks_navigate.hero": "Call-to-action",
  "nav_block.site_blocks_navigate.packs": "Game packs",
  "nav_block.site_blocks_navigate.subscriptions-packs": "Subscriptions",
  "nav_block.site_blocks_navigate.store": "Store",
  "nav_block.site_blocks_navigate.footer": "Footer",
  "nav_block.site_blocks_navigate.description": "Description",
  "nav_block.site_blocks_navigate.gallery": "Gallery",
  "nav_block.site_blocks_navigate.requirements": "System requirements",
  "nav_block.site_blocks_navigate.widgets": "Widgets",
  "nav_block.site_blocks_navigate.html": "Custom code",
  "nav_block.site_blocks_navigate.faq": "FAQs",
  "nav_block.site_blocks_navigate.embed": "Social media widgets",
  "nav_block.site_blocks_navigate.news": "News",
  "nav_block.site_blocks_navigate.retailers": "Sellers block",
  "nav_block.site_blocks_navigate.promoSlider": "Promo slider",
  "nav_block.site_blocks_navigate.topup": "Top-up",
  "nav_block.hero_settings_title.buy_button_component": "\"Buy\" Button",
  "nav_block.hero_settings_title.button_component": "Button",
  "nav_block.hero_settings_title.gplay_component": "Google Play",
  "nav_block.hero_settings_title.subscribe_component": "Subscribe",
  "nav_block.news_settings_title.launcher_select": "Content",
  "nav_block.hero_settings_title.platforms_component": "Platforms",
  "nav_block.hero_settings_title.pack_component": "Pack {numberPack}",
  "nav_block.hero_settings_title.storeSection_component": "Store section {numberPack}",
  "nav_block.hero_settings_title.storeSection_component.add": "Add store section",
  "nav_block.hero_settings_title.pack_component.add": "Add pack",
  "nav_block.hero_settings_title.plan_component.add": "Add subscription",
  "nav_block.hero_settings_title.product_component": "Product",
  "nav_block.hero_settings_title.plan_component": "Subscription",
  "nav_block.hero_settings_title.plan_component.select.plan": "Select subscription plan",
  "nav_block.hero_settings_title.plan_component.select.product": "Select product",
  "nav_block.hero_settings.toggle_logo": "Logo",
  "nav_block.hero_settings.toggle_description": "Description",
  "nav_block.hero_settings.buy_button_select_title": "Select package:",
  "nav_block.hero_settings.subscribe_select_title": "Add subscribers to:",
  "nav_block.hero_settings.lightBox_title": "Lightbox",
  "nav_block.hero_settings.toggle_steam": "Steam",
  "nav_block.hero_settings.toggle_windows": "Windows",
  "nav_block.hero_settings.toggle_app_store": "App Store",
  "nav_block.hero_settings.toggle_linux": "Linux",
  "nav_block.hero_settings.toggle_gog": "GOG",
  "nav_block.hero_settings.toggle_drm-free": "DRM-free",
  "nav_block.hero_settings.toggle_google": "Google Play",
  "nav_block.hero_settings.toggle_playstation": "PlayStation",
  "nav_block.hero_settings.toggle_switch": "Switch",
  "nav_block.hero_settings.toggle_xbox": "Xbox",
  "nav_block.hero_settings.toggle_apple": "Mac OS",
  "nav_block.hero_settings.toggle_nintendo": "Nintendo",
  "nav_block.hero_settings.toggle_discord": "Discord",
  "nav_block.hero_settings.toggle_epic": "Epic Games",
  "nav_block.hero_settings.toggle_origin": "Origin",
  "nav_block.hero_settings.toggle_uplay": "Uplay",
  "nav_block.hero_settings.toggle_unrealEngine": "Unreal Engine",
  "nav_block.hero_settings.toggle_text": "Description",
  "nav_block.hero_settings.gplay_added_link": "Google Play URL",
  "nav_block.hero_settings_footer.buy_button_component": "Set up details for <a href='{storeLink}' target='_top'>game keys</a> and <a href='{subscriptionLink}' target='_top'>subscriptions</a>.",
  "nav_block.hero_settings.footer.subscribe_component": "To see your subscribers, go to the selected <a href='{link}' target='_top'>Login</a> > Users.",
  "nav_block.hero_settings.buy_button_select_null_element": "-- SELECT --",
  "nav_block.hero_settings.subscribe_select_null_element": "-- SELECT --",
  "nav_block.hero_settings.subscribe_seelct_extra_options.create_login": "+ New Login",
  "nav_block.button_settings.select_seelct_extra_options.create_sku": "+ Game key package",
  "nav_block.button_settings.select_seelct_extra_options.create_sub": "+ New subscription",
  /* settings menu for packs */
  "nav_block.packs_settings.toggle_background": "Custom background",
  "nav_block.packs_settings.toggle_packPictures": "Pack pictures",
  "nav_block.packs_settings.toggle_hoverEffect": "Buy by clicking on pack area",
  "nav_block.packs_settings.buy_select_title": "Pack {packNumber} SKU",
  "nav_block.packs_settings.toggle_ribbon": "Badge",
  "nav_block.packs_settings.buy_select_null_element": "-- SELECT SKU --",
  "nav_block.packs_settings.title.title": "Heading",
  "nav_block.packs_settings.description.title": "Description",
  "nav_block.packs_settings.style.title": "Pack style",
  "nav_block.packs_settings.custom-style.title": "Custom pack style",
  "nav_block.packs_settings.custom-style.order.title": "Pack elements",
  "nav_block.packs_settings.custom-style.order.item.ribbon": "Badge",
  "nav_block.packs_settings.custom-style.order.item.img": "Pack image",
  "nav_block.packs_settings.custom-style.order.item.cta": "Button",
  "nav_block.packs_settings.custom-style.order.item.title": "Title",
  "nav_block.packs_settings.custom-style.order.item.description": "Description",
  "nav_block.packs_settings.custom-style.order.item.subtext": "Note",
  /* settings menu for subscriptions-packs */
  "nav_block.subscriptions-packs_settings.title.title": "Heading",
  "nav_block.subscriptions-packs_settings.description.title": "Description",
  "nav_block.subscriptions-packs_settings.style.title": "Subscription style",
  "nav_block.subscriptions-packs_settings.toggle_background": "Custom background",
  "nav_block.subscriptions-packs_settings.toggle_icons": "Icons",
  "nav_block.subscriptions-packs_settings.toggle_image": "Image",
  "nav_block.subscriptions-packs_settings.description.text": "To configure existing subscriptions, go to the {extraTextProduct} component. ",
  "nav_block.subscriptions-packs_settings.description.product": "Product ",
  "nav_block.subscriptions-packs_settings.description.text.more": "You can create a subscription by clicking the corresponding option in the {extraTextSubscription} component.",
  "nav_block.subscriptions-packs_settings.description.href.subscription": "Subscription",
  "nav_block.subscriptions-packs_settings.plan_select_null_element": "Select",
  "nav_block.subscriptions-packs_settings.product_select_null_element": "Select",
  "nav_block.subscriptions-packs_settings.product_select_no_product": "No product",
  "nav_block.subscriptions-packs_settings.add_new_plan": "+ New plan",
  /* settings menu for store block */
  "nav_block.store_settings.imageSize_select_title": "Image size", // Used for Image size in Store section
  "nav_block.store_settings.toggle_background": "Custom background",
  "nav_block.store_settings.toggle_description": "Items group name",
  "nav_block.store_settings.toggle_title": "Heading",
  "nav_block.store_settings.imageSize_select_title": "Image size", // Used for Image size in Store section
  "nav_block.store_settings.store.cardSize.title": "Item size",
  "nav_block.store_settings.tooltip.cardSize.medium": "Standard",
  "nav_block.store_settings.tooltip.cardSize.small": "Small",
  "nav_block.store_settings.store.sectionsTemplate.title": "Style",
  "nav_block.store_settings.tooltip.sectionsTemplate.sections": "Grouped",
  "nav_block.store_settings.tooltip.sectionsTemplate.tabs": "Tabbed",
  "nav_block.store_settings.layout.footer": "<a href='{link}' target='_top'>Set up purchases</a> in Store.",
  "nav_block.store_settings.storeItemsType_select_title": "Store content:",
  "nav_block.store_settings.storeItemsGroup_select_title": "Items group:",
  "nav_block.store_settings.virtualItems.title": "Virtual items",
  "nav_block.store_settings.virtualItems.create": "Create virtual item",
  "nav_block.store_settings.storeIчtemsGroup_select_null_element": "-- SELECT GROUP --",
  "nav_block.store_settings.gameKeys.title": "Games",
  "nav_block.store_settings.gameKeys.create": "Create game keys",
  "nav_block.store_settings.selector_title": "Select purchase type:",
  "nav_block.store_settings.toggle_itemDescription": "Item description",
  "nav_block.store_settings.toggle_horizontalScroll": "Horizontal scroll on mobile devices",
  "nav_block.store_settings.toggle_horizontalScroll.hint": "If items don't fit on one screen, mobile device users can scroll items horizontally.",
  "nav_block.store_settings.toggle_shopping_cart": "Shopping cart",
  "nav_block.store_settings.toggle_shopping_cart_info": "The cart allows users to buy multiple items and use promo codes.",
  "nav_block.store_settings.toggle_shopping_cart_info_without_login": "To unlock the cart, change the login method from user ID to <a id='{linkId}'>Xsolla Login</a>. <br>The cart allows users to buy multiple items and use promo codes.",
  /* settings menu for virtual currency section */
  "nav_block.store_settings.virtualCurrency.title": "Virtual currency (packages)",
  "nav_block.store_settings.virtualCurrency.create": "Create virtual currency",
  "nav_block.store_settings.virtualCurrency.selector.all": "All currencies",
  /* settings menu for bundles */
  "nav_block.store_settings.bundles.title": "Bundles",
  /* settings menu for custom amount */
  "nav_block.store_settings.customAmount.title": "Virtual currency (custom amount)",
  /* settings menu for retailers */
  "nav_block.retailers_settings.toggle_background": "Custom background",
  "nav_block.retailers_settings.toggle_packPictures": "Pack pictures",
  "nav_block.retailers_settings.toggle_hoverEffect": "Buy by clicking on pack area",
  "nav_block.retailers_settings.toggle_ribbon": "Badge",
  "nav_block.retailers_settings_title.retailers_component" : "Game pack {numberPack}",
  "nav_block.retailers_settings_title.retailers_component.title" : "Airtable data",
  "nav_block.retailers_settings.toggle_title": "Heading",
  "nav_block.retailers_settings.input.database.hint": "Enter database name",
  "nav_block.retailers_settings.input.table.hint": "Enter table name",
  "nav_block.retailers_settings.toggle_expandedView": "Collapse pack options",
  /* settings menu for gallery */
  "nav_block.gallery_settings.toggle_sliderLoop": "Loop slider",
  "nav_block.gallery_settings.toggle_slideShadow": "Slide shadow",
  "nav_block.gallery_settings.toggle_slideArrow": "Slide arrows",
  "nav_block.gallery_settings.toggle_background": "Custom background",
  "nav_block.gallery_settings.slide_title": "Slide {imageNumber}",
  "nav_block.gallery_settings.add_new_slide": "Add slide",
  "nav_block.gallery_list.regular": "In the screen center",
  "nav_block.gallery_list.full": "Full screen",
  /* settings menu for description */
  "nav_block.description_settings.toggle_title": "Heading",
  "nav_block.description_settings.toggle_background": "Custom background",
  "nav_block.description_settings.image_title": "Media {imageNumber}",
  "nav_block.description_settings.image_title.add": "Add media",
  "nav_block.description_settings.text_title": "Text {textNumber}",
  "nav_block.description_settings.text_title.add": "Add text",
  "nav_block.description_settings.toggle_bullets": "Bullets",
  "nav_block.description_settings.toggle_quotes": "Quotes",
  /* settings menu for CustomButton */
  "nav_block.button_settings.tabs.buy": "Buy",
  "nav_block.button_settings.tabs.buy.create_asset": "+ New package",
  "nav_block.button_settings.tabs.buy.create_sub": "+ New subscription",
  "nav_block.button_settings.tabs.link": "Link",
  "nav_block.button_settings.tabs.preset": "Preset",
  "nav_block.button_settings.select_select_null_element": "-- SELECT SKU --",
  "nav_block.button_settings.select_select_null_sub_element": "-- SELECT SUBSCRIPTION --",
  "nav_block.button_settings.sku_not_available": "The SKU is no longer available because you switched the project to a new account type. Select the new one.",
  "nav_block.button_settings.toggle_background": "Custom background",
  "nav_block.button_settings.toggle_open_new_tab": "Open in a new tab",
  "nav_block.button_settings.preset_select_title": "Presets",
  "nav_block.button_settings.preset_select_null_element": "Select preset",
  "nav_block.button_settings.preset.apple_store": "Apple Store",
  "nav_block.button_settings.preset.google_play": "Google Play",
  "nav_block.button_settings.link_placeholder": "https://example.com",
  "nav_block.button_settings.link_hint": "Enter the URL to send users to.",
  "nav_block.button_settings.text_color": "Text color",
  /* settings menu for System Requirements */
  "nav_block.requirements_settings.platform_req_component": "Platform {numberPlatform}",
  "nav_block.requirements_settings.platform_req_component.add": "Add platform",
  "nav_block.requirements_settings.toggle_background": "Custom background",
  "nav_block.requirements_settings.tooltip.req-min": "Minimum",
  "nav_block.requirements_settings.tooltip.req-full": "Minimum + recommended",
  /* settings menu for Lightbox */
  "nav_block.hero.lightBox": "Lightbox",
  "nav_block.hero.lightBox.add": "Lightbox",
  "nav_block.hero.toggle_background": "Custom background",
  "nav_block.hero.lightBox_title": "YouTube/Vimeo video URL:",
  "nav_block.hero.lightBox.tooltip": "Opens the content in a modal window when the user clicks on it.",
  "nav_block.hero.lightBox_placeholder": "https://example.com",
  /* settings menu for Header*/
  "nav_block.settings.widgets.translations_component.footer": "Add more languages in <a href='{link}' target='_top'> project settings</a>.",
  "nav_block.header_settings.toggle_ar-AE": "العَرَبِيَّة‎",
  "nav_block.header_settings.toggle_bg-BG": "Български",
  "nav_block.header_settings.toggle_zh-CN": "简体中文",
  "nav_block.header_settings.toggle_cs-CZ": "Čeština",
  "nav_block.header_settings.toggle_de-DE": "Deutsch",
  "nav_block.header_settings.toggle_en-US": "English",
  "nav_block.header_settings.toggle_es-ES": "Español",
  "nav_block.header_settings.toggle_fr-FR": "Français",
  "nav_block.header_settings.toggle_he-IL": "עברית",
  "nav_block.header_settings.toggle_hu-HU": "Magyar",
  "nav_block.header_settings.toggle_it-IT": "Italiano",
  "nav_block.header_settings.toggle_ja-JP": "日本語",
  "nav_block.header_settings.toggle_ko-KR": "한국어",
  "nav_block.header_settings.toggle_pl-PL": "Polski",
  "nav_block.header_settings.toggle_pt-BR": "Português",
  "nav_block.header_settings.toggle_ro-RO": "Romanesci",
  "nav_block.header_settings.toggle_ru-RU": "Русский",
  "nav_block.header_settings.toggle_th-TH": "ไทย",
  "nav_block.header_settings.toggle_tr-TR": "Türkçe",
  "nav_block.header_settings.toggle_zh-TW": "繁體中文",
  "nav_block.header_settings.toggle_vi-VN": "Tiếng Việt",
  "nav_block.header_settings.toggle_facebookLike": "Facebook Like",
  "nav_block.header_settings.toggle_facebookShare": "Facebook Share",
  "nav_block.header_settings.toggle_tweet": "Tweet",
  "nav_block.header_settings.set_up_sharing_button": "Set up sharing",
  "nav_block.header_settings.title": "Header block",
  "nav_block.site_blocks_navigate.header": "Header",
  "nav_block.header_settings.share_options": "Share options",
  "nav_block.header_settings.translate_options": "Languages",
  "nav_block.header_settings.toggle_logo": "Logo",
  "nav_block.header_settings.toggle_background": "Custom background",
  "nav_block.header_settings.toggle_headerFixed": "Fixed position",
  "nav_block.header_settings_title.login_component": "Login",
  "nav_block.header_settings_footer.login_component": "Users will log in to your site via the widget. <a href='{link}' target='_top'>Set up login methods</a> in your project.",
  "nav_block.header_settings_footer.login_empty": "Users will log in to your site via the widget. <a href='{link}' target='_top'>Create your first project</a> and select login methods.",
  "nav_block.header_settings_footer.login_disabled": "All pages of your site have the same login method. Go to the {mainPageLink} to change login methods for this site.", // Placeholder {mainPageLink} is "main page". Full sentence: "All pages of your site have the same login method. Go to the main page to change login methods for this site.". DO NOT change placeholders in this segment.
  "nav_block.header_settings_footer.login_disabled.main_page_link": "main page", // Please, disable auto-propagation for this segment (orange arrow) and translate the target text as a part of the full sentence. Full sentence: "All pages of your site have the same login method. Go to the main page to change login methods for this site."
  "nav_block.header_settings_footer.login_empty_disabled": "All pages of your site have the same login method. Go to the {mainPageLink} to select login methods for this site.", // Placeholder {mainPageLink} is "main page". Full sentence: "All pages of your site have the same login method. Go to the main page to select login methods for this site.". DO NOT change placeholders in this segment.
  "nav_block.header_settings_footer.login_empty_disabled.main_page_link": "main page", // Please, disable auto-propagation for this segment (orange arrow) and translate the target text as a part of the full sentence. Full sentence: "All pages of your site have the same login method. Go to the main page to select login methods for this site."
  "nav_block.header_settings.login_select_title": "Add logged users to:",
  "nav_block.header_settings.login_select_null_element": "Select",
  "nav_block.header_settings.login_seelct_extra_options.create_login": "+ New Login",
  /* settings menu for  Custom HTML */
  "nav_block.html_settings.toggle_background": "Custom background",
  "nav_block.html_settings.custom_code-title": "Add your code here:",
  "nav_block.html_settings.custom_code-placeholder_text": "<!-- Your custom code -->",
  "nav_block.html_settings.custom_code-placeholder_button": "add code",
  "nav_block.html_settings.layout.footer": "You can customize your site using our <a href='{link}' target='_blank'>existing solutions</a>.",
  /* settings menu for Footer */
  "nav_block.footer_settings.toggle_background": "Custom background",
  "nav_block.footer_settings.style.title": "Style",
  "nav_block.footer_settings.tooltip.three-columns": "Columns",
  "nav_block.footer_settings.tooltip.one-column": "Rows",
  "nav_block.footer_settings.pegi_component": "PEGI",
  "nav_block.footer_settings.esrb_component": "ESRB",
  "nav_block.footer_settings.usk_component": "USK",
  "nav_block.footer_settings.classind_component": "ClassInd",
  "nav_block.footer_settings.acb_component": "ACB",
  "nav_block.footer_settings.oflc_component": "OFLC",
  "nav_block.footer_settings.iarc_component": "IARC",
  "nav_block.footer_settings.social_component": "Social",
  "nav_block.footer_settings.ageRestriction_component": "Age restrictions",
  "nav_block.footer_settings.select_pegi-3": "PEGI 3",
  "nav_block.footer_settings.select_pegi-7": "PEGI 7",
  "nav_block.footer_settings.select_pegi-12": "PEGI 12",
  "nav_block.footer_settings.select_pegi-16": "PEGI 16",
  "nav_block.footer_settings.select_pegi-18": "PEGI 18",
  "nav_block.footer_settings.select_esrb-e": "ESRB E",
  "nav_block.footer_settings.select_esrb-e-10": "ESRB 10+",
  "nav_block.footer_settings.select_esrb-t": "ESRB T",
  "nav_block.footer_settings.select_esrb-m": "ESRB M",
  "nav_block.footer_settings.select_esrb-a": "ESRB AO",
  "nav_block.footer_settings.select_esrb-rp": "ESRB RP",
  "nav_block.footer_settings.select_usk-0": "USK 0",
  "nav_block.footer_settings.select_usk-6": "USK 6",
  "nav_block.footer_settings.select_usk-12": "USK 12",
  "nav_block.footer_settings.select_usk-16": "USK 16",
  "nav_block.footer_settings.select_usk-18": "USK 18",
  "nav_block.footer_settings.select_classind-l": "ClassInd L",
  "nav_block.footer_settings.select_classind-10": "ClassInd 10",
  "nav_block.footer_settings.select_classind-12": "ClassInd 12",
  "nav_block.footer_settings.select_classind-14": "ClassInd 14",
  "nav_block.footer_settings.select_classind-16": "ClassInd 16",
  "nav_block.footer_settings.select_classind-18": "ClassInd 18",
  "nav_block.footer_settings.select_acb-g": "ACB G",
  "nav_block.footer_settings.select_acb-pg": "ACB PG",
  "nav_block.footer_settings.select_acb-m": "ACB M",
  "nav_block.footer_settings.select_acb-ma": "ACB MA 15+",
  "nav_block.footer_settings.select_acb-r": "ACB R 18+",
  "nav_block.footer_settings.select_oflc-g": "OFLC G",
  "nav_block.footer_settings.select_oflc-pg": "OFLC PG",
  "nav_block.footer_settings.select_oflc-m": "OFLC M",
  "nav_block.footer_settings.select_oflc-13": "OFLC 13",
  "nav_block.footer_settings.select_oflc-16": "OFLC 16",
  "nav_block.footer_settings.select_oflc-18": "OFLC 18",
  "nav_block.footer_settings.select_iarc-3": "IARC 3+",
  "nav_block.footer_settings.select_iarc-7": "IARC 7+",
  "nav_block.footer_settings.select_iarc-12": "IARC 12+",
  "nav_block.footer_settings.select_iarc-16": "IARC 16+",
  "nav_block.footer_settings.select_iarc-18": "IARC 18+",
  "nav_block.footer_settings.select_age-0": "0+",
  "nav_block.footer_settings.select_age-6": "6+",
  "nav_block.footer_settings.select_age-12": "12+",
  "nav_block.footer_settings.select_age-16": "16+",
  "nav_block.footer_settings.select_age-17": "17+",
  "nav_block.footer_settings.select_age-18": "18+",
  "nav_block.footer_settings.select_age-21": "21+",
  "nav_block.footer_settings.toggle_youtube": "YouTube",
  "nav_block.footer_settings.toggle_twitter": "Twitter",
  "nav_block.footer_settings.toggle_kickstarter": "Kickstarter",
  "nav_block.footer_settings.toggle_wechat": "WeChat",
  "nav_block.footer_settings.toggle_vk": "VK",
  "nav_block.footer_settings.toggle_instagram": "Instagram",
  "nav_block.footer_settings.toggle_facebook": "Facebook",
  "nav_block.footer_settings.toggle_discord": "Discord",
  "nav_block.footer_settings.toggle_twitch": "Twitch",
  "nav_block.footer_settings.toggle_gamepedia": "Gamepedia",
  /* Social embed*/
  "nav_block.embed_settings.title": "Social media widgets block",
  "nav_block.embed_settings.stub": "This is a test widget. The widget with real data will be available in preview and on the site after its publication.",
  "nav_block.embed_settings.toggle_title": "Heading",
  "nav_block.embed_settings.toggle_background": "Custom background",
  "nav_block.embed_settings.facebook_feed_title.label": "FACEBOOK FEED",
  "nav_block.embed_settings.facebook_feed_title.add": "Add facebook feed",
  "nav_block.embed_settings.twitter_feed_title.label": "TWITTER FEED",
  "nav_block.embed_settings.twitter_feed_title.add": "Add twitter feed",
  "nav_block.embed_settings.discord_embed_title.label": "DISCORD",
  "nav_block.embed_settings.discord_embed_title.add": "Add discord",
  "nav_block.embed_settings.twitch_embed_title.label": "TWITCH",
  "nav_block.embed_settings.twitch_embed_title.add": "Add twitch",
  "nav_block.embed_settings.input_url.facebook_feed.placeholder": "facebook.com/username",
  "nav_block.embed_settings.input_url.twitter_feed.placeholder": "twitter.com/username",
  "nav_block.embed_settings.input_url.twitch_embed.placeholder": "twitch.tv/username",
  "nav_block.embed_settings.input_url.discord_embed.placeholder": "Discord server ID",
  "nav_block.embed_settings.input_url.facebook_feed.hint": "Enter the URL of your Facebook page to send users to.",
  "nav_block.embed_settings.input_url.twitter_feed.hint": "Enter the URL of your Twitter page to send users to.",
  "nav_block.embed_settings.input_url.twitch_embed.hint": "Enter the URL of the Twitch channel you would like to embed.",
  "nav_block.embed_settings.input_url.discord_embed.hint": "Enter the ID of your Discord server<br/>1. Open Discord  <br/>2. Click on your server title above the channel <br/>3. Click on the Server Settings option <br/>4. Click on the Widget option  <br/>5. Enable your server widget  <br/>6. Adjust the invite settings  <br/>7. Click on the Copy button in the Server ID section",
  "nav_block.embed_list.dark": "Dark",
  "nav_block.embed_list.light": "Light",
  "nav_block.embed_list.columns": "Columns",
  "nav_block.embed_list.rows": "Rows",
  /* NEWS */
  "nav_block.news_settings.toggle_title": "Heading",
  "nav_block.news_settings.toggle_description": "Description",
  "nav_block.news_settings.style.title": "Style",
  "nav_block.news_settings.toggle_background": "Custom background",
  "nav_block.news_settings.title": "News block",
  "nav_block.news_settings.launcher_select_select_title": "Take news from:",
  "nav_block.news_settings.launcher_create_button": "Create news",
  "nav_block.news_settings.launcher_select_select_null_element": "-- SELECT --",
  "nav_block.news_settings.footer.launcher_select.launcher_empty": "To create news, you need to <a href='{launcherLink}' target='_top'>create a Launcher</a> to contain it.",
  "nav_block.news_settings.footer.launcher_select.launcher_not_selected": "Select a Launcher or <a href='{launcherLink}' target='_top'>create a new one</a>.",
  "nav_block.news_settings.footer.launcher_select": "You can manage news <a href='{newsLink}' target='_top'>here</a> or create a new <a href='{launcherLink}' target='_top'>Launcher</a>.",
  "nav_block.news_settings.add_article": "add article",
  "nav_block.news_settings.privacy_tooltip": "Only {subscriptionNames} subscribers will see this article.",
  "nav_block.news.banner.null_news_text": "Your news will be shown here.",
  "nav_block.news.banner.empty_news_text": "No news found. Go to the News block and add an article there.",
  "nav_block.news.create_article_modal.head": "Create an article",
  "nav_block.news.edit_article_modal.head": "Edit article",
  "nav_block.news.create_edit_article_modal.gated_content": "visibility settings",
  "nav_block.news.create_edit_article_modal.privacy_settings_title": "Who can see this article?",
  "nav_block.news.create_edit_article_modal.privacy_settings_priority": "priority",
  "nav_block.news.create_edit_article_modal.privacy_settings_pin_to_top": "Pin to top",
  "nav_block.news.create_article_modal.close": "cancel",
  "nav_block.news.edit_article_modal.close": "delete article",
  "nav_block.news.create_edit_article_modal.publish": "publish",
  "nav_block.news.create_edit_article_modal.form_title": "Title",
  "nav_block.news.create_edit_article_modal.form_image": "Image",
  "nav_block.news.create_edit_article_modal.form_checkbox": "Use as thumbnail for other localizations",
  "nav_block.news.create_edit_article_modal.form_preview": "Preview",
  "nav_block.news.create_edit_article_modal.form_article_text": "Article text",
  "nav_block.news.create_edit_article_modal.form_required": "Required",
  "nav_block.news.confirm_delete_article.title": "Delete this article?",
  "nav_block.news.confirm_delete_article.description": "If you delete the article, all its content will be lost.",
  "nav_block.news.confirm_delete_article.cancel": "keep article",
  "nav_block.news.confirm_delete_article.submit": "delete article",
  "nav_block.news_settings.tooltip.mixed": "Mixed",
  "nav_block.news_settings.tooltip.horizontal": "Scroll",
  "nav_block.news_settings.tooltip.small": "Grid",
  "nav_block.news_settings.tooltip.slider": "Slider",
  /* FAQ */
  "nav_block.faq_settings.title": "FAQs block",
  "nav_block.faq_settings.toggle_title": "Heading",
  "nav_block.faq_settings.toggle_questionMode": "Show answers",
  "nav_block.faq_settings.toggle_background": "Custom background",
  "nav_block.faq_settings.question_title": "Question {textNumber}",
  "nav_block.description_settings.question_title.add": "Add question",
  /* settings menu for editing seo */
  "nav_block.settings.seo_title": "SEO Settings",
  "nav_block.seo-inside-settings.google-tag-manager": "Google Tag Manager",
  "nav_block.seo-inside-settings.favicon": "Favicon",
  "nav_block.seo-inside-settings.facebook-share": "Facebook sharing",
  "nav_block.seo-inside-settings.google-search-result-preview": "Google search result preview",
  "nav_block.seo-inside-settings.custom-code": "Custom JavaScript",
  /* settings menu for editing login */
  "nav_block.login_settings.toggle_logo": "Logo",
  "nav_block.login_settings.toggle_instruction": "User ID instructions",
  "nav_block.login_settings.toggle_background": "Custom background",
  "nav_block.login_settings.toggle_use-site-style": "Use site style",
  "nav_block.login_settings.user_id.title": "USER ID",
  "nav_block.login_settings.user_id.description": "Users log in with their IDs from your game or via social networks.",
  "nav_block.login_settings.user_id.login_selector": "Select where to store user data:",
  "nav_block.login_settings.user_id.webhook": "Add a webhook to your game to check if a user exists. Enter the webhook URL below:",
  "nav_block.login_settings.user_id.extra_information": "Optional:",  
  "nav_block.login_settings.user_id.set_up_link": "Set up {linkText}",
  "nav_block.login_settings.user_id.set_up_link.social_settings": "social networks",
  "nav_block.login_settings.user_id.set_up_link.personalization": "personalized promotions",
  "nav_block.login_settings.login.title": "XSOLLA LOGIN",
  "nav_block.login_settings.login.description": "Users log in with a username and password, social networks, or phone. You can also specify age restrictions.",
  "nav_block.login_settings.login.selector": "Select where to store user data and {setUpText} login options:",
  "nav_block.login_settings.login.selector.doc_link": "set up",
  "nav_block.login_settings.guide": "Integration guide",
  /* modal windows */
  "modal.all_blocks.add_block_settings.title": "Add block",
  "modal.popup_settings.title": "Enter URL",
  "modal.code_editor.title": "Code editor",
  "modal.code_editor.subtitle": "Add your code here:",
  "modal.code_editor.placeholder": "<!--\r\n\r\nAdd your custom HTML code here. Please note: JS code is not\r\nsupported.\r\n\r\n(＠＾◡＾)\r\n\r\n-->\r\n",
  "modal.code_editor.button": "Apply",
  "modal.code_editor.notification_title": "Your JS code will be deleted",
  "modal.code_editor.notification_description": "The site does not support a JS code. It will be deleted, and only your HTML code will apply. If you want to add a JS code to your site, please contact your Account Manager or email am@xsolla.com.",
  /* Xsolla UI-kit */
  "xsui.dropdown.search": "Search",
  "xsui.group-select.select-all": "Select all",
  "xsui.group-select.unselect-all": "Unselect all",
  "xsui.image-picker.caption": "Upload image",
  "xsui.select.placeholder": "Select",
  "xsui.switch.off": "Off",
  "xsui.switch.on": "On",
  /* */
  "modal.delete_block.title": "Delete block?",
  "modal.delete_block.description": "If you delete the block, all its content will be lost.",
  "modal.delete_block.discard": "keep block",
  "modal.delete_block.confirm": "delete block",
  "modal.create_login.title": "New Login successfully created",
  "modal.create_login.description": "You can manage its settings <a href='{extraText}' target='_blank'>here</a>.",
  "modal.create_login.discard": "Thanks, dismiss",
  "modal.delete_image.title": "Delete this image?",
  "modal.delete_image.description": "If you delete the image, it will be removed from the assets, but saved in the blocks you used it for.",
  "modal.delete_image.discard": "keep image",
  "modal.delete_image.confirm": "delete image",
  /* */
  "nav_block.menu_spaces.seo": "SEO Settings",
  "nav_block.menu_spaces.assets": "Assets",
  "nav_block.menu_spaces.translations": "Language settings",
  "nav_block.menu_spaces.login": "Login settings",
  "nav_block.description_settings.title": "Description block",
  "nav_block.store_settings.title": "Store block",
  "nav_block.footer_settings.title": "Footer block",
  "nav_block.gallery_settings.title": "Gallery block",
  "nav_block.requirements_settings.title": "System requirements block",
  "nav_block.rwidgets_settings.title": "Widgets block",
  "nav_block.reviews_settings.title": "Reviews block",
  "nav_block.html_settings.title": "Custom code block",
  "nav_block.retailers_settings.title": "Sellers block",
  /* common texts in menu */
  "nav_block.page_editor.tooltip_assets": "Upload image",
  "nav_block.button.add_block": "Add block",
  "nav_block.button.add_block.disabled.tooltip": "Limit of 50 blocks per site",
  "nav_block.preview.title":"Preview",
  "nav_block.back-to-builder.title":"Back to builder",
  "nav_block.preview_navigate.publish_button":"Publish",
  "nav_block.settings.image.assets": "Assets",
  "nav_block.settings.image.set_image": "Set image",
  "nav_block.settings.image.set_media": "Set media",
  "nav_block.settings.image.background": "Page background",
  "nav_block.settings.assets.images": "Images",
  "nav_block.settings.assets.style": "Image settings", // Used for Layout title
  "nav_block.assets_settings.asset_select_title": "Size", // Used for Assets settings
  "nav_block.settings.assets.preview": "Preview",
  "nav_block.settings.assets.tab.image": "Images",
  "nav_block.settings.assets.tab.youtube": "Video",
  "nav_block.settings.assets.tint": "Tint",
  "nav_block.assets_sizes_list.cover": "Maximize",
  "nav_block.assets_sizes_list.contain": "Ensure fit",
  "nav_block.assets_sizes_list.fixed": "Fix",
  "nav_block.settings.assets.youtube.url": "YouTube video URL",
  "nav_block.packs_settings.title": "Game packs block",
  "nav_block.parts.delete_button": "Delete block",
  "nav_block.parts.duplicate_button": "Duplicate block",
  "nav_block.subscriptions-packs_settings.title": "Subscriptions block",
  "nav_block.subscriptions-packs.delete_button": "Delete block",
  "nav_block.subscriptions-packs.duplicate_button": "Duplicate block",
  "nav_block.hero.title": "Call-to-action block",
  "nav_block.all_settings.layout": "Layout",
  "nav_block.all_settings.private.title": "Who can see this block:",
  "nav_block.all_settings.private.tooltip": "Show content only to selected users and lock it for others.",
  "nav_block.all_settings.private.default_label": "All users",
  "nav_block.all_settings.private.plan_subscribes": "{plan} subscribers",
  "nav_block.site_settings.add_component": "Add block",
  /* languages map */
  "languages.ar-AE": "العَرَبِيَّة‎",
  "languages.bg-BG": "Български",
  "languages.zh-CN": "简体中文",
  "languages.cs-CZ": "Čeština",
  "languages.de-DE": "Deutsch",
  "languages.en-US": "English",
  "languages.es-ES": "Español",
  "languages.fr-FR": "Français",
  "languages.he-IL": "עברית",
  "languages.hu-HU": "Magyar",
  "languages.it-IT": "Italiano",
  "languages.ja-JP": "本語",
  "languages.ko-KR": "한국어",
  "languages.pl-PL": "Polski",
  "languages.pt-BR": "Português",
  "languages.ro-RO": "Romanesci",
  "languages.ru-RU": "Русский",
  "languages.th-TH": "ไทย",
  "languages.tr-TR": "Türkçe",
  "languages.zh-TW": "繁體中文",
  "languages.vi-VN": "Tiếng Việt",
  /* */
  "button.upload": "Upload",
  "button.open": "Open",
  "button.save": "Save",
  "start_page.title": "Create a new site",
  "start_page.select_template": "Select template",
  "start_page.selling_site": "Selling",
  "start_page.cfd": "BackerSite",
  "start_page.webstore": "store",
  "start_page.hint.store": "Create a site with a promotional block and a store for selling games, in-game items, or virtual currencies. See <a target='_blank' href='{gameStoreLink}'>demo with games</a> or <a target='_blank' href='{webStoreLink}'>demo with items and currencies</a>.",
  "start_page.google_pay": "Import from<br>Google Play",
  "start_page.gplay.default_input_message": "Enter your app\'s Google Play URL",
  "start_page.url_default.error_input_message": "The URL is invalid",
  "start_page.url_default.success_input_message": "The URL is valid",
  "start_page.url_default.success_input_error_data": "No data found",
  "start_page.url_default.create_button": "Create",
  "start_page.steam": "Import from<br>Steam",
  "start_page.steam.import": "Import from Steam",
  "start_page.steam.default_input_message": "Enter your app's Steam URL",
  "start_page.kickstarter": "Kickstarter",
  "start_page.notify_hint": "Notify me",
  "start_page.will_notify_hint": "We will notify you",
  "start_page.try_again": "Please try again later",
  "start_page.hint.selling_site": "Add pre-orders, Founder’s packs or game keys to create a landing page that will sell your game. <a href='https://turnon.xsollasitebuilder.com/' target='_blank'>See demo</a>.",
  "start_page.hint.google_play": "Import info and assets from Google Play and add pre-orders, Founder’s packs or game keys to create a pre-filled landing page that will sell your game.",
  "start_page.hint.steam": "Import info and assets from Steam and add pre-orders, Founder’s packs or game keys to create a pre-filled landing page that will sell your game.",
  "start_page.hint.cfd": "Give paid subscribers exclusive content. Best suited for game developers, esport teams, cosplayers, and content creators. <a href='https://cfd-demo.xsollasitebuilder.com/' target='_blank'>See the subscriptions content demo</a> for details.",
  "start_page.hint.serverless.title": "Game keys widgets might work incorrectly on other websites",
  "start_page.hint.serverless.description": "If you sell game keys via widgets on other websites, we strongly recommend you create <a href='{extraText}' target='_blank'>a new Publisher Account project</a> before continuing with this template.",
  "start_page.hint.universal_item.title": "This template only works with projects on Commerce API",
  "start_page.hint.universal_item.description": "Your project will be switched to the Commerce API integration. If you created any game keys, virtual items, or currencies, you need to create them again before the site is published. The rest of your settings will be transferred. If you decide to switch back, access to everything you created before will be restored.",
  "start_page.topup": "Web Shop<br>Page",
  "start_page.hint.topup.sell-your-game": "Sell in-game items, currencies, subscriptions, or bundles without the fees from mobile platforms.",
  "start_page.hint.topup.enter-url": "Players will make purchases via your store on your own site.",
  "start_page.hint.topup.see-document": "See the {extraText} for details.",
  "start_page.hint.topup.document_link": "Web Shop documentation",
  "start_page.topup.checkbox_message": "Use game info from Google Play or App Store",
  "start_page.topup.default_input_message": "Enter your game's Google Play URL, App Store URL, or continue with a blank template.",
  "start_page.topup.default_input_message_error": "We couldn't find any game info at this URL. Check your input or copy the full URL from {link_gplay} or {link_appstore}.",
  "start_page.topup.default_input_message_gplay": "Google Play",
  "start_page.topup.default_input_message_appstore": "App Store",
  "preview.hero.cta_default": "Buy now",
  "seo_page.favicon.title": "Favicon",
  "seo_page.favicon_info": "Upload a 32 x 32 pixel ICO, PNG, GIF, or JPG file to display in browser tabs.",
  "seo_page.favicon_generator": "Favicon generator",
  "seo_page.sharing.title": "Sharing options",
  "seo_page.sharing.form.title.text": "Enter sharing title",
  "seo_page.sharing.form.title.input": "Title (100 symbols limit)",
  "seo_page.sharing.form.description.text": "Enter sharing description",
  "seo_page.sharing.form.description.input": "Description (300 symbols limit)",
  "seo_page.sharing.form.image.text": "Sharing image (PNG, 1200x630)",
  "seo_page.sharing.form.image.button": "Set image",
  "seo_page.sharing.form.submit.text": "save",
  "seo_page.facebook-sharing.title": "Facebook sharing preview",
  "seo_page.facebook_description.practices": "Sharing Best Practices for Websites & Mobile Apps",
  "seo_page.facebook_description.facebook": "Facebook Text Overlay Tool",
  "seo_page.google_search": "Google search results preview",
  "seo_page.google_search-title": "Enter the search result title (must not exceed 100 characters)",
  "seo_page.google_search-description": "Enter your game description (must not exceed 300 characters)",
  "seo_page.custom_code": "Custom JavaScript",
  "seo_page.custom_code.css": "Code inside the head tag:",
  "seo_page.custom_code.js": "Code before the closing body tag:",
  "seo_page.custom_code.disclaimer": "The code is not validated. You are using it at your own risk.",
  "editor.hero.calltoaction.buy_button.buy_now": "Buy now {price}", // текст у кнопки в CTA зависит от настроек ключа
  "editor.hero.calltoaction.buy_button.preorder": "Pre-order {price}", // текст у кнопки в CTA зависит от настроек ключа
  "editor.hero.calltoaction.buy_button.set_up_button": "Set up button", // текст у кнопки в CTA если ключ не установлен
  "editor.hero.calltoaction.lightBox.set_up_button": "Set up button", // текст у кнопки в CTA если ключ не установлен
  "editor.hero.calltoaction.subscription.success_subscribe": "You're subscribed", // сообщение об успешной подписке
  "editor.custom_code.edit_code": "add code",
  "editor.store.button.buy": "Buy now",
  "editor.store.emptyEditorSection": "No items found. You can add items <a href='{storeLink}' target='_top'>here</a>.",
  "editor.store.emptyGamesEditorSection": "No available game keys found. <a href='{storeLink}' target='_top'>Upload keys here</a>.",
  "editor.button.subscription.title": "Subscribe for</br>{fullCurrency} per {periodCount}&thinsp;{periodName}",
  "editor.toolbar.textType.heading-1": "Heading 1",
  "editor.toolbar.textType.heading-2": "Heading 2",
  "editor.toolbar.textType.heading-3": "Heading 3",
  "editor.toolbar.textType.text": "Text",
  "editor.toolbar.link.placeholder": "https://example.com",
  "editor.requirements.addButton.text": "+ Add row",
  "editor.requirements.deleteButton.tooltip": "Delete row",
  "nav_block.color_theme.title": "Global theme", // Блок Global Theme
  "nav_block.color_theme.page_block": "Page background",
  "nav_block.color_theme.main_colors": "Colors",
  "nav_block.color_theme.main_colors.tooltip": "<strong>Primary</strong>: buttons,  selections, major inputs, discount labels.<br/><strong>Secondary</strong>: background for discounted items.<br/><strong>Text</strong>: default text color.<br/><strong>Border</strong>: outline for discounted items.<br/><strong>Background</strong>: default site background.<br/><strong>Overlay</strong>: background for full-price items, pop-ups.",
  "nav_block.color_theme.main_colors.text": "Text",
  "nav_block.color_theme.main_colors.button": "Button",
  "nav_block.color_theme.main_colors.accent": "Primary",
  "nav_block.color_theme.main_colors.overlay": "Overlay",
  "nav_block.color_theme.main_colors.secondary": "Secondary",
  "nav_block.color_theme.main_colors.border": "Border",
  "nav_block.color_theme.main_colors.sb-background": "Background",
  "nav_block.color_theme.main_colors.hint": "Customize your theme with an extended color palette. Secondary, border, and overlay colors only apply to a store for now. More settings are coming soon.",
  "nav_block.color_theme.main_colors.page_tint": "Page tint",
  "nav_block.color_theme.main_colors.roundness": "Roundness:",
  "nav_block.color_theme.main_colors.background_blur": "Background blur",
  "nav_block.color_theme.main_colors.video_settings_title": "Video background",
  "nav_block.color_theme.main_colors.video_settings_placeholder": "Add a YouTube video URL to set the background video",
  "nav_block.color_theme.main_fonts.header": "Fonts",
  "nav_block.theme_settings.header_select_title": "Title text",
  "nav_block.theme_settings.main_select_title": "Body text",
  "notification.publication.success_text": "Your site has been successfully published.",
  "input.default_hint": "Enter your profile URL",
  "popup.error.title": "Error",
  /* errors */
  "error.sb.assets.upload_size": "The image is too large to upload. Maximum size: 10MB",
  "error.sb.projectInfo.get_info": "Something went wrong. Please try again later or contact our integration team at <a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>",
  "error.sb.projectInfo.update_info": "Could not disable tokenless integration. Please disable it manually in your Project settings > <a href='{link}' target='_blank'>Integration settings</a>",
  "error.sb.projectInfo.toggle_component": "Could not enable the Subscriptions module. Please enable it manually in Store > <a href='{link}' target='_blank'>Subscriptions</a>",
  "error.sb.projectInfo.sub_not_ready": "The subscription is not ready. Please configure it in Publisher Account > <a href='{link}' target='_blank'>Subscriptions</a>.",
  "error.sb.default_error": "Something went wrong. Please try again later or contact our integration team at <a href='mailto:integration@xsolla.com'>integration@xsolla.com</a>",
  "error.sb.401": "Sorry, you cannot view this page. Please <a href='{link}' target='_blank'>log in to Publisher Account</a> to continue.",
  "error.sb.404": "Sorry, the site with this name does not exist. You can check the name of your site in <a href='{link}' target='_blank'>Publisher Account</a> > Site Builder.",
  "error.login.404": "Sorry, the Login project with this name does not exist. You can check your project ID in <a href='{link}' target='_blank'>Publisher Account</a> > Login > reqired Login project.",
  /* EMAILS */
  /* Email Color Settings */
  "nav_block.emails.site_blocks_navigate.receipt": "Purchase confirmation",
  "nav_block.color_theme.main_colors.background": 'Background',
  /* Email General Settings and titles */
  "nav_block.emails.receipt_settings.title": "Purchase confirmation email",
  "nav_block.emails.receipt_settings.image.title": "Header image",
  "nav_block.emails.receipt_settings.text.title": "Text block",
  "nav_block.emails.receipt_settings.social.title": "Socials",
  "nav_block.emails.common_template.title": "Template settings",
  "nav_block.emails.template_settings.image.title": "Image",
  "nav_block.emails.template_settings.text.title": "Text",
  "nav_block.emails.template_settings.social.title": "Socials",
  "nav_block.preview_navigate.send_test_email":"Send test email",
  /* Email modals*/
  "modal.emails.publish_common.title": "Test your email by sending it to {email}",
  "modal.emails.publish_common.button": "Send test email",
  "modal.emails.publish_confirm.title": "You have changed the email",
  "modal.emails.publish_confirm.description": "After updating the email will go live and users will start receiving it.",
  "modal.emails.publish_confirm.discard": "discard",
  "modal.emails.publish_confirm.update_email": "update email",
  "modal.emails.publish_success.title": "Your email has been updated!",
  "modal.emails.publish_success.description": "It is now live and users will start receiving it.",
  "modal.emails.publish_success.close": "close",
  /* Email side-menu */
  "nav_block.emails.side-menu.subject-header": "Email subject",
  "nav_block.emails.side-menu.content": "Content",
  "nav_block.emails.side-menu.theme": "Styles",
  /* Login widget */
  "nav_block.login.site_blocks_navigate.widget": "Login widget",
  "nav_block.login.widget_settings.title": "Login widget editor",
  "nav_block.login.widget.scene_settings.title": "Page background",
  "nav_block.login.widget.header_settings.switch.title": "Logo",
  /* Login widget modals*/
  "modal.login.widget.publish_confirm.title": "You have changed Login global theme",
  "modal.login.widget.publish_confirm.description": "After publication, the widget will be changed for the projects it was connected to.",
  "modal.login.widget.publish_confirm.discard": "discard",
  "modal.login.widget.publish_confirm.publish": "publish",
  "modal.login.widget.publish_success.title": "Login global theme has been updated",
  "modal.login.widget.publish_success.description": "You can learn more about the widget {docLink}.",
  "modal.login.widget.publish_success.description-widget": "here",
  "modal.login.widget.publish_success.close": "close",
  "modal.login.widget.publish_error.title": "Failed to save changes",
  "modal.login.widget.publish_error.description": "There seems to be a problem with our servers. Please try again later.",
  /* button tabs controls */
  "button_tabs.controls.tooltip.laptop": "Desktop",
  "button_tabs.controls.tooltip.tablet": "Tablet",
  "button_tabs.controls.tooltip.mobile": "Mobile",
  /* custom lang select */
  "components.custom_select.languages.title": "Content language:",
  "components.custom_select.languages.tooltip": "Content language",
  "components.custom_select.preview_modes.tooltip": "How users see your site:",
  "components.custom_select.languages.ar-AE": "العَرَبِيَّة‎",
  "components.custom_select.languages.bg-BG": "Български",
  "components.custom_select.languages.zh-CN": "简体中文",
  "components.custom_select.languages.cs-CZ": "Čeština",
  "components.custom_select.languages.de-DE": "Deutsch",
  "components.custom_select.languages.en-US": "English",
  "components.custom_select.languages.es-ES": "Español",
  "components.custom_select.languages.fr-FR": "Français",
  "components.custom_select.languages.he-IL": "עברית",
  "components.custom_select.languages.hu-HU": "Magyar",
  "components.custom_select.languages.it-IT": "Italiano",
  "components.custom_select.languages.ja-JP": "本語",
  "components.custom_select.languages.ko-KR": "한국어",
  "components.custom_select.languages.pl-PL": "Polski",
  "components.custom_select.languages.pt-BR": "Português",
  "components.custom_select.languages.ro-RO": "Romanesci",
  "components.custom_select.languages.ru-RU": "Русский",
  "components.custom_select.languages.th-TH": "ไทย",
  "components.custom_select.languages.tr-TR": "Türkçe",
  "components.custom_select.languages.zh-TW": "繁體中文",
  "components.custom_select.languages.vi-VN": "Tiếng Việt",
  "modal.disable_server_integration.title": "Disable tokenless integration?",
  "modal.disable_server_integration.description": "Tokenless integration will be disabled. To finish Subscriptions and Game Keys settings, add the Login section in the Header block and select the project to store user data in. Please also make sure you are not using the Game Keys widget elsewhere, as it might not work correctly.",
  "modal.disable_server_integration.discard": "Discard",
  "modal.disable_server_integration.confirm": "Confirm",
  "nav_block.translations.footer": "Footer",
  "nav_block.translations.header": "Header",
  "nav_block.translations.position_title": "Language selector position",
  "retailers.edition.select": "Select your copy",
  "retailers.edition.digital": "Digital",
  "retailers.edition.physical": "Physical",
  "retailers.country.select": "Select your country",
  "retailers.country.default_country": "Argentina",
  "retailers.platform.select": "Select the platform",
  "retailers.buttons.button_link": "Buy now",
  "retailers.retailer.select": "Select the seller",
  "retailers.retailer.default_retailer": "Seller name",
  "retailers.platform.pc": "PC",
  "retailers.platform.ps": "PS4",
  "retailers.platform.xbox_one": "XBOX ONE",
  "retailers.platform.switch": "SWITCH",
  /* Promo Slider Block */
  "nav_block.promoSlider_settings.title": "Promo slider block",
  "nav_block.promoSlider_settings.toggle_logo": "Logo",
  "nav_block.promoSlider_settings.toggle_description": "Description",
  "nav_block.promoSlider_settings.toggle_slideArrow": "Slide arrows",
  "nav_block.promoSlider_settings.toggle_sliderLoop": "Loop slider",
  "nav_block.promoSlider_settings.toggle_background": "Custom background",
  "nav_block.promoSlider_settings.promoSlide_title": "Slide {textNumber}",
  "nav_block.promoSlider_settings.lightBox_title": "Lightbox",
  "nav_block.promoSlider_settings.lightBox_tooltip": "Opens the content in a modal window when the user clicks on it.",
  "nav_block.promoSlider_settings.subscribe_title": "Subscribe",
  "nav_block.promoSlider_settings.platforms_title": "Platforms",
  "nav_block.promoSlider_settings.button_title": "Button",
  "nav_block.promoSlider_settings.duplicate_slide_title": "Duplicate slide",
  "nav_block.promoSlider_settings.delete_slide_title": "Delete slide",
  "nav_block.description_settings.media_select_title": "Media opening options",
  "nav_block.footer_settings.select_none": "None",
  "nav_block.footer_settings.select_enlarge": "Enlarge",
  "nav_block.footer_settings.select_link": "Open via the link",
  /* Top up block */
  "nav_block.topup_settings.title": "Top-up block",
  "nav_block.topup_settings.toggle_logo": "Logo",
  "nav_block.topup_settings.toggle_title": "Header",
  "nav_block.topup_settings.toggle_description": "Description",
  "nav_block.topup_settings.toggle_background": "Custom background",
  "nav_block.topup_settings.toggle_userInstruction": "User ID instructions",
  "nav_block.topup_settings.instruction_about_block": "This block lets users enter their game IDs to easily buy in-game goods. Learn more about the {extraText} in our documentation.",
  "nav_block.topup_settings.instruction_about_block.document_name": "Top-up solution",
  "nav_block.topup_settings.userIdInput_title": "User ID input",
  "nav_block.topup_settings.toggle_userIdValidation": "User ID validation",
  "nav_block.topup_settings.link_hint": "Enter your validation webhook URL",
  "nav_block.topup_settings.link_invalid": " Webhook URL is invalid.",
  "nav_block.topup_settings.userIdInput_footer": "A webhook checks if the user ID is correct before the purchase is credited to the associated account. {extraText}.",
  "nav_block.topup_settings.userIdInput_footer.link": "Learn more about webhooks",
  "editor.topup.addStepButton.text": "+ Add step",
  "editor.topup.deleteStepButton.tooltip": "Delete step",
  "editor.topup.tooltip.hide.login": "This block will be hidden at the published site, as you configured an alternative login method. Your users will now log in via the widget.",
  "editor.topup.tooltip.hide.userid": "If you still want to identify them by ID via this block, delete the <strong>Login</strong> component in the <strong>Header</strong> block.",
  /*  Subscriptions-pack Editor */
  "editor.subscriptions-packs.tooltip.hide.userid": "This block will be hidden on your published site. By default, the site identifies users by <strong>ID</strong>, which is not enough for subscription management.",
  "editor.subscriptions-packs.tooltip.hide.login": "To use subscriptions, go to <strong>Login settings</strong> and set up Xsolla Login.",
};

module.exports = localizations;
